<template>
  <main-layout :code="6">
    <change-currency-layout route="deleted-cards">
      <div class="card">
        <div class="card-header header-elements">
          <h5 class="m-0 me-2">Удаленные карты ({{ currency }})</h5>
          <div class="card-header-elements ms-auto">
<!--            <a href="#add-card" @click.prevent="addCardModal = true" class="btn btn-sm btn-secondary"><span class="tf-icon bx bx-plus"></span> Добавить-->
<!--              карту</a>-->
            <a href="#refresh" @click.prevent="$refs.datatable.loadItems" class="btn btn-sm btn-primary"><span class="tf-icon bx bx-refresh"></span> Обновить данные</a>
          </div>
        </div>
        <div class="card-datatable text-nowrap">
          <div class="btn-group p-3" role="group">
            <button type="button" class="btn btn-success" @click.prevent="restoreCard(checkedItems)" :disabled="!checkedItems.length">Восстановить</button>
          </div>
          <datatable ajax="/api/cards/list" ref="datatable" :default-query="defaultQuery" :default-order="defaultOrder" v-model:items="items"
                     :columns="columns" :checkbox="true" checkbox-key="autoID" v-model:checked-items="checkedItems">
            <template #tr="item">
              <td><span class="fw-semibold">{{ item.autoID }}</span></td>
              <td>
                **** {{ item.lastdigits }}
                <template v-if="item.phone && item.phone.length">
                    <small class="text-muted">{{ item.phone }}</small>
                </template>
              </td>
              <td v-if="item.removeReason">{{ item.removeReason }}</td><td v-else><i>нет</i></td>
              <!--<td v-if="item.customerID"><a href="#">Перейти / {{ item.customerID }}</a></td><td v-else><i>нет</i></td>-->
              <td>{{ item.comment }}</td>
              <td v-if="item.port">{{ item.port }}</td><td v-else><i>нет</i></td>
              <td>
                <a href="#restoreCard" @click.prevent="restoreCard([item.autoID])" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Восстановить карту'}"><i
                    class="bx bx-power-off"></i></a>
                <a href="#fullInfo" @click.prevent="fullInfo(item)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Информация о карте'}"><i
                    class="bx bx-fullscreen"></i></a>
                <a href="#cardHistory" @click.prevent="cardHistory(item)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'История изменений'}"><i
                    class="bx bx-history"></i></a>
              </td>
            </template>
          </datatable>
          <add-card-modal v-model="addCardModal" :currency="currency"/>
          <card-full-info-modal v-model="cardFullInfoModal" :data="cardFullInfo"/>
          <card-history-modal v-model="cardHistoryModal" :data="cardHistoryInfo"/>
        </div>
      </div>
    </change-currency-layout>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import ChangeCurrencyLayout from "@/views/layouts/ChangeCurrencyLayout";
import {CARD_LANG, COMMON_LANG, DEFAULT_TIME_FORMAT} from "@/constants";
import Datatable from "@/views/components/Datatable";
import AddCardModal from "@/views/components/Modals/AddCardModal";
import http from "@/http";
import CardFullInfoModal from "@/views/components/Modals/CardFullInfoModal";
import CardHistoryModal from "@/views/components/Modals/CardHistoryModal";
import Swal from "sweetalert2";

export default {
  name: "DeletedCards",
  components: {CardHistoryModal, CardFullInfoModal, AddCardModal, Datatable, ChangeCurrencyLayout, MainLayout},
  props: ['currency'],
  data() {
    return {
      checkedItems: [],
      cardHistoryInfo: null,
      cardHistoryModal: false,
      cardFullInfo: null,
      cardFullInfoModal: false,
      addCardModal: false,
      defaultOrder: ['autoID', 'desc'],
      defaultQuery: {status: -1, currency: this.currency},
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'autoID', title: '#', sorting: true, filter: {type: 'input'}},
        {field: 'lastdigits', title: 'Номер карты', filter: {type: 'input', operator: 'like', end: '%'}},
        {field: 'deleteReason', title: 'Причина'},
        {field: 'comment', title: 'Комментарий', filter: {type: 'input', operator: 'like', end: '%'}},
        {field: 'port', title: 'Порт', filter: {type: 'input', operator: 'like', end: '%'}},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  watch: {
    currency(newVal) {
      this.defaultQuery = {...this.defaultQuery, currency: newVal}
    }
  },
  methods: {
    fullInfo(item) {
      this.cardFullInfo = item
      this.cardFullInfoModal = true
    },
    editCard(item) {

    },
    cardHistory(item) {
      this.cardHistoryModal = true
      this.cardHistoryInfo = null

      http.post('/api/cards/history', {
        id: item.autoID
      }).then(({data}) => {
        this.cardHistoryInfo = {item: data.item, history: data.history}
      })
    },
    restoreCard(ids) {
      Swal.fire({
        title: CARD_LANG.RESTORE,
        text: '',
        didOpen: this.getAllCards(ids, this.items),
        showCancelButton: true,
        confirmButtonText: CARD_LANG.YES_RESTORE,
        cancelButtonText: COMMON_LANG.CANCEL
      }).then((result) => {
        if (result.isConfirmed) {
          http.post('/api/cards/restore', {
            id: ids
          }).then(() => {
            this.$refs.datatable.loadItems()
          })
        }
      })
    },
    getAllCards(id, cards) {
      return function() {
        let cardList = []

        for (let card of cards) {
          if (id.includes(card.autoID))
            cardList.push("<b>" + card.lastdigits + "</b>")
        }

        Swal.getHtmlContainer().innerHTML = cardList.join(', ')
        Swal.getHtmlContainer().style.display = 'block'
      }
    }
  }
}
</script>

<style scoped>

</style>
