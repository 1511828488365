import {io} from "socket.io-client";
import {BACKEND_URL} from "@/constants";
import toastr from "toastr";

const socket = io(BACKEND_URL, {
    path: '/socket',
    transports: ['websocket', 'pooling'],
})

socket.on('notification', ({text}) => {
    toastr.warning(text)
})

export default socket
