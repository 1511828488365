<template>
  <main-layout :codes="16">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Подсчет задолженности</h5>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/accounting/debts" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.id }}</span></td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.cryptocurrency }}</td>
                  <td>{{ item.value }}</td>
                  <td>
                    <a href="#complete-payment" @click.prevent="completePayment(item)"
                       v-if="item.type === 'orders'"
                       class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Произвести выплату'}"><i
                        class="bx bx-credit-card-front"></i></a>
                  </td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {ACCOUNTING_LANG, COMMON_LANG, DEFAULT_TIME_FORMAT, STORE_LANG} from "@/constants";
import http from "@/http";
import AddApiModal from "@/views/components/Modals/AddApiModal";
import AddPartnerModal from "@/views/components/Modals/AddPartnerModal";
import Swal from "sweetalert2";

export default {
  name: "Debts",
  components: {AddPartnerModal, AddApiModal, Datatable, AddRoleModal, MainLayout},
  data() {
    return {
      id: null,
      addPartnerModal: false,
      defaultOrder: ['id', 'desc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'id', title: '#', sorting: true},
        {field: 'name', title: 'Партнер'},
        {field: 'cryptocurrency', title: 'Криптовалюта / Валюта'},
        {field: 'value', title: 'Значение'},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    completePayment(item) {
      Swal.fire({
        title: ACCOUNTING_LANG.COMPLETE_PAYMENT,
        text: '',
        showCancelButton: true,
        confirmButtonText: ACCOUNTING_LANG.YES_COMPLETE,
        cancelButtonText: COMMON_LANG.CANCEL,
      }).then((result) => {
        if (result.isConfirmed) {
          http.post('/api/accounting/add_payout_to_withdraw_list', {
            id: item.id,
            cryptocurrency: item.cryptocurrency
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
