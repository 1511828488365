<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 class="modal-title">Добавление пользователя в панель #{{ id }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="telegramid" class="form-label">ID telegram</label>
                <input type="text" id="telegramid" v-model="item.telegramid" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="rolePanel" class="form-label">Тип</label>
                <select2 id="rolePanel" v-model="item.rolePanel" :items="roles"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Добавить</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";

export default {
    name: "AddPanelTelegramModal",
    props: ['id', 'modelValue'],
    components: {Select2, BootstrapSelect, LoadingButton, Modal},
    data() {
        return {
            loading: false,
            item: {},
            roles: [
                {value: 'Admin', title: 'Админ'},
                {value: 'Operator', title: 'Оператор'},
            ],
        }
    },
    methods: {
        saveItem() {
            this.loading = true
            http.post('/api/panel/addtguser', {
                id: this.id,
                item: this.item
            }).then(({data}) => {

                let {
                    success,
                    item
                } = data

                if (success) {
                    this.$emit('update:modelValue', false)
                }

            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
