<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="data">
        <div class="modal-header">
          <h5 class="modal-title">Обмен | #{{ data.uuID }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Покупка: <strong>{{ data.direction_name.split('_')[1].toUpperCase() }}</strong></p>
          <p>Оплата: <strong>{{ data.direction_name.split('_')[0].toUpperCase() }}</strong></p>
          <p>Код обмена: <strong>{{ data.uuID }}</strong></p>
          <p>Источник: <strong>{{ changeSources[data.source] }}</strong></p>
          <p>Статус: <strong>{{ changeStatus[data.status] }}</strong></p>
          <p>Кошелек: <strong>{{ data.cryptowallet }}</strong></p>
          <p>TXID: <strong>
            <a v-if="data.txID && data.txID.length" :href="getTxLink(data.direction_name.split('_')[1], data.txID)" target="_blank">{{ data.txID }}</a>
            <i v-else>Отсутствует</i>
          </strong></p>

          <p>Сумма: {{ data.cryptoreserv }} {{ data.direction_name.split('_')[1].toUpperCase() }}<strong></strong></p>
          <p>Сумма в фиате: <strong>{{ data.pay }} {{ data.currency }}</strong></p>
          <br>
          <p>Время создния: <strong>
            <moment-format :input="data.createTime" :output="DEFAULT_TIME_FORMAT"/>
          </strong></p>
          <p v-if="[2,3,4].includes(data.status)">Время оплаты: <strong>
            <moment-format :input="data.payTime" :output="DEFAULT_TIME_FORMAT"/>
          </strong></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import {DEFAULT_TIME_FORMAT, changeSources, changeStatus} from "@/constants";
import {copyTextToClipboard, getTxLink} from "@/functions";

export default {
  name: "FullChangeInfoModal",
  props: ['modelValue', 'data'],
  components: {MomentFormat, LoadingBlock, Modal},
  data() {
    return {
      changeStatus,
      changeSources,
      DEFAULT_TIME_FORMAT
    }
  },
  methods: {
    getTxLink,
    copyTextToClipboard
  }
}
</script>

<style scoped>

</style>
