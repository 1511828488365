<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="!loadingBlock">
        <form v-if="item" action="/api/bot/savewh" method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 class="modal-title">WebHook</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="url" class="form-label">URL</label>
                <input type="text" id="url" v-model="item.url" class="form-control"
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="proxy" class="form-label">Прокси</label>
                <input type="text" id="proxy" v-model="item.proxy" class="form-control"
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="1"
                         v-model="item.status" id="status-toggle">
                  <label class="form-check-label" for="status-toggle">Статус</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingButton from "@/views/components/UI/LoadingButton";
import http from "@/http";
import LoadingBlock from "@/views/components/UI/LoadingBlock";

export default {
  name: "EditWebHookSettingsModal",
  props: ['item', 'modelValue'],
  components: {LoadingBlock, LoadingButton, Modal},
  created() {
    /*this.item = {
        name: null,
        token: null,
        proxy: null,
    }*/
  },
  methods: {
    saveItem() {
      this.loading = true
      http.post('/api/bot/savewh', {
        id: this.item.id,
        item: this.item
      }).then(response => {

        let {
          success
        } = response.data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
