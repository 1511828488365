<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Двухфакторная авторизация</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="otp" class="form-label">Код из приложения</label>
              <input type="text" id="otp" v-model.trim="otp" class="form-control"
                     placeholder="123456" data-np-checked="1">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
          <loading-button :loading="loading" type="button" class="btn btn-label-danger" @click.prevent="disableOTP">Выключить</loading-button>
        </div>
      </div>
    </div>
  </modal>

</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import LoadingButton from "@/views/components/UI/LoadingButton";
import http from "@/http";
export default {
  name: "TwoFactorDisableModal",
  props: ['modelValue'],
  components: {LoadingButton, BootstrapSelect, LoadingBlock, Modal},
  data() {
    return {
      otp: null,
      loading: false,
    }
  },
  methods: {
    disableOTP() {
      http.post('/api/account/disable_otp', {
        otp: this.otp
      }).then(({data}) => {
        let {
          success
        } = data

        if (success) {
         this.$emit('update:modelValue', false)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
