import store from '../../store'

export default function isGuest ({ next }: any){

    // @ts-ignore
    if(!store.state['user'].user) {
        return next()
    }

    return next({
        name: 'home'
    })
}
