<template>
  <div class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel1">Добавление быстрой ссылки</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="quick-link-title" class="form-label">Название</label>
              <input type="text" id="quick-link-title" class="form-control" placeholder="Введите название">
            </div>
            <div class="col-12 mb-3">
              <label for="quick-link-url" class="form-label">Ссылка</label>
              <input type="text" id="quick-link-url" class="form-control" placeholder="Введите ссылку">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
          <button type="button" class="btn btn-primary">Создать быструю ссылку</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AddQuickLinkModal",
}
</script>

<style scoped>

</style>
