<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form method="post" @submit.prevent="disableCard">
          <div class="modal-header">
            <h5 class="modal-title">Добавление партнера</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="reason" class="form-label">Выберите причину</label>
                <select2 id="reason" v-model="reason" :items="reasons"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" type="submit">Отключить</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";
import Swal from "sweetalert2";
import {CARD_LANG, cardDisableReason, COMMON_LANG} from "@/constants";

export default {
  name: "DisableCardModal",
  props: ['ids', 'items', 'modelValue'],
  components: {Select2, BootstrapSelect, LoadingButton, Modal},
  data() {
    return {
      reasons: [],
      reason: 1,
    }
  },
  created() {
    for (let reasonId in cardDisableReason) {
      this.reasons.push({
        title: cardDisableReason[reasonId],
        value: reasonId
      })
    }
    this.reason = 1
  },
  watch: {
    modelValue(newVal) {
      if (newVal) {
        this.reason = 1
      }
    }
  },
  methods: {
    getAllCards(id, cards) {
      return function () {
        let cardList = []

        for (let card of cards) {
          if (id.includes(card.autoID))
            cardList.push("<b>" + card.lastdigits + "</b>")
        }

        Swal.getHtmlContainer().innerHTML = cardList.join(', ')
        Swal.getHtmlContainer().style.display = 'block'
      }
    },
    disableCard() {
      Swal.fire({
        title: CARD_LANG.DISABLE,
        text: '',
        showCancelButton: true,
        confirmButtonText: CARD_LANG.YES_DISABLE,
        cancelButtonText: COMMON_LANG.CANCEL,
        didOpen: this.getAllCards(this.ids, this.items),
      }).then((result) => {
        if (result.isConfirmed) {
          http.post('/api/cards/disable', {
            id: this.ids,
            reason: this.reason
          }).then(({data}) => {
            const {success} = data
            if (success) {
              this.$emit('update:modelValue', false)
              this.$emit('update:page')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
