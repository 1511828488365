import Bitcoin from "@/views/pages/store/Bitcoin.vue";
import Tron from "@/views/pages/store/Tron.vue";
import History from "@/views/pages/store/History.vue";
import Transactions from "@/views/pages/store/Transactions.vue";
import P2PWallet from "@/views/pages/store/P2PWallet.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/store/btc',
        name: 'store-btc',
        component: Bitcoin,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/store/tron',
        name: 'store-tron',
        component: Tron,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/store/history',
        name: 'store-history',
        component: History,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/store/transactions',
        name: 'store-transactions',
        component: Transactions,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/store/p2pwallet',
        name: 'store-p2pwallet',
        component: P2PWallet,
        meta: {
            middleware: [isUser]
        }
    }
]
