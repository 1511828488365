<template>
  <main-layout :code="3">
    <changes-layout>

        <div class="card">
            <div class="card-header header-elements">
            <h5 class="m-0 me-2">Логи обменов</h5>
            </div>
            <div class="card-datatable text-nowrap">
            <datatable ajax="/api/changes/logs" ref="datatable" :default-query="defaultQuery" :default-order="defaultOrder" :items="items"
                        :columns="columns">
                <template #tr="item">
                <td><span class="fw-semibold">{{ item.autoID }}</span></td>
                <td>{{ item.uuID }}</td>

                <td v-if="item?.user">{{ item.user.login }}</td>
                <td v-else-if="!item.userID"><i>Системно</i></td>
                <td v-else><i>нет</i></td>

                <td>
                    <span v-if="eventsColor[item.event]" class="badge m-1" :class="{ [eventsColor[item.event]]: true}">{{ events[item.event] || item.event }}</span>
                    <span v-else class="badge bg-secondary m-1">{{ events[item.event] || item.event }}</span>
                </td>

                <td>
                    <moment-format :input="item.time" output="YYYY-MM-DD HH:mm"/>
                    <small class="text-muted">{{ moment(item.time).fromNow() }}</small>
                </td>

                <td v-if="item.missingPay">
                    <router-link :to="{name: 'utility-missing_pays', params: { currency: item.missingPay.currency, missingPayId: item.missingPay.autoID }}">
                        {{ item.missingPay.autoID }}
                    </router-link>
                </td>
                <td v-else><i>нет</i></td>

                </template>
            </datatable>
            </div>
        </div>
    </changes-layout>

  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import ChangesLayout from "@/views/layouts/ChangesLayout";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import Swal from "sweetalert2";
import moment from "moment-timezone";

export default {
  name: "ChangeLogs",
  components: {
    MomentFormat,
    Datatable, MainLayout, ChangesLayout
  },
  props: ['uuid'],
  data() {
    return {
      defaultOrder: ['autoID', 'desc'],
      defaultQuery: { },
      events: {
        Create: 'Создан',
        Pay: 'Оплачен',
        CancelTime: 'Отменен по времени',
        CancelClient: 'Отменен клиентом',
        Problem: 'Проблемный',
      },
      eventsColor: {
        Create: 'bg-primary',
        Pay: 'bg-success',
        CancelTime: 'bg-warning',
        CancelClient: 'bg-warning',
        Problem: 'bg-danger',
      },
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'autoID', title: '#', sorting: true},
        {field: 'uuID', title: 'Код заявки', filter: {type: 'input', operator: 'like'}},
        {field: '$user.name$', title: 'Сотрудник', filter: {type: 'input', operator: 'like'}},
        {field: 'event', title: 'Событие', filter: {
            type: 'select', items: [
                { title: 'Создан', value: 'Create' },
                { title: 'Оплачен', value: 'Pay' },
                { title: 'Отменен по времени', value: 'CancelTime' },
                { title: 'Отменен клиентом', value: 'CancelClient' },
                { title: 'Проблемный', value: 'Problem' },
            ]
            }
        },
        {field: 'time', title: 'Дата', filter: {type: 'datetime-range'}},
        {field: 'missingPayID', title: 'Ид потерянного платежа'},
      ],
      moment,
      users: []
    }
  },
  
  created() {
    if (this.uuid && this.uuid.length) {
        this.defaultQuery = {...this.defaultQuery, uuID: this.uuid}
    }
  },

  watch: {
    uuid(newVal) {
        this.defaultQuery = {...this.defaultQuery, uuID: newVal}
    }
  },
  mounted() {
    this.loadAllUsers()
  },

  methods: {
    loadAllUsers() {
      http.post('/api/user/list').then(({data}) => {
        this.users = data.items.map(i => i.login)

        this.columns[2] = {
            field: '$user.name$', title: 'Сотрудник', filter: {
                type: 'select', items: data.items.map(i => ({title: i.login, value: i.login}))
            }
        }
      })
    },
  }
}
</script>



<style scoped>
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1700px;
  }
}
</style>
