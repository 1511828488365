<template>
  <div class="loading">
    <div class="spinner-border spinner-border-lg text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingBlock"
}
</script>

<style scoped>
.loading {
  text-align: center;
}
</style>
