<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 class="modal-title">{{ id ? 'Изменение курса' : 'Добвление курса' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col mb-3">
                <label for="name" class="form-label">Название</label>
                <input type="text" id="name" v-model="item.name" class="form-control" placeholder="Введите название"
                       data-np-checked="1">
              </div>
            </div>
            <div class="row g-2 mb-3">
              <div class="col mb-0">
                <label for="currency" class="form-label">Валюта</label>
                <input type="text" id="currency" v-model="item.currency" class="form-control" placeholder="RUB"
                       data-np-checked="1">
              </div>
              <div class="col mb-0">
                <label for="crypto" class="form-label">Криптовалюта</label>
                <input type="text" id="crypto" v-model="item.crypto" class="form-control" placeholder="BTC"
                       data-np-checked="1">
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-3">
                <label for="url" class="form-label">API URL</label>
                <input type="text" id="url" v-model="item.url" class="form-control" placeholder="https://binance.com"
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="method" class="form-label">Метод</label>
                <input type="text" id="method" v-model="item.method" class="form-control" placeholder="GET"
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="jsonObject" class="form-label">Ключ</label>
                <input type="text" id="jsonObject" v-model="item.jsonObject" class="form-control"
                       placeholder="btc[0].price" data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="proxy" class="form-label">Прокси</label>
                <input type="text" id="proxy" v-model="item.proxy" class="form-control"
                       placeholder="socks5://127.0.0.1:1234/" data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="seconds" class="form-label">Интервал (в секундах)</label>
                <input type="text" id="seconds" v-model.number="item.seconds" class="form-control" placeholder="60"
                       data-np-checked="1">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingButton from "@/views/components/UI/LoadingButton";
import http from "@/http";

export default {
  name: "AddRateModal",
  props: ['id', 'modelValue'],
  components: {LoadingButton, Modal},
  data() {
    return {
      urls: {
        get: '/api/rate/get',
        add: '/api/rate/add',
        save: '/api/rate/save'
      },
      loading: false,
      item: {},
      defaultItem: {
        name: null,
        currency: null,
        url: null,
        crypto: null,
        seconds: null,
        proxy: null,
        jsonObject: null,
        method: null,
      }
    }
  },
  created() {
    this.item = {...this.defaultItem}
  },
  watch: {
    id(newVal) {
      if (newVal) {
        this.loading = true
        http.post(this.urls.get, {id: this.id}).then(({data}) => {
          let {
            success,
            item
          } = data

          if (success) {
            for (let field of Object.keys(this.item)) {
              this.item[field] = item[field]
            }
          }

        }).finally(() => {
          this.loading = false
        })
      } else {
        for (let field of Object.keys(this.item)) {
          this.item = {...this.defaultItem}
        }
      }
    }
  },
  computed: {
    saveUrl() {
      return this.id ? this.urls.save : this.urls.add
    }
  },
  methods: {
    saveItem() {
        console.log('this.saveUrl', this.saveUrl)
      this.loading = true
      http.post(this.saveUrl, {
        id: this.id,
        item: this.item
      }).then(response => {
        let {
          success,
          item
        } = response.data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
