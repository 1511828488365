<template>
  <main-layout>
    <div class="container-xxl flex-grow-1 container-p-y">
      <div class="row">
        <div class="col-md-12">
          <!-- Change Password -->
          <div class="card mb-4">
            <h5 class="card-header">Сумма оборота по кошельку или по партнеру</h5>
            <div class="card-body">
                <form @submit.prevent="getTurnoverAmountWallet">
                    <div class="row">
                        <div class="mb-3 col-md-4">
                            <label class="form-label" for="wallet">Криптовалютный адрес</label>
                            <div class="input-group input-group-merge">
                            <input class="form-control" type="text" id="wallet" name="wallet" v-model="turnoverAmountWallet.wallet" />
                            </div>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="form-label" for="partner">Партнер</label>
                            <select2 id="partner" v-model:items="partners" v-model="turnoverAmountWallet.partner_id" :options="{titleField: 'name', valueField: 'id'}"/>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="form-label" for="wallet">Время по UTC</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" placeholder="YYYY-MM-DD - YYYY-MM-DD"
                                    v-flatpickr="{mode: 'range', enableTime: true, onClose: selectDate('turnoverAmountWallet')}"/>
                            </div>
                        </div>
                        <div class="col-12 mt-1">
                            <button type="submit" class="btn btn-primary me-2">Получить</button>
                        </div>
                    </div>
                </form>
            </div>
          </div>
          <div class="card mb-4">
            <h5 class="card-header">Список заявок по кошельку или по партнеру</h5>
            <div class="card-body">
                <form @submit.prevent="getListApplicationsWallet">
                    <div class="row">
                        <div class="mb-3 col-md-4">
                            <label class="form-label" for="wallet">Криптовалютный адрес</label>
                            <div class="input-group input-group-merge">
                            <input class="form-control" type="text" id="wallet" name="wallet" v-model="listApplicationsWallet.wallet" />
                            </div>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="form-label" for="partner">Партнер</label>
                            <select2 id="partner" v-model:items="partners" v-model="listApplicationsWallet.partner_id" :options="{titleField: 'name', valueField: 'id'}"/>
                        </div>
                        <div class="mb-3 col-md-4">
                            <label class="form-label" for="wallet">Время по UTC</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" placeholder="YYYY-MM-DD - YYYY-MM-DD"
                                    v-flatpickr="{mode: 'range', enableTime: true, onClose: selectDate('listApplicationsWallet')}"/>
                            </div>
                        </div>
                        <div class="col-12 mt-1">
                            <button type="submit" class="btn btn-primary me-2">Получить</button>
                        </div>
                    </div>
                </form>
            </div>
          </div>
          <div class="card mb-4">
            <h5 class="card-header">Список заявок по выбранной карте</h5>
            <div class="card-body">
                <form @submit.prevent="getTurnoverAmountCard">
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label class="form-label" for="card">Карта</label>
                            <select2 id="card" v-model:items="cards" v-model="turnoverAmountCard.card_id" :options="{title: (e) => `${e.lastdigits} (${e.currency}) ${e.comment}`, valueField: 'autoID'}"/>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label class="form-label" for="wallet">Время по UTC</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" placeholder="YYYY-MM-DD - YYYY-MM-DD"
                                    v-flatpickr="{mode: 'range', enableTime: true, onClose: selectDate('turnoverAmountCard')}" />
                            </div>
                        </div>
                        <div class="col-12 mt-1">
                            <button type="submit" class="btn btn-primary me-2">Получить</button>
                        </div>
                    </div>
                </form>
            </div>
          </div>
          <div class="card mb-4">
            <h5 class="card-header">Список заявок по выбранной карте</h5>
            <div class="card-body">
                <form @submit.prevent="getListApplicationsCard">
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label class="form-label" for="card">Карта</label>
                            <select2 id="card" v-model:items="cards" v-model="listApplicationsCard.card_id" :options="{title: (e) => `${e.lastdigits} (${e.currency}) ${e.comment}`, valueField: 'autoID'}"/>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label class="form-label" for="wallet">Время по UTC</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" placeholder="YYYY-MM-DD - YYYY-MM-DD"
                                    v-flatpickr="{mode: 'range', enableTime: true, onClose: selectDate('listApplicationsCard')}" />
                            </div>
                        </div>
                        <div class="col-12 mt-1">
                            <button type="submit" class="btn btn-primary me-2">Получить</button>
                        </div>
                    </div>
                </form>
            </div>
          </div>
          <!--/ Change Password -->
          <!--<div class="card mb-4">
            <h5 class="card-header">Двухфакторная аутентификация</h5>
            <div class="card-body">
              <p class="fw-semibold mb-3">Статус: <span class="text-success" v-if="user.otp_enabled">Включена</span><span class="text-danger" v-else>Отключена</span></p>
              <p class="w-75">Для авторизации Вам понабодится Google Authenticator</p>
              <button class="btn btn-success mt-2" v-if="!user.otp_enabled" @click.prevent="enableOTP">Включить</button>
              <button class="btn btn-danger mt-2" v-else="user.otp_enabled" @click.prevent="disableOTP">Отключить</button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <two-factor-modal v-model="twoFactorModal" :data="image"/>
    <two-factor-disable-modal v-model="twoFactorDisableModal"/>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import http from "@/http";
import {mapMutations, mapState} from "vuex";
import TwoFactorModal from "@/views/components/Modals/TwoFactorModal";
import TwoFactorDisableModal from "@/views/components/Modals/TwoFactorDisableModal";
import Select2 from "@/views/components/UI/Select2";
import Swal from "sweetalert2";

export default {
  name: "Me",
  components: {TwoFactorDisableModal, TwoFactorModal, MainLayout, Select2},
  data() {
    return {
      cards: [],
      //
      partners: [],
      turnoverAmountWallet: {
        partner_id: 0,
        wallet: '',
        date: undefined,
        loading: false
      },
      listApplicationsWallet: {
        partner_id: 0,
        wallet: '',
        date: undefined,
        loading: false
      },
      turnoverAmountCard: {
        card_id: 0,
        date: undefined,
        loading: false
      },
      listApplicationsCard: {
        card_id: 0,
        date: undefined,
        loading: false
      },
    }
  },
  mounted() {
    http.post('/api/partner/list').then(({data}) => {
        this.partners = data.items;
    })

    http.post('/api/cards/get_all_active').then(({data}) => {
        this.cards = data.items;
    })
  },
  methods: {
    selectDate(name) {
      return (selectedDates) => {
        this[name].date = selectedDates.length === 2 ? selectedDates : undefined
      }
    },
    getTurnoverAmountWallet() {
        if (this.turnoverAmountWallet.loading) return
        if ((!this.turnoverAmountWallet.partner_id && !this.turnoverAmountWallet.wallet) || !this.turnoverAmountWallet.date) return
        this.turnoverAmountWallet.loading = true;
        http.post('/api/accounting/getTurnoverAmountWallet', this.turnoverAmountWallet).then(({data}) => {
            this.turnoverAmountWallet.loading = false;
            if (data.success) {
                let html = ''

                if (data.hasPartner) {
                    html = `Сумма оборота по USDT (Успешные заявки): ${data.result1.paySum || 0} ${data.currency}<br/>`;
                    html += `Сумма выплаченная по USDT (Успешные заявки): ${data.result1.cryptoreservSum || 0} ${data.currency}<br/>`;
                    html += `Сумма оборота по USDT (Проблемные заявки): ${data.result2.paySum || 0} ${data.currency}<br/>`;
                    html += `Сумма выплаченная по USDT (Проблемные заявки): ${data.result2.cryptoreservSum || 0} ${data.currency}<br/><br/>`;
                
                    html += `Общая сумма по USDT: ${(data.result1.paySum || 0) + (data.result2.paySum || 0)} ${data.currency} / ${(data.result1.cryptoreservSum || 0) + (data.result2.cryptoreservSum || 0)} ${data.currency}<br/><br/>`;

                    
                    html += `Сумма оборота по BTC (Успешные заявки): ${data.result3.paySum || 0} ${data.currency}<br/>`;
                    html += `Сумма выплаченная по BTC (Успешные заявки): ${data.result3.cryptoreservSum || 0} ${data.currency}<br/>`;
                    html += `Сумма оборота по BTC (Проблемные заявки): ${data.result4.paySum || 0} ${data.currency}<br/>`;
                    html += `Сумма выплаченная по BTC (Проблемные заявки): ${data.result4.cryptoreservSum || 0} ${data.currency}<br/><br/>`;
                
                    html += `Общая сумма по BTC: ${(data.result3.paySum || 0) + (data.result4.paySum || 0)} ${data.currency} / ${(data.result3.cryptoreservSum || 0) + (data.result4.cryptoreservSum || 0)} ${data.currency}`;
                } else {
                    html = `Сумма оборота в фиате: ${data.paySum}<br/>Сумма оборота в криптовалюте: ${data.cryptoreservSum}`
                }

                Swal.fire({
                    title: 'Сумма оборота',
                    html
                })
            }
        })
    },


    getTurnoverAmountCard() {
        if (this.turnoverAmountCard.loading) return;
        if (!this.turnoverAmountCard.card_id || !this.turnoverAmountCard.date) return
        const cardData = this.cards.find(c => c.autoID === this.turnoverAmountCard.card_id);
        if (!cardData) return
        this.turnoverAmountCard.loading = true;

        http.post('/api/accounting/getTurnoverAmountCard', this.turnoverAmountCard).then(({data}) => {
            this.turnoverAmountCard.loading = false;
            if (data.success) {
                let html = ''

                html = `Сумма оборота по карте (Успешные заявки): ${data.result1.paySum || 0} ${cardData.currency}<br/>`;
                html += `Сумма оборота по карте (Проблемные заявки): ${data.result2.paySum || 0} ${cardData.currency}<br/><br/>`;
            
                html += `Общий оборот по карте: ${(data.result1.paySum || 0) + (data.result2.paySum || 0)} ${cardData.currency}`;

                Swal.fire({
                    title: 'Сумма оборота',
                    html
                })
            }
        })
    },
    getListApplicationsWallet() {
        if (this.listApplicationsWallet.loading) return
        if ((!this.listApplicationsWallet.partner_id && !this.listApplicationsWallet.wallet) || !this.listApplicationsWallet.date) return
        this.listApplicationsWallet.loading = true;
        http.post('/api/accounting/getListApplicationsWallet', this.listApplicationsWallet).then(({data}) => {
            this.listApplicationsWallet.loading = false;
            if (data.success) {
                this.download([
                    'uuID\t\t\tв фиате\t\t\tв криптовалюте\t\t\tдата\t\t\tвремя\n',
                    ...(data.list.map(l => `${l.uuID}\t\t${l.pay}\t\t\t${l.cryptoreserv}\t\t\t${l.createTime}\t\t\t${l.payTime}\n`))
                ], `${this.listApplicationsWallet.partner_id || this.listApplicationsWallet.wallet}_${this.listApplicationsWallet.date[0]}_${this.listApplicationsWallet.date[1]}.csv`, 'text/plain');
            }
        })
    },
    getListApplicationsCard() {
        if (this.listApplicationsCard.loading) return
        if (!this.listApplicationsCard.card_id || !this.listApplicationsCard.date) return
        this.listApplicationsCard.loading = true;
        http.post('/api/accounting/getListApplicationsCard', this.listApplicationsCard).then(({data}) => {
            this.listApplicationsCard.loading = false;
            if (data.success) {
                this.download([
                    'uuID\t\t\tв фиате\t\t\tв криптовалюте\t\t\tдата\t\t\tвремя\n',
                    ...(data.list.map(l => `${l.uuID}\t\t\t${l.pay}\t\t\t${l.cryptoreserv}\t\t\t${l.createTime}\t\t\t${l.payTime}\n`))
                ], `${this.listApplicationsCard.card_id}_${this.listApplicationsCard.date[0]}_${this.listApplicationsCard.date[1]}.csv`, 'text/plain');
            }
        })
    },

    download(content, fileName, contentType) {
        const a = document.createElement("a");
        const file = new Blob(content, {type: contentType});

        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
        a.remove();
    },
  },
  computed: {
    ...mapState('user', ['user'])
  }
}
</script>

<style scoped>

</style>
