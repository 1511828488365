<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" v-if="data">
        <div class="modal-header">
          <h5 class="modal-title">{{ data?.history ? 'Диалоги' : 'Сообщения' }} | {{ data.item.uuID }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="data?.dialogs" style="padding: 0">
          <div class="table-responsive text-nowrap">
            <table class="table mt-1">
              <thead>
              <tr>
                <th>Диалог</th>
                <th>Клиент</th>
                <th>Время создания</th>
                <th>Действие</th>
              </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(row) in data.dialogs" :key="row.id" :class="{'table-success': row.closed_at}">
                <td>{{ row.title }}</td>
                <td>{{ row.customer.name }} ({{ row.customer.username }})</td>
                <td><moment-format :input="row.created_at" :output="DEFAULT_TIME_FORMAT"/></td>
                <td><a href="#messages" @click.prevent="getMessages(row.id)">Открыть</a></td>
              </tr>
              </tbody>
            </table>
            <small class="p-3">*Зеленый - закрытый диалог</small>
          </div>
        </div>
        <div class="modal-body" v-if="data?.messages" style="padding: 0">
          <div class="table-responsive text-nowrap">
            <table class="table">
              <tbody class="table-border-bottom-0">
                <tr v-for="(row) in data.messages" :key="row.id" :class="{'table-success': row.user_id}">
                  <td>{{ row.text }}</td>
                  <td><moment-format :input="row.date" :unix="true" :output="DEFAULT_TIME_FORMAT"/></td>
                </tr>
              </tbody>
            </table>
            <small class="p-3">*Зеленый - оператор</small>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import moment from "moment-timezone";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";

export default {
  name: "DialogsModal",
  props: ['modelValue', 'data'],
  components: {MomentFormat, LoadingBlock, Modal},
  data() {
   return {
     DEFAULT_TIME_FORMAT
   }
  },
  methods: {
    getMessages(dialog_id) {
      let savedData = this.data
      this.$emit('update:data', null)

      http.post('/api/changes/get_messages', {
        dialog_id
      }).then(({data}) => {
        let {
            success,
            messages
        } = data

        if (success) {
          this.$emit('update:data', {item: savedData.item, messages})
        } else {
          this.$emit('update:data', savedData)
        }
      }).catch(() => {
        this.$emit('update:data', savedData)
      })
    }
  }
}
</script>

<style scoped>

</style>
