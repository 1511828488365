<template>
  <div class="modal fade" tabindex="-1" aria-hidden="true" ref="modal">
    <slot/>
  </div>
</template>

<script>
import {Modal} from "bootstrap";

export default {
  name: "Modal",
  props: ['modelValue'],
  data() {
    return {
      modal: null
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs.modal)
    this.$refs.modal.addEventListener('show.bs.modal', this.emitShow)
    this.$refs.modal.addEventListener('hide.bs.modal', this.emitHide)
  },
  beforeUnmount() {
    this.$refs.modal.removeEventListener('show.bs.modal', this.emitShow)
    this.$refs.modal.removeEventListener('hide.bs.modal', this.emitHide)
  },
  unmounted() {
    this.$emit('update:modelValue', false)
    this.modal.dispose()
  },
  methods: {
    emitShow() {
      this.$emit('update:modelValue', true)
    },
    emitHide() {
      this.$emit('update:modelValue', false)
    }
  },
  watch: {
    modelValue(newVal) {
      if (newVal)
        this.modal.show();
      else
        this.modal.hide();
    }
  }
}
</script>

<style scoped>

</style>
