<template>
  <slot/>
  <div class="blockUI blockOverlay" v-if="loading"></div>
  <div class="blockUI blockMsg" v-if="loading">
    <div class="spinner-border text-secondary" role="status"></div>
  </div>
</template>

<script>
export default {
  name: "LoadingElement",
  props: ['loading']
}
</script>

<style scoped>
.blockUI.blockOverlay {
  z-index: 1000;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  opacity: 0.8;
  cursor: default;
  position: absolute;
}

.blockUI.blockMsg {
  z-index: 1011;
  position: absolute;
  padding: 0px;
  margin: 0px;
  width: 100%;
  top: calc(50% - 18px);
  text-align: center;
  color: rgb(0, 0, 0);
  border: 0px;
  background-color: transparent;
  cursor: default;
}
</style>
