<template>
<span class="badge badge-dot me-1"
      :class="{
                            'bg-primary': color === COLORS.Primary,
                            'bg-secondary': color === COLORS.Secondary,
                            'bg-success': color === COLORS.Success,
                            'bg-danger': color === COLORS.Danger,
                            'bg-warning': color === COLORS.Warning,
                            'bg-info': color === COLORS.Info,
                          }"
></span>
</template>

<script>
import {COLORS} from "@/constants";

export default {
  name: "Badge",
  props: ['color'],
  data() {
    return {
      COLORS
    }
  }
}
</script>

<style scoped>

</style>
