import Changes from "@/views/pages/Changes.vue";
import ChangeLogs from "@/views/pages/logs/ChangeLogs.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/changes/:status',
        name: 'changes',
        component: Changes,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/logs/change',
        name: 'change-logs',
        component: ChangeLogs,
        props: true,
        meta: {
            middleware: [isUser]
        }
    }
]
