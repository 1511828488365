<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="data">
        <div class="modal-header">
          <h5 class="modal-title">Курс | {{ data.name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Криптовалюта: <strong>{{ data.crypto }}</strong></p>
          <p>Фиатная валюта: <strong>{{ data.currency }}</strong></p>
          <p>Crontab: <strong>{{ data.crontab }}</strong></p>
          <br>
          <p>Proxy: <strong>{{ data.proxy }}</strong></p>
          <p>API URL: <strong>{{ data.url }}</strong></p>
          <p>Метод запроса: <strong>{{ data.method }}</strong></p>
          <p>Ключ: <strong>{{ data.jsonObject }}</strong></p>
          <br>
          <p>Последнее обновление: <strong><moment-format :input="data.lastupdate" :output="DEFAULT_TIME_FORMAT"/></strong></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import moment from "moment-timezone";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import {DEFAULT_TIME_FORMAT} from "@/constants";

export default {
  name: "FullRateInfoModal",
  props: ['modelValue', 'data'],
  components: {MomentFormat, LoadingBlock, Modal},
  data() {
   return {
     DEFAULT_TIME_FORMAT
   }
  }
}
</script>

<style scoped>

</style>
