<template>
  <main-layout :code="3">
    <div class="container-xxl flex-grow-1 container-p-y">
        <div class="card">
            <div class="card-header header-elements">
            <h5 class="m-0 me-2">Логи сотрудников</h5>
            </div>
            <div class="card-datatable text-nowrap">
            <datatable ajax="/api/user/logs" ref="datatable" :default-query="defaultQuery" :default-order="defaultOrder" :items="items"
                        :columns="columns">
                <template #tr="item">
                <td><span class="fw-semibold">{{ item.autoID }}</span></td>
                <td v-if="item?.admin">{{ item.admin.login }}</td>
                <td v-else>нет</td>
                <td v-if="item?.user">{{ item.user.login }}</td>
                <td v-else>{{ item.userName }}</td>
                <td>
                    <span v-if="eventsColor[item.event]" class="badge m-1" :class="{ [eventsColor[item.event]]: true}">{{ item.event }}</span>
                    <span v-else class="badge bg-secondary m-1">{{ item.event }}</span>
                </td>

                <td>
                    <moment-format :input="item.time" output="YYYY-MM-DD HH:mm"/>
                    <small class="text-muted">{{ moment(item.time).fromNow() }}</small>
                </td>
                </template>
            </datatable>
            </div>
        </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import ChangesLayout from "@/views/layouts/ChangesLayout";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import Swal from "sweetalert2";
import moment from "moment-timezone";

export default {
  name: "UserLogs",
  components: {
    MomentFormat,
    Datatable, MainLayout, ChangesLayout
  },
  props: ['uuid'],
  data() {
    return {
      defaultOrder: ['autoID', 'desc'],
      defaultQuery: { },
      DEFAULT_TIME_FORMAT,

      eventsColor: {
        'Авторизация': 'bg-success',
        'Попытка входа': 'bg-danger',
        'Изменение пароля': 'bg-danger',
        'Добавлен': 'bg-primary',
        'Изменен': 'bg-warning',
        'Удален': 'bg-danger',
      },
      items: [],
      columns: [
        {field: 'autoID', title: '#', sorting: true},
        {field: '$admin.login$', title: 'Админ', filter: {type: 'input', operator: 'like'}},
        {field: '$user.login$', title: 'Сотрудник', filter: {type: 'input', operator: 'like'}},
        //{field: 'event', title: 'Событие', filter: {type: 'input', operator: 'like'}},
        {field: 'event', title: 'Событие', filter: {
            type: 'select', operator: 'like', items: [
                { title: 'Авторизация', value: 'Авторизация' },
                { title: 'Попытка входа', value: 'Попытка входа' },
                { title: 'Изменение пароля', value: 'Изменение пароля' },
                { title: 'Добавлен', value: 'Добавлен' },
                { title: 'Изменен', value: 'Изменен' },
                { title: 'Удален', value: 'Удален' },
                { title: 'Включил 2FA', value: 'Включил 2FA' },
                { title: 'Отключил 2FA', value: 'Отключил 2FA' },
            ]}
        },
        {field: 'time', title: 'Дата', filter: {type: 'datetime-range'}},
      ],
      moment,
    }
  },
  
  created() {
    if (this.uuid && this.uuid.length) {
        this.defaultQuery = {...this.defaultQuery, uuID: this.uuid}
    }
  },

  watch: {
    currency(newVal) {
        this.defaultQuery = {...this.defaultQuery, uuID: newVal}
    }
  },
}
</script>



<style scoped>
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1700px;
  }
}
</style>
