<template>
  <menu-item v-for="(item, index) in items" :item="item" :key="index" v-show="!item?.access || permissions.includes(item?.access)"/>
</template>

<script>
import MenuItem from "@/views/components/Menu/MenuItem";
import {mapState} from "vuex";

export default {
  name: "MenuList",
  props: ['items'],
  components: {MenuItem},
  computed: {
    ...mapState('user', ['permissions'])
  }
}
</script>

<style scoped>

</style>
