<template>
  <main-layout :code="14">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Список платежей</h5>
              <div class="card-header-elements ms-auto">
                <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                    class="tf-icon bx bx-refresh"></span> Обновить данные</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/panel/payments" ref="datatable" :default-query="defaultQuery" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.paymentid }}</span></td>
                  <td v-if="item?.card">{{ item.card.numbercard }}</td><td v-else><i>нет</i></td>
                  <td>{{ item.fiatAmount }}</td>
                  <td>{{ item.cryptoAmount }}</td>
                  <td v-if="item?.rate">{{ item.rateExchange }}</td><td v-else><i>нет</i></td>
                  <td>
                    <moment-format :input="item.datePayment" :output="DEFAULT_TIME_FORMAT"/>
                  </td>
                  <td v-if="item?.partner">{{ item.partner.panelname }}</td><td v-else><i>нет</i></td>
                  <td>
                    <a href="#remove" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Удалить'}"
                       @click.prevent="removeItem(item)"><i
                        class="bx bx-trash"></i></a>
                  </td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import AddApiModal from "@/views/components/Modals/AddApiModal";
import AddPartnerModal from "@/views/components/Modals/AddPartnerModal";
import AddPaymentModal from "@/views/components/Modals/AddPaymentModal";
import AddPanelModal from "@/views/components/Modals/AddPanelModal";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import Swal from "sweetalert2";

export default {
  name: "PanelPayments",
  components: {
    MomentFormat,
    AddPanelModal, AddPaymentModal, AddPartnerModal, AddApiModal, Datatable, AddRoleModal, MainLayout
  },
  data() {
    return {
      defaultOrder: ['paymentid', 'desc'],
      defaultQuery: { isDedicatedPanel: false },
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'paymentid', title: '#', sorting: true},
        {field: 'cardid', title: 'Карта', filter: {type: 'input', operator: 'like', end: '%'}},
        {field: 'fiatAmount', title: 'Сумма (фиат)'},
        {field: 'cryptoAmount', title: 'Сумма (крипто)'},
        {field: 'rateExchange', title: 'Курс'},
        {field: 'datePayment', title: 'Время', filter: {type: 'datetime-range'}},
        {field: '$partner.panelname$', title: 'Панель', filter: {type: 'input',                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              operator: 'like', end: '%'}},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    removeItem(item) {
        Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите удалить платеж ${item.paymentid}?`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (!result.isConfirmed) return;
            http.post('/api/panel/payment_remove', {
                id: item.paymentid,
            }).then(({data}) => {
                let {
                    success
                } = data

                if (success) {
                    this.updatePage()
                }
            })
        })
    },
  }
}
</script>

<style scoped>

</style>
