<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="!loadingBlock">
        <form v-if="item" action="/api/bot/savesup" method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 class="modal-title">Поддержка</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
                <div class="col-12 mb-3">
                    <label for="financial" class="form-label">Финансовая поддержка</label>
                    <input type="text" id="financial" v-model="item.financial" class="form-control"
                        data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                    <label for="technical" class="form-label">Техническая поддержка</label>
                    <input type="text" id="technical" v-model="item.technical" class="form-control"
                        data-np-checked="1">
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingButton from "@/views/components/UI/LoadingButton";
import http from "@/http";
import LoadingBlock from "@/views/components/UI/LoadingBlock";

export default {
  name: "AddCardModal",
  props: ['item', 'modelValue'],
  components: {LoadingBlock, LoadingButton, Modal},
  created() {
    /*this.item = {
        name: null,
        token: null,
        proxy: null,
    }*/
  },
  methods: {
    saveItem() {
      this.loading = true
      http.post('/api/bot/savesup', {
        id: this.item.id,
        item: this.item
      }).then(response => {

        let {
          success
        } = response.data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
