<template>
  <router-view/>
</template>
<script>
import '@/assets/vendor/fonts/boxicons.css'

import '@/assets/vendor/css/rtl/core.css'
import '@/assets/vendor/css/rtl/theme-default.css'
import '@/assets/css/demo.css'

import '@/assets/vendor/libs/toastr/toastr.css'
import '@/assets/vendor/libs/sweetalert2/sweetalert2.css'

import '@/assets/vendor/libs/flatpickr/flatpickr.css'
import '@/assets/vendor/libs/pickr/pickr-themes.css'

export default {}
</script>
