<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 class="modal-title" v-if="!id">Добавление партнера</h5>
            <h5 class="modal-title" v-else>Изменение партнера</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="panelname" class="form-label">Название</label>
                <input type="text" id="panelname" v-model="item.panelname" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="rateID" class="form-label">ID Курса</label>
                <input type="text" id="rateID" v-model.number="item.rateID" class="form-control"
                       placeholder="123" data-np-checked="1">
              </div>
              <div class="col-12 mb-3" v-if="!id">
                <label for="currency" class="form-label">Валюта</label>
                <select2 id="currency" v-model.number="item.currency" :items="currencies"/>
              </div>
              <div class="col-12 mb-3">
                <label for="cryptocurrency" class="form-label">Криптовалюта</label>
                <select2 id="cryptocurrency" v-model.number="item.cryptocurrency" :items="cryptocurrencies"/>
              </div>
              <div class="col-12 mb-3">
                <label for="feeRate" class="form-label">Комиссия</label>
                <input type="text" id="feeRate" v-model.number="item.feeRate" class="form-control"
                       placeholder="12" data-np-checked="1">
              </div>
              <!--<div class="col-12 mb-3" v-if="id">
                <label for="balance" class="form-label">Баланс</label>
                <input type="text" id="balance" v-model.number="item.balance" class="form-control"
                       placeholder="12" data-np-checked="1">
              </div>-->
              <div class="col-12 mb-3">
                <label for="type_wallet" class="form-label">Тип</label>
                <select2 id="type_wallet" v-model.number="item.type_wallet" :items="type_wallet"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";

export default {
  name: "AddPanelModal",
  props: ['id', 'modelValue'],
  components: {Select2, BootstrapSelect, LoadingButton, Modal},
  data() {
    return {
      urls: {
        get: '/api/panel/get',
        add: '/api/panel/add',
        save: '/api/panel/save'
      },
      loading: false,
      item: {},
      defaultItem: {
        panelname: null,
        rateID: null,
        currency: null,
        cryptocurrency: null,
        feeRate: null,
        //balance: 0,
        type_wallet: null,
      },
      currencies: [
        {title: 'RUB', value: 'RUB'},
        {title: 'UAH', value: 'UAH'},
        {title: 'KZT', value: 'KZT'}
      ],
      cryptocurrencies: [
        {title: 'BTC', value: 'BTC'},
        {title: 'USDT', value: 'USDT'},
      ],
      type_wallet: [
        {title: 'Фиатный', value: 'fiat'},
        {title: 'Криптовалютный', value: 'crypto'},
      ],
    }
  },
  created() {
    this.item = {...this.defaultItem}
  },
  watch: {
    id(newVal) {
      if (newVal) {
        this.loading = true
        http.post(this.urls.get, {id: this.id}).then(({data}) => {
          let {
            success,
            item
          } = data

          if (success) {
            for (let field of Object.keys(this.item)) {
              this.item[field] = item[field]
            }
          }

        }).finally(() => {
          this.loading = false
        })
      } else {
        for (let field of Object.keys(this.item)) {
          this.item = {...this.defaultItem}
        }
      }
    }
  },
  computed: {
    saveUrl() {
      return this.id ? this.urls.save : this.urls.add
    }
  },
  methods: {
    saveItem() {
      this.loading = true
      http.post(this.saveUrl, {
        id: this.id,
        item: this.item
      }).then(({data}) => {

        let {
          success,
          item
        } = data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
