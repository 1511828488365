<template>
  <div class="dataTables_wrapper dt-bootstrap5 no-footer">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_length"><label>Показать <select
                class="form-select" v-model.number="limit">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select></label></div>
          </div>
<!--          <div class="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-end">-->
<!--            <div class="dataTables_filter"><label>Поиск:<input type="search"-->
<!--                                                               class="form-control-sm"-->
<!--                                                               placeholder=""-->

<!--                                                               data-np-checked="1"></label>-->
<!--            </div>-->
<!--          </div>-->
        </div>
    <div class="table-responsive" style="position: relative">
      <loading-element :loading="loading">
        <table class="table table-bordered table-striped table-hover dataTable no-footer"
               role="grid" style="width: 1383px;">
          <thead>
          <tr role="row">
            <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" v-if="checkbox"
                style="width: 18px;">
              <input type="checkbox" class="form-check-input" :checked="checkedItems.length"
                     @change.prevent="selectAll"
                     :indeterminate="checkedItems.length && items.length > checkedItems.length">
            </th>
            <th :style="{width: column?.width}"
                :class="{
                sorting: column.sorting,
                sorting_asc: column.sorting && column.field === order[0] && order[1] === 'asc',
                sorting_desc: column.sorting && column.field === order[0] && order[1] === 'desc'
              }"
                :key="index2" v-for="(column, index2) in columns"
                @click.prevent="titleClick(column)">
                <div v-if="column?.tooltip" v-tooltip="{title: column.tooltip}">{{ column.title }}</div>
                <div v-else>{{ column.title }}</div>
            </th>
          </tr>
          <tr role="row" class="filter-row" v-if="hasFilter">
            <th v-if="checkbox"></th>
            <th :key="index" v-for="(column, index) in columns">
              <div v-if="column.filter?.type === 'input'">
                <input @keyup="updateQuery" :value="showQuery(column.field)" @input="setInputQuery($event, column.field, column.filter)" type="text"
                       class="form-control-sm" data-np-checked="1">
              </div>
              <div v-else-if="column.filter?.type === 'date-range'">
                <input type="text" class="form-control-sm" placeholder="YYYY-MM-DD - YYYY-MM-DD"
                       v-flatpickr="{mode: 'range', onClose: selectDate(column.field, 'date-range')}"/>
              </div>
              <div v-else-if="column.filter?.type === 'datetime-range'">
                <input type="text" class="form-control-sm" placeholder="YYYY-MM-DD - YYYY-MM-DD"
                       v-flatpickr="{mode: 'range', enableTime: true, onClose: selectDate(column.field, 'datetime-range')}"/>
              </div>
              <div v-else-if="column.filter?.type === 'select'">
                <select2 :items="column.filter?.items" :model-value="showQuery(column.field)" @update:modelValue="setSelectQuery($event, column.field, column.filter)"/>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr :class="index % 2 ? 'odd' : 'even'" v-for="(item, index) in items">
            <td class="dt-checkboxes-cell" v-if="checkbox">
              <input type="checkbox" class="dt-checkboxes form-check-input"
                     @change.prevent="selectRow(item[checkboxKey])"
                     :checked="checkedItems.includes(item[checkboxKey])">
            </td>
            <slot v-bind="item" name="tr"/>
          </tr>
          </tbody>
        </table>
      </loading-element>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_info" role="status" aria-live="polite">Всего {{ items.length }}</div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_paginate paging_simple_numbers">
          <ul class="pagination">
            <li class="paginate_button page-item previous" :class="{disabled: page <= 1}">
              <a href="#" @click.prevent="prev" class="page-link">Назад</a>
            </li>
            <li class="paginate_button page-item" :class="{active: 1 === page}" v-if="page > 5">
              <a @click.prevent="setPage(1)" href="#" class="page-link">1</a>
            </li>
            <li class="paginate_button page-item disabled" v-if="page > 6"><a href="#" class="page-link">…</a></li>
            <li class="paginate_button page-item" :class="{active: p === page}" v-for="p in displayedPages">
              <a @click.prevent="setPage(p)" href="#" class="page-link">{{ p }}</a>
            </li>
            <li class="paginate_button page-item disabled" v-if="page < pages - 5"><a href="#" class="page-link">…</a>
            </li>
            <li class="paginate_button page-item" :class="{active: pages === page}" v-if="page < pages - 4">
              <a @click.prevent="setPage(pages)" href="#" class="page-link">{{ pages }}</a>
            </li>
            <li class="paginate_button page-item next" :class="{disabled: page >= pages}">
              <a href="#" @click.prevent="next" class="page-link">Вперед</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css'
import http from "@/http";
import LoadingElement from "@/views/components/UI/LoadingElement";
import Select2 from "@/views/components/UI/Select2";

export default {
  name: "Datatable",
  components: {Select2, LoadingElement},
  props: {
    columns: Array,
    defaultOrder: Array,
    defaultQuery: Object,
    ajax: String,
    checkbox: Boolean,
    checkboxKey: String,
    checkedItems: Array,
    items: Array,
    limit: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      items: [],
      order: ['id', 'desc'],
      page: 1,
      total: null,
      query: {},
      pages: null,
      loading: false
    }
  },
  created() {
    this.initParams()
  },
  mounted() {
    this.loadItems()
  },
  watch: {
    defaultOrder() {
      this.order = this.defaultOrder
      this.setPage(1, true)
    },
    defaultQuery() {
      this.query = this.defaultQuery ?? {}
      this.setPage(1, true)
    },
    items() {
      this.updateItems()
    },
    limit() {
      this.setPage(1, true)
    }
  },
  methods: {
    setSelectQuery(e, field, filter) {
      let {
        operator = 'eq',
        start = '',
        end = '',
      } = filter

      let value = e || undefined

      if (value === undefined)
        this.query[field] = this.defaultQuery[field]
      else
        this.query[field] = operator === 'eq' ? value : {operator, value}

      this.setPage(1, true)
    },
    selectDate(column, type) {
      return (selectedDates) => {

        if (type === 'date-range' || type === 'datetime-range')
          this.query[column] = selectedDates.length === 2 ? {operator: 'between', value: selectedDates} : undefined

        this.setPage(1, true)
      }
    },
    updateItems() {
      let newValue = []
      for (let itemId of this.checkedItems || []) {
        let finded = false

        for (let item of this.items) {
          if (item[this.checkboxKey] === itemId) {
            finded = true
            break
          }
        }

        if (finded)
          newValue.push(itemId)
      }

      return this.$emit('update:checkedItems', newValue)
    },
    selectAll() {
      let newValue = []

      if (this.items.length > this.checkedItems.length) {
        for (let item of this.items) {
          newValue.push(item[this.checkboxKey])
        }
      }

      return this.$emit('update:checkedItems', newValue)
    },
    selectRow(id) {
      if (this.checkedItems.includes(id)) {
        let newValue = this.checkedItems
        newValue.splice(newValue.indexOf(id), 1)
        return this.$emit('update:checkedItems', newValue)
      } else {
        let newValue = [...this.checkedItems, id]
        return this.$emit('update:checkedItems', newValue)
      }
    },
    updateQuery(e) {
      if (e.code === 'Enter')
        this.setPage(1, true)
    },
    setInputQuery(e, field, filter) {
      let {
        operator = 'eq',
        start = '',
        end = '',
      } = filter

      let value = e.target.value ? e.target.value : undefined

      if (value === undefined)
        this.query[field] = undefined
      else
        this.query[field] = operator === 'eq' ? value : {operator, value}
    },
    showQuery(field) {
      try {
        if(typeof this.query[field] === "object")
          return this.query[field].value
        return this.query[field]
      } catch (e) {
        return ""
      }
    },
    initParams() {
      this.order = this.defaultOrder || this.order
      this.query = this.defaultQuery
    },
    titleClick(title) {
      if (title.sorting) {
        let asc = true

        if (this.order[0] === title.field)
          asc = !(this.order[1] === 'asc')

        this.order = [title.field, asc ? 'asc' : 'desc']

        this.loadItems()
      }
    },
    prev() {
      if (!this.page || this.page <= 1)
        return

      this.setPage(this.page - 1)
    },
    next() {
      if (!this.page || this.page >= this.pages)
        return

      this.setPage(this.page + 1)
    },
    setPage(page, force = false) {
      if (this.loading || page < 1)
        return

      if ((this.page === page || page > this.pages) && !force)
        return

      this.page = page

      this.loadItems()
    },
    loadItems() {
      if (this.loading || !this.ajax)
        return

      this.loading = true

      http.post(this.ajax, {
        query: this.query,
        order: this.order,
        page: this.page,
        limit: this.limit
      }).then(response => {
        let {
          items,
          query,
          order,
          page,
          pages,
          total,
          limit
        } = response.data

        this.items = items
        this.order = order
        this.page = page
        this.total = total
        this.query = query
        this.pages = pages

        this.$emit('update:items', items)
        this.$emit('update:limit', limit)

      }).finally(() => {
        this.loading = false
      })
    }
  },
  computed: {
    displayedPages() {
      let pages = []

      for (let i = Math.max(1, this.page - 4); i <= Math.min(this.pages, this.page + 4); i++) {
        pages.push(i)
      }

      return pages
    },
    hasFilter() {
      let result = false

      for (let column of this.columns) {
        if (column.filter)
          result = true
      }

      return result
    }
  }
}
</script>

<style scoped>
.filter-row th {
  padding: 5px 10px !important;
}
</style>
