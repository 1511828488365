<template>
  <div class="col-lg-4 col-12 mb-4" v-for="item in currencies" :key="item.id">
    <div class="card">
        <div class="card-body text-center">
        <span class="d-block text-nowrap">Конверсия ({{ item?.name ?? '-' }})</span>
        <h2 class="mb-0">{{ item?.amount ?? '-' }} | {{ item?.yesterdayAmount ?? '-' }}</h2>
        <span class="d-block text-nowrap">За сегодня | За Вчера</span>
        </div>
    </div>
  </div>
</template>

<script>

import AddRateWidgetModal from "@/views/components/Modals/AddRateWidgetModal";
import http from "@/http";

export default {
  name: "Conversion",
  components: {AddRateWidgetModal},
  props: ['currency'],
  data() {
    return {
        currencies: []
    }
  },
  created() {
    this.loadConversion()
  },
  methods: {
    loadConversion() {
        http.post('/api/home/conversion', {
            currency: this.currency,
        }).then(({data}) => {
            console.log(data)
            let {
                success,
                currencies
            } = data

            if (success){
                this.currencies = currencies
            }
        })
    }
  }
}
</script>

<style scoped>
.change-currency {
  position: absolute;
  right: 10px;
  top: 5px;
}
</style>
