<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="data">
        <div class="modal-header">
          <h5 class="modal-title">Карта | {{ data.lastdigits }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Номер карты: <strong>{{ data.numbercard }}</strong></p>
          <p>Баланс: <strong>{{ data.balance }} {{ data.currency }}</strong></p>
          <p>Добавил: <strong>{{ data.addlogin }}</strong></p>
          <p>Дата добавления: <strong><moment-format :input="data.addtime" :output="DEFAULT_TIME_FORMAT"/></strong></p>
          <p>Статус: <strong>{{ data.status }}</strong></p>
          <p>Комментарий: <strong>{{ data.comment }}</strong></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import moment from "moment-timezone";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import {DEFAULT_TIME_FORMAT} from "@/constants";

export default {
  name: "CardFullInfoModal",
  props: ['modelValue', 'data'],
  components: {MomentFormat, LoadingBlock, Modal},
  data() {
   return {
     DEFAULT_TIME_FORMAT
   }
  }
}
</script>

<style scoped>

</style>
