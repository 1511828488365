<template>
  <main-layout :code="2">
    <changes-layout>
      <div class="card">
        <div class="card-header header-elements">
          <h5 class="m-0 me-2">{{ pageTitle }}</h5>
          <div class="card-header-elements ms-auto">
            <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                class="tf-icon bx bx-refresh"></span> Обновить данные</a>
          </div>
        </div>
        <div class="card-datatable text-nowrap">
          <datatable ajax="/api/changes/list" :default-query="defaultQuery" ref="datatable"
                     :default-order="defaultOrder" v-model:items="items"
                     :columns="columns">
            <template #tr="item">
              <td><span class="fw-semibold">{{ item.autoID }}</span></td>
              <td>
                <router-link :to="{name: 'change-logs', params: { uuid: item.uuID }}">
                    {{ item.uuID }}
                </router-link>
              </td>
              <td>{{ item.paymentdata }}</td>

              <td v-if="item.isP2PCard">Да</td>
              <td v-else>Нет</td>
              
              <td v-if="item.direction_name === 'bitcoin_balance'">{{ item.pay }} BTC</td>
              <td v-else-if="item.direction_name === 'card_balance'">{{ item.pay }} {{ item.currency }}</td>
              <td v-else>{{ item.pay }} {{ item.currency }}</td>

              <td v-if="item.direction_name === 'bitcoin_balance'">{{ item.cryptoreserv }} {{ item.currency }}</td>
              <td v-else-if="item.direction_name === 'card_balance'">{{ item.cryptoreserv }} {{ item.currency }}</td>
              <td v-else>{{ item.cryptoreserv }} {{ getCrypto(item.direction_name.split('_')[1].toUpperCase()) }}</td>

              <td v-if="!['completed', 'processing'].includes(status)"><a href="#copy" @click.prevent="copy(item.cryptowallet)"
                                                   v-tooltip="{title: item.cryptowallet}">{{
                  item.cryptowallet.slice(0, 5)
                }}...</a></td>
              <td v-if="item?.partner">{{ item?.partner?.name }}</td>
              <td v-else><i>нет</i></td>
              <td>
                <moment-format :input="item.createTime" output="YYYY-MM-DD HH:mm"/>
                <small class="text-muted">{{ moment(item.createTime).fromNow() }}</small>
              </td>
              <!--<td v-if="status === 'completed'">
                <moment-format :input="item.payTime" output="YYYY-MM-DD HH:mm"/>
              </td>-->
              <td v-if="status === 'processing'">{{ processingStatus[item.status] }}</td>
              <td v-if="status === 'completed'">{{ changeStatus[item.status] }}</td>
              <td>
                <a href="#more" class="btn btn-sm btn-icon"
                   @click.prevent="loadFullInfo(item.autoID)" v-tooltip="{title: 'Подробнее'}"><i
                    class="bx bx-fullscreen"></i></a>
                <!--<a href="#history" class="btn btn-sm btn-icon"
                   @click.prevent="loadFullHistory(item.autoID)" v-tooltip="{title: 'История изменений'}"><i
                    class="bx bx-list-ul"></i></a>-->
<!--                <a href="#dialogs" class="btn btn-sm btn-icon"-->
<!--                   @click.prevent="loadDialogs(item)" v-tooltip="{title: 'История обращений'}"-->
<!--                   v-if="status !== 'processing'"><i-->
<!--                    class="bx bx-question-mark"></i></a>-->
                <a href="#completed" @click.prevent="changeToCompleted(item.autoID, item.status)"
                   class="btn btn-sm btn-icon"
                   v-if="status !== 'completed'"
                   v-tooltip="{title: 'Перенести в оплаченные'}"><i
                    class="bx bx-check"></i></a>
              </td>
            </template>
          </datatable>
          <dialogs-modal v-model="dialogsModal" :data="dialogs" @update:data="value => dialogs = value"/>
          <full-change-info-modal v-model="fullChangeInfoModal" :data="fullChangeInfo"/>
          <change-history-modal v-model="changeHistoryModal" :data="changeHistory"/>
          <missing-pay-modal ref="missingPayModal" @missing-pay-id="MissingPayIdSelected" v-model="missingPayModal"
                             :data="missingPays"/>
        </div>
      </div>
    </changes-layout>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import ChangesLayout from "@/views/layouts/ChangesLayout";
import Datatable from "@/views/components/Datatable";
import {changeSources} from "@/constants";
import FullChangeInfoModal from "@/views/components/Modals/FullChangeInfoModal";
import http from "@/http";
import moment from "moment-timezone";
import ChangeHistoryModal from "@/views/components/Modals/ChangeHistoryModal";
import MissingPayModal from "@/views/components/Modals/MissingPayModal";
import DialogsModal from "@/views/components/Modals/DialogsModal";

export default {
  name: "Changes",
  components: {
    DialogsModal,
    MissingPayModal,
    ChangeHistoryModal, FullChangeInfoModal, Datatable, ChangesLayout, MomentFormat, MainLayout
  },
  props: ['status'],
  data() {
    return {
      changeStatus: {
        "2": "Успешно",
        "3": "Успешно | Проблемная",
        "4": "Успешно | Оператор",
        "5": "Успешно | Автоподдержка",
        //"6": "Успешно | Не выплачено"
      },
      processingStatus: {
        "0": "Ожидает оплаты",
        "9": "В обработке",
      },
      defaultOrder: ['autoID', 'desc'],
      defaultQuery: {status: null},
      moment,
      missingPayModal: false,
      missingPays: null,

      changeHistoryModal: false,
      changeHistory: null,

      fullChangeInfoModal: false,
      fullChangeInfo: null,

      dialogsModal: false,
      dialogs: null,

      changeSources,
      items: [],
      columns: []
    }
  },
  created() {
    this.updateStatuses()
    this.$nextTick().then(() => {
      this.updateColumns()
    })
  },
  watch: {
    status() {
      this.updateStatuses()
      this.updateColumns()
    }
  },
  methods: {
    getCrypto(crypto) {
      if (crypto === 'BITCOIN')
        return 'BTC'

      return 'USDT'
    },
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    updateStatuses() {
        if (this.status === 'completed')
            this.defaultQuery = {status:  Object.keys(this.changeStatus).map(i => Number(i))}
        else if (this.status === 'processing')
            this.defaultQuery = {status: Object.keys(this.processingStatus).map(i => Number(i))}
        else if (this.status === 'deleted')
            this.defaultQuery = {status: [-1]}
    },
    updateColumns() {
        const columns = [];


        
        columns.push({width: 'auto', field: 'autoID', title: '#', sorting: true, filter: {type: 'input'}});
        columns.push({field: 'uuID', title: 'Код', filter: {type: 'input', operator: 'like', end: '%'}});
        columns.push({field: 'paymentdata', title: 'Пд', tooltip: 'Платежные данные', filter: {type: 'input', operator: 'like'}});
        
        columns.push({field: 'isP2PCard', title: 'P2P', filter: {
            type: 'select', items: [
                    { title: 'Да', value: 1 },
                    { title: 'Нет', value: 0 },
                ]
            }
        });

        columns.push({field: 'pay', title: 'Сумма $', tooltip: 'Сумма оплаты', filter: {type: 'input'}});
        columns.push({field: 'cryptoreserv', title: 'Сумма B', tooltip: 'Сумма в крипте'});
        if (!['processing', 'completed'].includes(this.status)) {
            columns.push({field: 'cryptowallet', title: 'Адрес', filter: {type: 'input', operator: 'like'}});
        }
        columns.push({field: '$partner.name$', title: 'Источник', filter: {type: 'input', operator: 'like'}});
        columns.push({field: 'createTime', title: 'Создана', filter: {type: 'datetime-range'}});

        if (this.status === 'processing') {
            columns.push({
                field: 'status', title: 'Статус', filter: {
                    type: 'select', items: Object.keys(this.processingStatus).map(i => ({title: this.processingStatus[i], value: Number(i)}))
                }
            })
        }


        if (this.status === 'completed') {
            //columns.push({field: 'payTime', title: 'Оплачена', filter: {type: 'datetime-range'}})

            columns.push({
                field: 'status', title: 'Статус', filter: {
                    type: 'select', items: Object.keys(this.changeStatus).map(i => ({title: this.changeStatus[i], value: Number(i)}))
                }
            })
        }

        columns.push({field: 'actions', title: 'Действия'});

        this.columns = columns;
    },
    loadFullInfo(autoID) {
      this.fullChangeInfo = null
      this.fullChangeInfoModal = true

      http.post('/api/changes/get', {
        id: autoID
      }).then((response) => {

        let {
          success,
          item
        } = response.data

        if (success)
          this.fullChangeInfo = item
        else
          this.fullChangeInfoModal = false

      }).catch(error => {
        this.fullChangeInfoModal = false
      })
    },
    completeChange(autoID, missingPayID = null) {
      return http.post('/api/changes/complete', {
        id: autoID,
        missing_pay_id: missingPayID,
      })
    },
    loadFullHistory(autoID) {
      this.changeHistory = null
      this.changeHistoryModal = true

      http.post('/api/changes/history', {
        id: autoID
      }).then((response) => {

        let {
          success,
          item,
          history
        } = response.data

        if (success)
          this.changeHistory = {item, history}
        else
          this.changeHistoryModal = false

      }).catch(error => {
        this.changeHistoryModal = false
      })
    },
    loadDialogs(item) {
      this.dialogs = null
      this.dialogsModal = true

      http.post('/api/changes/get_dialogs', {
        change_id: item.uuID
      }).then(({data}) => {
        let {
          success,
          dialogs
        } = data

        if (success) {
          this.dialogs = {item, dialogs}
        } else {
          this.dialogsModal = false
        }
      }).catch(() => {
        this.dialogsModal = false
      })
    },
    changeToCompleted(autoID, status) {

      if (status === 0) {
        return this.completeChange(autoID).finally(() => {
          this.$refs.datatable.loadItems()
        })
      }

      this.missingPays = null
      this.missingPayModal = true

      http.post('/api/changes/missing_pays', {
        id: autoID
      }).then((response) => {

        let {
          success,
          item,
          missing_pays,
        } = response.data

        if (success)
          this.missingPays = {missing_pays, item}
        else
          this.missingPayModal = false

      }).catch(error => {
        this.missingPayModal = false
      })
    },
    MissingPayIdSelected({missingPayID, autoID}) {
      if (!missingPayID)
        return

      this.$refs.missingPayModal.loading = true

      this.completeChange(autoID, missingPayID).then((response) => {

        let {
          success,
        } = response.data

        if (success)
          this.missingPayModal = false

      }).finally(error => {
        this.$refs.missingPayModal.loading = false
      })
    }
  },
  computed: {
    pageTitle() {
      if (this.status === 'completed')
        return 'Завершенные обмены'
      else if (this.status === 'processing')
        return 'Не завершенные обмены'
      else if (this.status === 'deleted')
        return 'Удаленные обмены'
    }
  }
}
</script>

<style scoped>
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1700px;
  }
}
</style>
