<template>
  <main-layout :code="12">
    <other-logs-layout>
      <div class="card">
        <div class="card-header header-elements">
          <h5 class="m-0 me-2">Другие логи ({{ currency }})</h5>
          <div class="card-header-elements ms-auto">
            <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                class="tf-icon bx bx-refresh"></span> Обновить данные</a>
          </div>
        </div>
        <div class="card-datatable text-nowrap">
          <datatable ajax="/api/logs/list" ref="datatable" :default-query="defaultQuery" :default-order="defaultOrder" :items="items"
                     :columns="columns">
            <template #tr="item">
              <td><span class="fw-semibold">{{ item.autoID }}</span></td>
              <td>{{ item.port }}</td>
              <td v-if="item.customerName">{{ item.customerName }}</td><td v-else><i>нет</i></td>
              <td>{{ item.sender }}</td>
              <td>{{ item.text }}</td>
              <td><moment-format :input="item.addTime" :output="DEFAULT_TIME_FORMAT"/></td>
            </template>
          </datatable>
        </div>
      </div>
    </other-logs-layout>
  </main-layout>
</template>

<script>
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import Datatable from "@/views/components/Datatable";
import MainLayout from "@/views/layouts/MainLayout";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import OtherLogsLayout from "@/views/layouts/OtherLogsLayout";

export default {
  name: "OtherLogs",
  components: {OtherLogsLayout, MomentFormat, Datatable, MainLayout},
  props: ['currency'],
  data() {
    return {
      defaultOrder: ['autoID', 'desc'],
      defaultQuery: {inctype: 'payments-other', currency: this.currency},
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'autoID', title: '#', sorting: true},
        {field: 'port', title: 'Порт', filter: {type: 'input', operator: 'like', end: '%'}},
        {field: 'customerName', title: 'Физ.лицо', filter: {type: 'input', operator: 'like', end: '%'}},
        {field: 'sender', title: 'Отправитель', filter: {type: 'input', operator: 'like', end: '%'}},
        {field: 'text', title: 'Текст', filter: {type: 'input', operator: 'fulltext'}},
        {field: 'addTime', title: 'Время'},
      ]
    }
  },
  watch: {
    currency(newVal) {
      this.defaultQuery = {...this.defaultQuery, currency: newVal}
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
  }
}
</script>

<style scoped>
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1700px;
  }
}
</style>
