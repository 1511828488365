import socket from "@/socketio";

export default {
    namespaced: true,

    state: {
        token: null,
        user: null,
        permissions: []
    },
    mutations: {
        setUser(state: any, user: any) {
            state.user = user
        },
        setToken(state: any, token: string) {
            state.token = token
            localStorage.setItem('token', token)

            socket.disconnect()
            socket.auth = {
                token: token
            }
            socket.connect()
        },
        setPermissions(state: any, permissions: any) {
            state.permissions = permissions
        }
    },
    getters: {},
    actions: {},
}
