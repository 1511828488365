// @ts-ignore
import emitter from "@/events";

export default {
    namespaced: true,

    state: {
        menuOpened: false,
        imageModalUrl: null,
        notifications: null,
    },
    mutations: {
        setMenuState(state: any, menuState: boolean) {
            state.menuOpened = menuState

            if (state.menuOpened)
                document.body.classList.add('layout-menu-expanded')
            else
                document.body.classList.remove('layout-menu-expanded')
        },
        previewImage(state: any, imageUrl: string) {
            state.imageModalUrl = imageUrl

            emitter.emit('imageModalShow')
        },
        setNotifications(state: any, notifications: Array<any>) {
            state.notifications = notifications
        }
    },
    getters: {},
    actions: {},
}
