<template>
  <!-- Layout wrapper -->
  <div class="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
    <div class="layout-container">
      <navbar/>

      <!-- Layout container -->
      <div class="layout-page">
        <!-- Content wrapper -->
        <div class="content-wrapper">
          <!-- Menu -->
          <aside-menu/>
          <!-- / Menu -->

          <!-- Content -->
          <slot v-if="!code || permissions.includes(code)"/>
          <not-enough-access v-else/>
          <!--/ Content -->

          <!-- Footer -->
          <main-footer/>
          <!-- / Footer -->

          <div class="content-backdrop fade"></div>
        </div>
        <!--/ Content wrapper -->
      </div>

      <!--/ Layout container -->
    </div>
  </div>

  <!-- Overlay -->
  <div class="layout-overlay layout-menu-toggle"></div>

  <!-- Drag Target Area To SlideIn Menu On Small Screens -->
  <div class="drag-target"></div>
  <!--/ Layout wrapper -->

  <add-quick-link-modal v-modal="{name: 'add-quick-link'}"/>
</template>

<script>

import '@/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css'
import '@/assets/vendor/libs/apex-charts/apex-charts.css'

import Navbar from "@/views/components/Navbar";
import MainFooter from "@/views/components/MainFooter";
import AsideMenu from "@/views/components/Aside/AsideMenu";
import AddQuickLinkModal from "@/views/components/Modals/AddQuickLinkModal";
import NotEnoughAccess from "@/views/pages/system/NotEnoughAccess";
import {mapState} from "vuex";

export default {
  name: "MainLayout",
  props: {
    code: {
      default: null
    }
  },
  components: {NotEnoughAccess, AddQuickLinkModal, AsideMenu, MainFooter, Navbar},
  computed: {
    ...mapState('user', ['permissions'])
  }
}
</script>

<style scoped>
.layout-page {
  padding-top: 62px !important;
}
</style>
