<template>
  <main-layout :code="14">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Список партнеров</h5>
              <div class="card-header-elements ms-auto">
                <a href="#add-panel" @click.prevent="editItem(null)" class="btn btn-sm btn-secondary"><span
                    class="tf-icon bx bx-plus"></span> Добавить панель</a>
                <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                    class="tf-icon bx bx-refresh"></span> Обновить данные</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/panel/list" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.panelid }}</span></td>
                  <td>{{ item.panelname }}</td>
                  <td>{{ item.currency }}</td>
                  <td>{{ item.cryptocurrency }}</td>
                  <td>{{ type_wallet[item.type_wallet] || item.type_wallet }}</td>
                  <td>{{ item.feeRate }}%</td>
                  <td>
                    {{ item.balance }}
                    <a href="#edit" @click.prevent="depositItem(item.panelid)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Пополнить баланс'}"><i
                        class="bx bx-plus-circle"></i></a>
                    <a href="#edit" @click.prevent="withdrawItem(item.panelid)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Списать баланс'}"><i
                        class="bx bx-minus-circle"></i></a>
                    </td>
                  <td>
                    <a href="#add-payment" @click.prevent="addPayment(item.panelid)" class="btn btn-sm btn-icon" v-tooltip="{title: 'Добавить платеж'}"><i
                        class="bx bx-folder-plus"></i></a>
                    <a href="#telegram-users" @click.prevent="telegramUsers(item.panelid)" class="btn btn-sm btn-icon" v-tooltip="{title: 'Пользователи Telegram'}"><i
                        class="bx bxs-user-detail"></i></a>
                    <a href="#telegram-users" @click.prevent="addTelegramUser(item.panelid)" class="btn btn-sm btn-icon" v-tooltip="{title: 'Добавить пользователя Telegram'}"><i
                        class="bx bxs-user-check"></i></a>
                    <a href="#remove" class="btn btn-sm btn-icon item-edit"
                        v-tooltip="{title: 'Редактирование'}"
                        @click.prevent="editItem(item.panelid)"><i
                            class="bx bx-edit"></i></a>
                    <a href="#remove" class="btn btn-sm btn-icon item-edit"
                        v-tooltip="{title: 'Удалить'}"
                        @click.prevent="removeItem(item)"><i
                         class="bx bx-trash"></i></a>                         
                  </td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->

      <add-payment-modal v-model="addPaymentModal" :id="id"/>
      <add-panel-modal v-model="addPanelModal" :id="id" @update:page="updatePage"/>
      <panel-telegrams-modal v-model="telegramUsersModal" :id="id"/>
      <add-panel-telegram-modal v-model="addTelegramUserModal" :id="id"/>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import AddApiModal from "@/views/components/Modals/AddApiModal";
import AddPartnerModal from "@/views/components/Modals/AddPartnerModal";
import AddPaymentModal from "@/views/components/Modals/AddPaymentModal";
import AddPanelModal from "@/views/components/Modals/AddPanelModal";
import PanelTelegramsModal from "@/views/components/Modals/PanelTelegramsModal";
import AddPanelTelegramModal from "@/views/components/Modals/AddPanelTelegramModal";
import Swal from "sweetalert2";

export default {
  name: "PanelList",
  components: {AddPanelModal, AddPaymentModal, AddPartnerModal, AddApiModal, Datatable, AddRoleModal, MainLayout, PanelTelegramsModal, AddPanelTelegramModal},
  data() {
    return {
      id: null,
      addPanelModal: false,
      addPaymentModal: false,
      telegramUsersModal: false,
      addTelegramUserModal: false,
      defaultOrder: ['panelid', 'desc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      type_wallet: {
        'fiat': 'Фиатный',
        'crypto': 'Криптовалютный',
      },
      columns: [
        {field: 'panelid', title: '#', sorting: true},
        {field: 'panelname', title: 'Панель'},
        {field: 'currency', title: 'Валюта'},
        {field: 'cryptocurrency', title: 'Криптовалюта'},
        {field: 'type_wallet', title: 'Тип'},
        {field: 'feeRate', title: 'Комиссия', sorting: true},
        {field: 'balance', title: 'Баланс', sorting: true},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    editItem(id) {
      this.id = id
      this.addPanelModal = true
    },
    addPayment(id) {
      this.id = id
      this.addPaymentModal = true
    },
    telegramUsers(id) {
      this.id = id
      this.telegramUsersModal = true
    },
    addTelegramUser(id) {
      this.id = id
      this.addTelegramUserModal = true
    },
    removeItem(item) {
        
        Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите удалить панель ${item.panelname}(#${item.panelid})?`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/panel/remove', {
                    id: item.panelid
                }).then(() => {
                    this.$refs.datatable.loadItems()
                })
            }
        })
    },
    depositItem(id) {
        Swal.fire({
            title: "Введите сумму которую хотите добавить",
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                console.log({
                    id,
                    amount: Number(result.value)
                })
                http.post('/api/panel/deposit', {
                    id,
                    amount: Number(result.value)
                }).then(({data}) => {
                    let {
                        success
                    } = data

                    if (success) {
                        this.$refs.datatable.loadItems()
                    }
                })
            }
        });

    },
    withdrawItem(id) {
        Swal.fire({
            title: "Введите сумму которую хотите списать",
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/panel/withdraw', {
                    id,
                    amount: Number(result.value)
                }).then(({data}) => {
                    let {
                        success
                    } = data

                    if (success) {
                        this.$refs.datatable.loadItems()
                    }
                })
            }
        });

    },
  }
}
</script>

<style scoped>

</style>
