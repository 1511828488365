<template>
  <button :disabled="loading">
    <span class="spinner-border" role="status" aria-hidden="true" v-if="loading"></span>
    <span class="visually-hidden" v-if="loading">Загрузка...</span>
    <slot v-else/>
  </button>
</template>

<script>
export default {
  name: "LoadingButton",
  props: ['loading']
}
</script>

<style scoped>

</style>
