import ActiveCards from "@/views/pages/cards/ActiveCards.vue";
import DisabledCards from "@/views/pages/cards/DisabledCards.vue";
import DeletedCards from "@/views/pages/cards/DeletedCards.vue";
import PanelCards from "@/views/pages/cards/PanelCards.vue";
import HighlightedCards from "@/views/pages/cards/HighlightedCards.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/cards/active/:currency',
        name: 'active-cards',
        component: ActiveCards,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/cards/disabled/:currency',
        name: 'disabled-cards',
        component: DisabledCards,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/cards/deleted/:currency',
        name: 'deleted-cards',
        component: DeletedCards,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/cards/panel/:currency',
        name: 'panel-cards',
        component: PanelCards,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/cards/highlighted/:currency',
        name: 'highlighted-cards',
        component: HighlightedCards,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
]
