<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="!loadingBlock">
        <form method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 class="modal-title" v-if="id">Редактирование сотрудника #{{ id }}</h5>
            <h5 class="modal-title" v-else>Добавление сотрудника</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="name" class="form-label">Имя</label>
                <input type="text" id="name" v-model="item.name" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="login" class="form-label">Логин</label>
                <input type="text" id="login" v-model="item.login" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="password" class="form-label">Пароль</label>
                <input type="text" id="password" v-model="item.password" class="form-control"
                       placeholder="123456" data-np-checked="1">
                <small v-if="id">Оставьте пустым, что бы не изменять</small>
              </div>
              <div class="col-12 mb-3">
                <label for="role_id" class="form-label">Роль</label>
                <select2 id="role_id" v-model.number="item.role_id" :items="roles" :options="{titleField: 'title', valueField: 'id'}"/>
              </div>
              <div class="col-12 mb-3">
                <label for="day_limit_ia_rub" class="form-label">Количество выданных заявок RUB (Суточный)</label>
                <input type="text" id="day_limit_ia_rub" v-model="item.day_limit_ia_rub" class="form-control"
                       placeholder="0" data-np-checked="1">
                <small v-if="id">Оставьте пустым, что бы не изменять</small>
              </div>
              <div class="col-12 mb-3">
                <label for="max_day_limit_ia_rub" class="form-label">Лимит на выдачу заявок RUB (Суточный)</label>
                <input type="text" id="max_day_limit_ia_rub" v-model="item.max_day_limit_ia_rub" class="form-control"
                       placeholder="0" data-np-checked="1">
                <small v-if="id">Оставьте пустым, что бы не изменять</small>
              </div>
              <div class="col-12 mb-3">
                <label for="day_limit_ia_uah" class="form-label">Количество выданных заявок UAH (Суточный)</label>
                <input type="text" id="day_limit_ia_uah" v-model="item.day_limit_ia_uah" class="form-control"
                       placeholder="0" data-np-checked="1">
                <small v-if="id">Оставьте пустым, что бы не изменять</small>
              </div>
              <div class="col-12 mb-3">
                <label for="max_day_limit_ia_uah" class="form-label">Лимит на выдачу заявок UAH (Суточный)</label>
                <input type="text" id="max_day_limit_ia_uah" v-model="item.max_day_limit_ia_uah" class="form-control"
                       placeholder="0" data-np-checked="1">
                <small v-if="id">Оставьте пустым, что бы не изменять</small>
              </div>
              <div class="col-12 mb-3" v-if="id">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="1"
                         v-model="item.hasBan" id="hasBan-toggle">
                  <label class="form-check-label" for="hasBan-toggle">Блокировка входа</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";
import LoadingBlock from "@/views/components/UI/LoadingBlock";

export default {
  name: "AddUserModal",
  props: ['id', 'modelValue', 'roles'],
  components: {LoadingBlock, Select2, BootstrapSelect, LoadingButton, Modal},
  data() {
    return {
      urls: {
        get: '/api/user/get',
        add: '/api/user/add',
        save: '/api/user/save'
      },
      loadingBlock: false,
      loading: false,
      item: {},
      defaultItem: {
        name: null,
        login: null,
        password: null,
        role_id: null,
        day_limit_ia_rub: null,
        max_day_limit_ia_rub: null,
        day_limit_ia_uah: null,
        max_day_limit_ia_uah: null,
        hasBan: null,
      },
    }
  },
  created() {
    this.item = {...this.defaultItem}
  },
  watch: {
    id(newVal) {
      if (newVal) {
        this.loadingBlock = true
        http.post(this.urls.get, {id: this.id}).then(({data}) => {
          let {
            success,
            item
          } = data

          if (success) {
            for (let field of Object.keys(this.item)) {
              this.item[field] = item[field]

              // extra
              if (field === 'password') {
                this.item[field] = null
                continue
              }
            }
          }

        }).finally(() => {
          this.loadingBlock = false
        })
      } else {
        for (let field of Object.keys(this.item)) {
          this.item = {...this.defaultItem}
        }
      }
    }
  },
  computed: {
    saveUrl() {
      return this.id ? this.urls.save : this.urls.add
    }
  },
  methods: {
    saveItem() {
      this.loading = true
      console.log({
        id: this.id,
        item: this.item
      })
      http.post(this.saveUrl, {
        id: this.id,
        item: this.item
      }).then(({data}) => {

        let {
          success,
          item
        } = data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
