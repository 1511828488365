<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Выдать за заявку | #{{ missingPayId }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col mb-3">
              <label for="orderId" class="form-label">Код заявки</label>
              <input type="text" id="orderId" v-model="orderId" class="form-control"
                     placeholder="Введите код заявки"
                     data-np-checked="1">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
          <loading-button :loading="loading" type="button" class="btn btn-label-success" @click.prevent="giveOrder">
            Выдать за заявку
          </loading-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingButton from "@/views/components/UI/LoadingButton";
import http from "@/http";

export default {
  name: "GiveOrderModal",
  props: ['modelValue', 'missingPayId'],
  components: {LoadingButton, Modal},
  data() {
    return {
      loading: false,
      orderId: null
    }
  },
  methods: {
    giveOrder() {
      if (this.loading)
        return

      this.loading = true
      http.post('/api/logs/give_order', {
        order_id: this.orderId,
        missing_pay_id: this.missingPayId
      }).then(({data}) => {

        if (data.success)
          this.$emit('update:modelValue', false)

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
