import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import isGuest from "@/router/middlewares/isGuest";
import isUser from "@/router/middlewares/isUser";
import settings from "@/router/settings";

import Login from '@/views/pages/Login.vue'
import Home from '@/views/pages/Home.vue'
import changes from "@/router/changes";
import user from "@/router/user";
import logs from "@/router/logs";
import cards from "@/router/cards";
import api from "@/router/api";
import partner from "@/router/partner";
import store from "@/router/store";
import utility from "@/router/utility";
import accounting from "@/router/accounting";
import panel from "@/router/panel";
import me from "@/router/me";
import privateapi from "@/router/privateapi";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      middleware: [isUser]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      middleware: [isGuest]
    }
  },
    ...me,
  ...panel,
  ...accounting,
  ...utility,
  ...store,
  ...partner,
  ...api,
  ...cards,
  ...logs,
  ...changes,
  ...settings,
  ...user,
  ...privateapi
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function middlewarePipeline(context: any, middleware: Array<Function>, index: number) {
  const nextMiddleware = middleware[index]

  if (!nextMiddleware) {
    return context.next
  }

  return () => {
    const nextPipeline = middlewarePipeline(
        context, middleware, index + 1
    )

    nextMiddleware({...context, next: nextPipeline})

  }
}

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware as Array<Function>

  const context = {
    to,
    from,
    next,
  }


  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })

})

export const route = (name: string, params: any = {}): string => {
  return router.resolve({name, params}).fullPath
}

export default router
