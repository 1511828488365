<template>
    <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">      
                <div class="col-12">
                    <div class="card">
                      <div class="card-header header-elements">
                        <h5 class="m-0 me-2">Список пользователей</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="card-datatable text-nowrap">
                        <datatable ajax="/api/panel/tguserslist" ref="datatable" :default-order="defaultOrder" :items="items"
                                   :columns="columns" :default-query="defaultQuery">
                          <template #tr="item">
                            <td><span class="fw-semibold">{{ item.telegramName }}</span></td>
                            <td>{{ item.telegramUsername }}</td>
                            <td>{{ item.rolePanel }}</td>
                            <td>
                                <a href="#remove" class="btn btn-sm btn-icon item-edit"
                                    v-tooltip="{title: 'Удалить'}"
                                    @click.prevent="removeItem(item)"><i
                                    class="bx bx-trash"></i></a>
                            </td>
                          </template>
                        </datatable>
                      </div>
                    </div>
                </div>
            </div>
        </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import Datatable from "@/views/components/Datatable";
import Swal from "sweetalert2";

export default {
    name: "PanelTelegramsModal",    
    props: ['id', 'modelValue'],
    components: {LoadingBlock, Modal, Datatable},
    data() {
        return {
            loadingBlock: false,
            defaultOrder: ['id', 'desc'],
            defaultQuery: { },
            items: [],
            columns: [
                {field: 'telegramName', title: 'Имя'},
                {field: 'telegramUsername', title: 'Username'},
                {field: 'rolePanel', title: 'Роль'},
                {field: 'actions', title: 'Действия', width: '110px'},
            ]
        }
    },
    
    created() {
        if (this.id) {
            this.defaultQuery = {...this.defaultQuery, panelid: this.id}
        }
    },

    watch: {
        id(newVal) {
            this.defaultQuery = {...this.defaultQuery, panelid: newVal}
        }
    },
    methods: {
        removeItem(item) {
            Swal.fire({
                title: 'Подтверждение',
                text: `Точно ли вы уверенны, что вы хотите удалить пользователя ${item.telegramName}(@${item.telegramUsername})?`,
                showCancelButton: true,
                confirmButtonText: 'Да',
                cancelButtonText: 'Нет',
            }).then((result) => {
                if (result.isConfirmed) {
                    http.post('/api/panel/removetguser', {
                        id: item.id
                    }).then(() => {
                        this.$refs.datatable.loadItems()
                    })
                }
            })
        }
    }
}
</script>