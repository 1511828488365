<template>
  <div class="container-xxl">
    <div class="authentication-wrapper authentication-basic container-p-y">
      <div class="authentication-inner py-4">
        <div class="card">
          <div class="card-body">
            <div class="app-brand justify-content-center">
              <router-link :to="{name: 'login'}" class="app-brand-link gap-2">
                <span class="app-brand-text demo h3 mb-0 fw-bold">Панель управления</span>
              </router-link>
            </div>

            <form id="formAuthentication" class="mb-3" action="/api/account/login" method="POST">
              <div class="mb-3">
                <label for="name" class="form-label">Логин</label>
                <input type="text" class="form-control" id="name" name="login" placeholder="Введите ваш логин" autofocus
                       v-model.trim="login">
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Пароль</label>
                <input type="password" class="form-control" id="password" name="password" placeholder="******"
                       v-model.trim="password">
              </div>
              <div class="mb-3">
                <label for="otp" class="form-label">2FA (Если есть)</label>
                <input type="text" class="form-control" id="otp" name="otp" placeholder="12345"
                       v-model.trim="otp">
              </div>
              <div class="mb-3">
                <loading-button class="btn btn-primary w-100" type="submit" :loading="loading" @click.prevent="doLogin">
                  Войти
                </loading-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/vendor/css/pages/page-auth.css'
import LoadingButton from "@/views/components/UI/LoadingButton";
import http from "@/http";
import {BACKEND_URL} from "@/constants";
import router, {route} from "@/router";

export default {
  name: "Login",
  components: {LoadingButton},
  data() {
    return {
      login: null,
      password: null,
      otp: null,
      loading: false
    }
  },
  methods: {
    doLogin() {
      if (this.loading)
        return

      this.loading = true

      http.post('/api/account/login', {
        login: this.login,
        password: this.password,
        otp: this.otp,
      }).then(response => {
        if (response.data.success)
          router.push({name: 'home'})
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
