<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form method="post" @submit.prevent="addPayment">
          <div class="modal-header">
            <h5 class="modal-title">Добавление платежи</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
                <div class="col-12 mb-3" v-if="!id">
                    <label for="currency" class="form-label">Валюта</label>
                    <select2 id="currency" v-model.number="item.currency" :items="currencies"/>
                </div>
                <div class="col-12 mb-3">
                    <label for="card" class="form-label">Карта</label>
                    <select2 id="card" v-model="item.card" :items="cards.filter(c => c.currency === item.currency)" :options="{title: (e) => `${e.lastdigits} (${e.comment})`, valueField: 'lastdigits'}"/>
                </div>
                <div class="col-12 mb-3">
                    <label for="amount" class="form-label">Список платежей через запятую</label>
                    <input type="text" id="amount" v-model="item.amount" class="form-control"
                        placeholder="123" data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                    <label for="amount" class="form-label">Время по UTC</label>
                    <input type="text" class="form-control" placeholder="YYYY-MM-DD HH:mm"
                        v-flatpickr="{enableTime: true, onClose: selectDate()}"/>
                </div> 
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";

export default {
  name: "AddPaymentUtilsModal",
  props: ['modelValue'],
  components: {Select2, BootstrapSelect, LoadingButton, Modal},
  data() {
    return {
      loading: false,
      item: {},
      defaultItem: {
        currency: null,
        amount: null,
        card: null,
        time: null
      },
      cards: [],
      
      currencies: [
        {title: 'RUB', value: 'RUB'},
        {title: 'UAH', value: 'UAH'},
        {title: 'KZT', value: 'KZT'}
      ],
    }
  },
  created() {
    this.item = {...this.defaultItem}
  },  
  mounted() {

    http.post('/api/cards/get_all_active').then(({data}) => {
        this.cards = data.items;
    })
  },
  methods: {
    selectDate(column, type) {
      return (selectedDates) => {
        this.item.time = selectedDates[0];
      }
    },
    addPayment() {
      this.loading = true
      http.post('/api/utility/add_missing_pays', {
        id: this.id,
        item: this.item
      }).then(({data}) => {

        let {
          success,
          item
        } = data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
