import {Tooltip} from "bootstrap";

export default {
    mounted(el: any, binding: any) {
        el.tooltop = new Tooltip(el, binding.value)
    },
    unmounted(el: any) {
        el.tooltop.dispose()
    }
}
