import http from "@/http";
import toastr from "toastr";
import Swal from "sweetalert2";

export const minifyAddress = (data: string) => {
    if (!data)
        return '-'

    if (data.length > 6)
        return data.slice(0, 3) + '...' + data.slice(data.length - 3, data.length)

    return data
}

export const rescanBlockchain = (crypto: 'BTC' | 'USDT' | 'XMR') => {
    return http.post('/api/store/rescan', {
        crypto
    })
}

export const getNewAddress = (crypto: 'BTC' | 'USDT' | 'XMR', label_id: number) => {
    return http.post('/api/store/get_new_address', {
        crypto,
        label_id
    })
}

export const getTxLink = (crypto: string, txID: string) => {
    crypto = crypto.toUpperCase()

    if (crypto === 'BTC')
        return `https://blockchain.com/btc/tx/${txID}`
    else if (crypto === 'USDT')
        return `https://tronscan.org/#/transaction/${txID}`
}


export function fallbackCopyTextToClipboard(text: string) {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        toastr.success('Текст скопирован')
    } catch (err) {
        return false
    }

    document.body.removeChild(textArea);

    return successful
}
export function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {
        toastr.success('Текст скопирован')
    }, function(err) {
        return false
    });
}
