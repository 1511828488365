<template>
  <main-layout>
    <div class="container-xxl flex-grow-1 container-p-y">
      <div class="row">
        <div class="col-md-12">
          <!-- Change Password -->
          <div class="card mb-4">
            <h5 class="card-header">Изменить пароль</h5>
            <div class="card-body">
              <form @submit.prevent="changePassword">
                <div class="row">
                  <div class="mb-3 col-md-6 form-password-toggle">
                    <label class="form-label" for="newPassword">Новый пароль</label>
                    <div class="input-group input-group-merge">
                      <input class="form-control" type="password" id="newPassword" name="newPassword" v-model="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                      <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                    </div>
                  </div>

                  <div class="mb-3 col-md-6 form-password-toggle">
                    <label class="form-label" for="confirmPassword">Повторный пароль</label>
                    <div class="input-group input-group-merge">
                      <input class="form-control" type="password" name="confirmPassword" id="confirmPassword" v-model="password_confirmation" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                      <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                    </div>
                  </div>
<!--                  <div class="col-12 mb-4">-->
<!--                    <p class="fw-semibold mt-2">Password Requirements:</p>-->
<!--                    <ul class="ps-3 mb-0">-->
<!--                      <li class="mb-1">-->
<!--                        Minimum 8 characters long - the more, the better-->
<!--                      </li>-->
<!--                      <li class="mb-1">At least one lowercase character</li>-->
<!--                      <li>At least one number, symbol, or whitespace character</li>-->
<!--                    </ul>-->
<!--                  </div>-->
                  <div class="col-12 mt-1">
                    <button type="submit" class="btn btn-primary me-2">Изменить пароль</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!--/ Change Password -->
          <div class="card mb-4">
            <h5 class="card-header">Двухфакторная аутентификация</h5>
            <div class="card-body">
              <p class="fw-semibold mb-3">Статус: <span class="text-success" v-if="user.otp_enabled">Включена</span><span class="text-danger" v-else>Отключена</span></p>
              <p class="w-75">Для авторизации Вам понабодится Google Authenticator</p>
              <button class="btn btn-success mt-2" v-if="!user.otp_enabled" @click.prevent="enableOTP">Включить</button>
              <button class="btn btn-danger mt-2" v-else="user.otp_enabled" @click.prevent="disableOTP">Отключить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <two-factor-modal v-model="twoFactorModal" :data="image"/>
    <two-factor-disable-modal v-model="twoFactorDisableModal"/>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import http from "@/http";
import {mapMutations, mapState} from "vuex";
import TwoFactorModal from "@/views/components/Modals/TwoFactorModal";
import TwoFactorDisableModal from "@/views/components/Modals/TwoFactorDisableModal";

export default {
  name: "Me",
  components: {TwoFactorDisableModal, TwoFactorModal, MainLayout},
  data() {
    return {
      image: null,
      password: null,
      password_confirmation: null,
      twoFactorModal: false,
      twoFactorDisableModal: false
    }
  },
  methods: {
    ...mapMutations('user', ['setUser', 'setToken']),
    changePassword() {
      http.post('/api/account/change_password', {
        password: this.password,
        password_confirmation: this.password_confirmation,
      }).then(({data}) => {
        let {
          success
        } = data

        if (success) {
          this.setUser(null)
          this.setToken(null)
          this.$router.push({name: 'login'})
        }
      })
    },
    enableOTP() {
      this.image = null
      http.post('/api/account/get_otp').then(({data}) => {
        let {
          success,
            qr,
            secret
        } = data

        if (success) {
          this.image = {qr, secret}
        }
      })
      this.twoFactorModal = true
    },
    disableOTP() {
      this.twoFactorDisableModal = true
    }
  },
  computed: {
    ...mapState('user', ['user'])
  }
}
</script>

<style scoped>

</style>
