<template>
    <div class="error_text_text">
        {{ result }}
    </div>
  
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "MomentFormat",
  props: ['input', 'output', 'unix', 'utcOffset'],
  computed: {
    result() {
      let dateTime = (this.unix ? moment.unix(this.input) : moment(this.input))

      if (this.utcOffset)
        dateTime.utcOffset(this.utcOffset)

      return dateTime.format(this.output)
    }
  }
}
</script>

<style scoped>

</style>
