<template>
  <main-layout :code="10">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Партнеры</h5>
              <div class="card-header-elements ms-auto">
                <a href="#add-partner" @click.prevent="editItem(null)" class="btn btn-sm btn-secondary"><span
                    class="tf-icon bx bx-plus"></span> Добавить партнера</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/partner/list" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.id }}</span></td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.sourceCode }}</td>
                  <td>{{ item.currency }}</td>
                  <td>{{ item.tokens.length || 'нет' }}</td>
                  <td v-if="item.is_p2p_toggle">Да</td>
                  <td v-else>Нет</td>
                  <td>
                    <a href="#edit" @click.prevent="editItem(item.id)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Редактировать'}"><i
                        class="bx bx-edit"></i></a>
                    <a href="#remove" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Удалить'}"
                       @click.prevent="removeItem(item)"><i
                        class="bx bx-trash"></i></a>
                  </td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->

      <add-partner-modal v-model="addPartnerModal" :id="id" @update:page="updatePage"/>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import AddApiModal from "@/views/components/Modals/AddApiModal";
import AddPartnerModal from "@/views/components/Modals/AddPartnerModal";
import Swal from "sweetalert2";

export default {
  name: "PartnerList",
  components: {AddPartnerModal, AddApiModal, Datatable, AddRoleModal, MainLayout},
  data() {
    return {
      id: null,
      addPartnerModal: false,
      defaultOrder: ['id', 'desc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'id', title: '#', sorting: true},
        {field: 'name', title: 'Партнер'},
        {field: 'sourceCode', title: 'Код'},
        {field: 'currency', title: 'Валюта'},
        {field: 'tokens', title: 'Ключи'},
        {field: 'is_p2p_toggle', title: 'P2P Карты'},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    editItem(id) {
      this.id = id
      this.addPartnerModal = true
    },
    removeItem(item) {
        
        Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите удалить партнера ${item.name}(#${item.id})?`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/partner/remove', {
                    id: item.id
                }).then(() => {
                    this.$refs.datatable.loadItems()
                })
            }
        })
    }
  }
}
</script>

<style scoped>

</style>
