import { createApp } from 'vue'
import VueApexCharts from "vue3-apexcharts";
import moment from "moment-timezone";
import flatpickr from "flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";

import ClickOutside from "@/directives/ClickOutside";
import BootstrapTooltip from "@/directives/BootstrapTooltip";
import BootstrapModal from "@/directives/BootstrapModal";
import PerfectScrollbar from "@/directives/PerfectScrollbar";
import CleaveDirective from "@/directives/CleaveDirective";
import FlatPickr from "@/directives/FlatPickr";

import router from '@/router'
import store from '@/store'
import http from "@/http";
import socket from "@/socketio";


import mixins from '@/mixins/mixins';

import "bootstrap"
import "@/assets/css/ibm-plex-sans.css"
import "@/assets/css/rubik.css"

import App from './App.vue'


async function start() {
    moment.locale('ru')
    moment.tz.setDefault("Europe/Moscow")
    flatpickr.setDefaults({
        locale: Russian,
    })

    store.commit('user/setToken', localStorage.getItem('token') || null)

    http.post('/api/start').finally(() => {
        createApp(App)
            .directive('click-outside', ClickOutside)
            .directive('tooltip', BootstrapTooltip)
            .directive('modal', BootstrapModal)
            .directive('perfect-scrollbar', PerfectScrollbar)
            .directive('cleave', CleaveDirective)
            .directive('flatpickr', FlatPickr)
            .use(store)
            .use(router)
            .use(VueApexCharts)
            .mixin(mixins)
            .mount('#app')
    })
}

start()
