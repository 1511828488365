<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form method="post" @submit.prevent="connectToPanel">
          <div class="modal-header">
            <h5 class="modal-title">Привязать к панели</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="panel_id" class="form-label">Панель</label>
                <select2 id="panel_id" v-model.number="panel_id" :items="panels"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Привязать</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";

export default {
  name: "ConnectToPanelModal",
  props: ['id', 'modelValue'],
  components: {Select2, BootstrapSelect, LoadingButton, Modal},
  data() {
    return {
      panels: [],
      panel_id: null,
      loading: false,
    }
  },
  watch: {
    id(newVal) {
      if (newVal) {
        this.loading = true
        http.post('/api/panel/list', {order: ['panelid', 'desc']}).then(({data}) => {
          let {
            success,
            items
          } = data

          if (success) {
            for (let item of items) {
              this.panels.push({title: item.panelname, value: item.panelid})
            }
          }

        }).finally(() => {
          this.loading = false
        })
      } else {
        this.panels = []
      }
    }
  },
  methods: {
    connectToPanel() {
      this.loading = true
      http.post('/api/cards/connect', {
        id: this.id,
        panel_id: this.panel_id
      }).then(({data}) => {

        let {
          success,
        } = data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
