<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="!loadingBlock">
        <form method="post" @submit.prevent="editAddress">
          <div class="modal-header">
            <h5 class="modal-title">Редактировние адреса</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="label_id" class="form-label">Лейбл</label>
                <select2 id="label_id" v-model.number="label_id" :items="labels" :options="{titleField: 'labelName', valueField: 'autoID'}"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";
import LoadingBlock from "@/views/components/UI/LoadingBlock";

export default {
  name: "EditAddressModal",
  props: ['walletId', 'crypto', 'modelValue'],
  components: {LoadingBlock, Select2, BootstrapSelect, LoadingButton, Modal},
  data() {
    return {
      loadingBlock: false,
      label_id: null,
      loading: false,
      labels: [],
    }
  },
  watch: {
    modelValue(newVal) {
      if (newVal) {
        this.loadingBlock = true
        http.post('/api/store/get_labels', {
          crypto: this.crypto,
          page: 1,
          limit: 100,
        }).then(({data}) => {
          let {
            success,
            items
          } = data

          if (success) {
            this.labels = items
          }
        }).finally(() => {
          this.loadingBlock = false
        })
      }
    }
  },
  methods: {
    editAddress() {
      this.loading = true
      http.post('/api/store/edit_address', {
        wallet_id: this.walletId,
        label_id: this.label_id,
      }).then(({data}) => {

        let {
          success,
        } = data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
