<template>
  <li class="nav-item navbar-search-wrapper me-2 me-xl-0">
    <a class="nav-item nav-link search-toggler" href="javascript:void(0);">
      <i class="bx bx-search bx-sm"></i>
    </a>
  </li>
</template>

<script>
export default {
  name: "Search"
}
</script>

<style scoped>

</style>
