import PaymentLogs from "@/views/pages/logs/PaymentLogs.vue";
import WithdrawLogs from "@/views/pages/logs/WithdrawLogs.vue";
import OtherLogs from "@/views/pages/logs/OtherLogs.vue";
import ApiLogs from "@/views/pages/logs/ApiLogs.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/logs/payments/:currency',
        name: 'payment-logs',
        component: PaymentLogs,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/logs/withdraw/:currency',
        name: 'withdraw-logs',
        component: WithdrawLogs,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/logs/other/:currency',
        name: 'other-logs',
        component: OtherLogs,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/logs/api',
        name: 'api-logs',
        component: ApiLogs,
        props: true,
        meta: {
            middleware: [isUser]
        }
    }
]
