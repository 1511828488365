<template>
  <div class="col-lg-4 col-12">
    <div class="row">
      <!-- Statistics Cards -->
      <div class="col-6 col-md-3 col-lg-6 mb-4" style="height: fit-content;" v-for="item in rates" :key="item.id">
        <div class="card">
          <div class="change-currency">
            <button class="btn p-0" type="button" @click.prevent="editWidget(item.id)">
                <i class="bx bx-cog"></i>
            </button>
            <button class="btn p-0" type="button" @click.prevent="deleteWidget(item)">
                <i class="bx bx-trash"></i>
            </button>
          </div>
          <div class="card-body text-center" :class="{error_text: (moment(item.rate?.lastupdate).diff() / 1000) < -1800}">
            <div class="avatar mx-auto">
              <img :src="getLogo(item.rate)" alt="btc">
            </div>
            <span class="d-block text-nowrap error_text_text">{{ item?.rate?.name ?? '-' }} ({{ item?.rate?.currency ?? '-' }})</span>
            <h2 class="mb-0 error_text_text">{{ item.rate?.value ?? '-' }}</h2>
            <moment-format :input="item.rate?.lastupdate" output="YYYY-MM-DD HH:mm"/>
            <small class="text-muted error_text_text">{{ moment(item.rate?.lastupdate).fromNow() }}</small>
          </div>
        </div>
      </div>
      <!--/ Statistics Cards -->
      <div class="col-6 col-md-3 col-lg-6 mb-4" @click.prevent="editWidget(null)" v-if="rates.length < 4"><a
          class="h-100" style="
    border: 5px dashed #a8b1bb;
    display: block;
    height: fit-content;
" href="#">
        <div class="text-center"><span data-v-265428ee="" style="
    color: #a8b1bb;
    font-size: 64px;
    line-height: 120px;
">+</span></div>
      </a></div>
    </div>

    <add-rate-widget-modal v-model="addRateWidgetModal" :id="id" :line="line" @update:page="updatePage"/>
  </div>
</template>

<script>

import moment from "moment-timezone";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import AddRateWidgetModal from "@/views/components/Modals/AddRateWidgetModal";
import Swal from "sweetalert2";
import http from "@/http";

export default {
  name: "RatesWidget",
  components: {AddRateWidgetModal, MomentFormat},
  props: ['items', 'line'],
  data() {
    return {
      rates: [],
      id: null,
      moment,
      addRateWidgetModal: false
    }
  },
  created() {
    this.updateRates()
  },
  watch: {
    items() {
      this.updateRates()
    }
  },
  methods: {
    getLogo(rate) {
      let result = '/assets/svg/icons/'

      switch (rate.crypto.toUpperCase()) {
        case 'BTC':
          result += 'bitcoin-btc-logo.svg'
          break
        case 'USDT':
          result += 'tether-usdt-logo.svg'
          break
        case 'ETH':
          result += 'ethereum-eth-logo.svg'
          break
        case 'LTC':
          result += 'litecoin-ltc-logo.svg'
          break
      }

      return result

    },
    editWidget(id) {
      this.id = id
      this.addRateWidgetModal = true
    },
    deleteWidget(item) {
      Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите удалить курс ${ item?.rate?.name ?? '-' } (${ item?.rate?.currency ?? '-' })?`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/home/rate_dell', {
                    id: item.id
                }).then(() => {
                    this.updatePage()
                })
            }
        })
    },
    updateRates() {
      try {
        this.rates = this.items[this.line] || []
      } catch (e) {
      }
    },
    updatePage() {
      this.$emit('update:page')
    }
  }
}
</script>

<style scoped>
.change-currency {
  position: absolute;
  right: 10px;
  top: 5px;
}
</style>
