import RateSettings from "@/views/pages/settings/RateSettings.vue";
import ParserSettings from "@/views/pages/settings/ParserSettings.vue";
import BotSettings from "@/views/pages/settings/BotSettings.vue";
import BotAOSettings from "@/views/pages/settings/BotAOSettings.vue";
import BotSupport from "@/views/pages/settings/BotSupport.vue";
import BotPanelSettings from "@/views/pages/settings/BotPanelSettings.vue";
import WebhookSettings from "@/views/pages/settings/WebhookSettings.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/settings/rate',
        name: 'settings-rate',
        component: RateSettings,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/settings/parser',
        name: 'settings-parser',
        component: ParserSettings,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/settings/bot',
        name: 'settings-bot',
        component: BotSettings,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/settings/botao',
        name: 'settings-botao',
        component: BotAOSettings,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/settings/botsupport',
        name: 'settings-botsupport',
        component: BotSupport,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/settings/botpanel',
        name: 'settings-botpanel',
        component: BotPanelSettings,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/settings/webhook',
        name: 'settings-webhook',
        component: WebhookSettings,
        meta: {
            middleware: [isUser]
        }
    },
]