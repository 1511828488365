<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="data">
        <div class="modal-header">
          <h5 class="modal-title">История изменений | #{{ data.item.uuID }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul class="timeline">
            <li class="timeline-item timeline-item-transparent ps-4" v-for="(row, index) in data.history" :key="index">
              <span class="timeline-point timeline-point-primary"></span>
              <div class="timeline-event pb-2">
                <div class="timeline-header mb-1">
                  <h6 class="mb-0">{{ row.title }}</h6>
                  <small class="text-muted"><moment-format :input="row.time" :output="DEFAULT_TIME_FORMAT"/></small>
                </div>
                <p class="mb-2">{{ row.description }}</p>
              </div>
            </li>
            <li class="timeline-end-indicator">
              <i class="bx bx-check-circle"></i>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import moment from "moment-timezone";
import {DEFAULT_TIME_FORMAT} from "@/constants";

export default {
  name: "ChangeHistoryModal",
  props: ['modelValue', 'data'],
  components: {MomentFormat, LoadingBlock, Modal},
  data() {
    return {
      moment,
      DEFAULT_TIME_FORMAT
    }
  }
}
</script>

<style scoped>

</style>
