<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="!loadingBlock">
        <form action="/api/cards/save" method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 class="modal-title" v-if="!id">Добвление карты</h5>
            <h5 class="modal-title" v-else>Изменение карты</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3" v-if="!id || typeof id === 'number' || id.length === 1">
                <label for="numbercard" class="form-label">Номер карты</label>
                <input type="text" id="numbercard" v-model="item.numbercard" class="form-control"
                       placeholder="0000 0000 0000 0000"
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="comment" class="form-label">Комментарий</label>
                <input type="text" id="comment" v-model="item.comment" class="form-control"
                       placeholder="Произвольный комментарий"
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="port" class="form-label">Порт</label>
                <input type="text" id="port" v-model="item.port" class="form-control" placeholder="Введите порт"
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3" v-if="partners">
                <label for="source" class="form-label">Источник</label>
                
                <select2 id="source" v-model:items="partners" v-model="item.source" :options="{titleField: 'name', valueField: 'id'}"/>
              </div>

              <template v-if="id">
                <div class="col-12 mb-3">
                  <label for="todayWithdrawCount" class="form-label">Израсходовано сегодня</label>
                  <input type="text" id="todayWithdrawCount" v-model="item.todayWithdrawCount" class="form-control" placeholder="0"
                         data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                  <label for="todayWithdrawLimit" class="form-label">Лимит сегодня</label>
                  <input type="text" id="todayWithdrawLimit" v-model="item.todayWithdrawLimit" class="form-control" placeholder="1000"
                         data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                  <label for="monthlyWithdrawCount" class="form-label">Израсходовано за месяц</label>
                  <input type="text" id="monthlyWithdrawCount" v-model="item.monthlyWithdrawCount" class="form-control" placeholder="0"
                         data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                  <label for="monthlyWithdrawLimit" class="form-label">Лимит за месяц</label>
                  <input type="text" id="monthlyWithdrawLimit" v-model="item.monthlyWithdrawLimit" class="form-control" placeholder="1000"
                         data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                  <label for="order_limit" class="form-label">Лимит заявок</label>
                  <input type="text" id="order_limit" v-model="item.order_limit" class="form-control" placeholder="1000"
                         data-np-checked="1">
                </div>
              </template>

              <template v-if="currency === 'RUB'">
                <div class="col-12 mb-3">
                    <label for="phone" class="form-label">Номер телефона (Не обязательный)</label>
                    <input type="text" id="phone" v-model="item.phone" class="form-control" placeholder="Введите номер телефона"
                           data-np-checked="1">
                </div>
                
                <div class="col-12 mb-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="1" v-model="item.sbp_enable" id="sbp_enable">
                        <label class="form-check-label" for="sbp_enable">СБП</label>
                    </div>
                </div>

                <div class="col-12 mb-3">
                  <label for="source" class="form-label">Банк</label>
                  
                  <select2 id="source" v-model:items="banks" v-model="item.bank" :options="{ titleField: 'title', valueField: 'value' }" />
                </div>

                <div class="col-12 mb-3">
                    <label for="fio" class="form-label">ФИО</label>
                    <input type="text" id="fio" v-model="item.fio" class="form-control" placeholder="Введите номер телефона"
                           data-np-checked="1">
                </div>
              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingButton from "@/views/components/UI/LoadingButton";
import http from "@/http";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import Select2 from "@/views/components/UI/Select2";

export default {
  name: "AddCardModal",
  props: ['id', 'modelValue', 'currency'],
  components: {LoadingBlock, LoadingButton, Modal, Select2},
  data() {
    return {
      loadingBlock: false,
      loading: false,
      urls: {
        get: '/api/cards/get',
        add: '/api/cards/add',
        save: '/api/cards/save'
      },
      item: {},
      partners: null,
      banks: [
        { title: 'Райффайзен Банк', value: 'raiffeisen' }, 
      ],
      defaultItem: {
        numbercard: null,
        comment: null,
        port: null,
        source: null,
        todayWithdrawCount: null,
        todayWithdrawLimit: null,
        monthlyWithdrawCount: null,
        monthlyWithdrawLimit: null,
        order_limit: null,
        phone: null,
        bank: null,
        sbp_enable: null,
        fio: null,
      }
    }
  },
  created() {
    this.item = {...this.defaultItem}
  },
  mounted() {
    http.post('/api/partner/list').then(({data}) => {
      this.partners = data.items
    })
  },
  computed: {
    saveUrl() {
      return this.id ? this.urls.save : this.urls.add
    }
  },
  watch: {
    id(newVal) {
      if (newVal && (typeof newVal === 'number' || (typeof newVal === 'object' && newVal.length === 1))) {
        this.loadingBlock = true
        http.post(this.urls.get, {id: typeof this.id === 'number' ? this.id : this.id[0]}).then(({data}) => {
          let {
            success,
            item
          } = data

          if (success) {
            for (let field of Object.keys(this.item)) {
              this.item[field] = item[field]
            }
          }

        }).finally(() => {
          this.loadingBlock = false
        })
      } else {
        this.loadingBlock = false
        for (let field of Object.keys(this.item)) {
          this.item = {...this.defaultItem}
        }

        // Extra
        this.item.currency = this.currency
      }
    }
  },
  methods: {
    saveItem() {
      this.loading = true
      http.post(this.saveUrl, {
        id: this.id,
        item: {...this.item, currency: this.currency}
      }).then(response => {

        let {
          success
        } = response.data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
