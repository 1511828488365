<template>
  <main-layout :code="6">
    <change-currency-layout route="active-cards">
      <div class="card">
        <div class="card-header header-elements">
            <div class="card-body" style="padding: 0;">
                <h5 class="m-0 me-2">Активные карты ({{ currency }})</h5>
                <span class="d-block text-nowrap" v-if="currency === 'UAH'">Свободные карты(Кол-во заказов): {{ orderSum }}</span>
            </div>
            <div class="card-header-elements ms-auto">
                <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                    class="tf-icon bx bx-refresh"></span> Обновить данные</a>
            </div>
        </div>
        <div class="card-datatable text-nowrap">
          <div class="btn-group p-3" role="group">
            <button type="button" class="btn btn-danger" @click.prevent="removeCard(checkedItems)"
                    :disabled="!checkedItems.length">Удалить
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="editCard(checkedItems)"
                    :disabled="!checkedItems.length">Изменить
            </button>
            <button type="button" class="btn btn-warning" @click.prevent="disableCard(checkedItems)"
                    :disabled="!checkedItems.length">Выключить
            </button>
          </div>
          <datatable ajax="/api/cards/list" ref="datatable" :default-query="defaultQuery" :default-order="defaultOrder"
                     v-model:items="items"
                     :columns="columns" :checkbox="true" checkbox-key="autoID" v-model:checked-items="checkedItems">
            <template #tr="item">
              <td><span class="fw-semibold">{{ item.autoID }}</span></td>
              <td>
                **** {{ item.lastdigits }}
                <template v-if="item.phone && item.phone.length">
                    <small class="text-muted">{{ item.phone }}</small>
                </template>
              </td>
              <td v-if="currency === 'RUB'">
                <a href="#" v-if="item.sbp_enable">Да</a>
                <i v-else>Нет</i>
              </td>
              <td v-if="currency === 'KZT'">
                <a href="#" v-if="item.customerID">Перейти / {{ item.customerID }}</a>
                <i v-else>нет</i>
              </td>
              <td v-if="item.comment">{{ item.comment }}</td>
              <td v-else><i>нет</i></td>
              <td>
                <span>
                  <span>
                  <span v-if="item.enabledUprate"><badge
                      :color="item.enabledUprate >= 50 ? COLORS.Success : COLORS.Danger"/> {{ item.enabledUprate }}</span>
                  <i v-else>нет</i>
                </span> /
                <span>
                  <span v-if="item.uprate"><badge
                      :color="item.uprate >= 50 ? COLORS.Success : COLORS.Danger"/> {{ item.uprate }}</span>
                  <i v-else>нет</i>
                </span>
                </span>
              </td>
              <td>
                <span>{{ item.todayWithdrawCount || '-' }} из {{ item.todayWithdrawLimit || '-' }}</span><br>
                <small>({{ item.monthlyWithdrawCount || '-' }} из {{ item.monthlyWithdrawLimit || '-' }})</small>
              </td>
              <td v-if="currency === 'UAH'">
                {{ item.waiting_order_limit }}/{{ item.success_order_limit }} из {{ item.order_limit }}
              </td>
              <td v-if="currency !== 'UAH'">
                <span v-if="item?.partner">{{ item?.partner?.name }}</span>
                <i v-else>нет</i>
              </td>
              <td v-if="currency !== 'UAH'">
                <span v-if="item.port">{{ item.port }}</span>
                <i v-else>нет</i>
              </td>
              <td>{{ item.balance }} {{ item.currency }}</td>
              <td v-if="currency === 'UAH'">
                <moment-format v-if="item.lastEnable" :input="item.lastEnable" :output="DEFAULT_TIME_FORMAT"/>
                <i v-else>нет</i>
              </td>
              <td>
                <a href="#disableCard" @click.prevent="disableCard([item.autoID])" class="btn btn-sm btn-icon item-edit"
                   v-tooltip="{title: 'Выключить карту'}"><i
                    class="bx bx-power-off"></i></a>
                <a href="#fullInfo" @click.prevent="fullInfo(item)" class="btn btn-sm btn-icon item-edit"
                   v-tooltip="{title: 'Информация о карте'}"><i
                    class="bx bx-fullscreen"></i></a>
                <a href="#editCard" @click.prevent="editCard(item.autoID)" class="btn btn-sm btn-icon item-edit"
                   v-tooltip="{title: 'Изменить карту'}"><i
                    class="bx bx-edit"></i></a>
                <a href="#cardHistory" @click.prevent="cardHistory(item)" class="btn btn-sm btn-icon item-edit"
                   v-tooltip="{title: 'История изменений'}"><i
                    class="bx bx-history"></i></a>
                <a href="#removeCard" @click.prevent="removeCard([item.autoID])" class="btn btn-sm btn-icon item-edit"
                   v-tooltip="{title: 'Удалить карту'}"><i
                    class="bx bx-trash"></i></a>
              </td>
            </template>
          </datatable>
          <add-card-modal v-model="addCardModal" :id="id" :currency="currency" @update:page="updatePage"/>
          <card-full-info-modal v-model="cardFullInfoModal" :data="cardFullInfo"/>
          <card-history-modal v-model="cardHistoryModal" :data="cardHistoryInfo"/>
          <disable-card-modal v-model="disableCardModal" :ids="disableCardIds" :items="items"
                              @update:page="updatePage"/>
        </div>
      </div>
    </change-currency-layout>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import ChangeCurrencyLayout from "@/views/layouts/ChangeCurrencyLayout";
import {CARD_LANG, COLORS, COMMON_LANG, DEFAULT_TIME_FORMAT} from "@/constants";
import Datatable from "@/views/components/Datatable";
import AddCardModal from "@/views/components/Modals/AddCardModal";
import http from "@/http";
import CardFullInfoModal from "@/views/components/Modals/CardFullInfoModal";
import CardHistoryModal from "@/views/components/Modals/CardHistoryModal";
import Swal from 'sweetalert2'
import MomentFormat from "@/views/components/UI/Moment/MomentFormat.vue";
import Badge from "@/views/components/UI/Badge.vue";
import DisableCardModal from "@/views/components/Modals/DisableCardModal.vue";

export default {
  name: "ActiveCards",
  computed: {
    COLORS() {
      return COLORS
    }
  },
  components: {
    DisableCardModal,
    Badge,
    MomentFormat,
    CardHistoryModal, CardFullInfoModal, AddCardModal, Datatable, ChangeCurrencyLayout, MainLayout
  },
  props: ['currency'],
  data() {
    return {
      id: null,
      checkedItems: [],
      cardHistoryInfo: null,
      cardHistoryModal: false,
      cardFullInfo: null,
      cardFullInfoModal: false,
      addCardModal: false,
      defaultOrder: ['autoID', 'desc'],
      defaultQuery: {status: 1, currency: this.currency},
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [],

      disableCardModal: false,
      disableCardIds: [],
      orderSum: 0,
    }
  },
  watch: {
    currency(newVal) {
      this.defaultQuery = {...this.defaultQuery, currency: newVal}
      this.updateColumns()
      this.loadOrderSum()
    }
  },
  created() {
    this.$nextTick().then(() => {
      this.updateColumns()
      this.loadOrderSum()
    })
  },
  methods: {
    loadOrderSum() {
      if (this.currency !== 'UAH') return;

      http.post('/api/cards/order_limit', { ...this.defaultQuery }).then(({data}) => {
        if (!data.success) return;
        this.orderSum = data.sum
      })
    },
    updatePage() {
      this.$refs.datatable.loadItems()
      this.loadOrderSum()
      this.checkedItems = []
    },
    updateColumns() {
      const columns = [];

      columns.push({field: 'autoID', title: '#', sorting: true, filter: {type: 'input'}});
      columns.push({field: 'lastdigits', title: 'Номер карты', filter: {type: 'input', operator: 'like', end: '%'}});
      if (this.currency === 'RUB') {
        columns.push({field: 'sbp_enable', title: 'СБП', sorting: true});
      } else if (this.currency === 'KZT') {
        columns.push({field: 'customerID', title: 'Физ.лицо', filter: {type: 'input', operator: 'like', end: '%'}});
      }
      columns.push({field: 'comment', title: 'Комментарий', filter: {type: 'input', operator: 'between'}});
      columns.push({field: 'uprate', title: 'Конверсия'});
      columns.push({field: 'limit', title: 'Лимиты'});
      if (this.currency === 'UAH') {
        columns.push({field: 'order_limit', title: 'Лимиты заявок'});
      }
      if (this.currency !== 'UAH') {
        columns.push({field: '$partner.name$', title: 'Источник', filter: {type: 'input', operator: 'like'}});
        columns.push({field: 'port', title: 'Порт', filter: {type: 'input', operator: 'like', end: '%'}});
      }
      columns.push({field: 'balance', title: 'Баланс', sorting: true});
      if (this.currency === 'UAH') {
        columns.push({
          field: 'lastEnable',
          title: 'Последнее включение',
          sorting: true
        });
      }
      columns.push({field: 'actions', title: 'Действия', width: '110px'});

      this.columns = columns
    },
    disableCard(ids) {
      this.disableCardIds = ids
      this.disableCardModal = true
      // Swal.fire({
      //   title: CARD_LANG.DISABLE,
      //   text: '',
      //   showCancelButton: true,
      //   confirmButtonText: CARD_LANG.YES_DISABLE,
      //   cancelButtonText: COMMON_LANG.CANCEL,
      //   didOpen: this.getAllCards(ids, this.items),
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     http.post('/api/cards/disable', {
      //       id: ids
      //     }).then(() => {
      //       this.$refs.datatable.loadItems()
      //     })
      //   }
      // })
    },
    fullInfo(item) {
      this.cardFullInfo = item
      this.cardFullInfoModal = true
    },
    editCard(id) {
      this.id = id
      this.addCardModal = true
    },
    cardHistory(item) {
      this.cardHistoryModal = true
      this.cardHistoryInfo = null

      http.post('/api/cards/history', {
        id: item.autoID
      }).then(({data}) => {
        this.cardHistoryInfo = {item: data.item, history: data.history}
      })
    },
    removeCard(ids) {
      Swal.fire({
        title: CARD_LANG.REMOVE,
        text: '',
        didOpen: this.getAllCards(ids, this.items),
        showCancelButton: true,
        confirmButtonText: COMMON_LANG.YES_DELETE,
        cancelButtonText: COMMON_LANG.CANCEL,
      }).then((result) => {
        if (result.isConfirmed) {
          http.post('/api/cards/remove', {
            id: ids
          }).then(() => {
            this.$refs.datatable.loadItems()
          })
        }
      })
    },
    getAllCards(id, cards) {
      return function () {
        let cardList = []

        for (let card of cards) {
          if (id.includes(card.autoID))
            cardList.push("<b>" + card.lastdigits + "</b>")
        }

        Swal.getHtmlContainer().innerHTML = cardList.join(', ')
        Swal.getHtmlContainer().style.display = 'block'
      }
    }
  }
}
</script>

<style scoped>
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1700px;
  }
}
</style>