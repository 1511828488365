<template>
  <main-layout :code="20">
    <settings-layout>
      <div class="card">
        <div class="card-header header-elements">
          <h5 class="m-0 me-2">Настройка курсов</h5>
          <div class="card-header-elements ms-auto">
            <a href="#add-rate" @click.prevent="editItem(null)" class="btn btn-sm btn-secondary"><span class="tf-icon bx bx-plus"></span> Добавить
              курс</a>
          </div>
        </div>
        <div class="card-datatable text-nowrap">
          <datatable ajax="/api/rate/list" ref="datatable" :default-order="['autoID', 'desc']" :items="items" :columns="columns">
            <template #tr="item">
              <td><span class="fw-semibold">{{ item.autoID }}</span></td>
              <td>{{ item.name }}</td>
              <td>{{ item.currency }}</td>
              <td>{{ item.crypto }}</td>
              <td>{{ item.seconds }}</td>
              <td>{{ item.value }}</td>
              <td>
                <moment-format :input="item.lastupdate" :output="DEFAULT_TIME_FORMAT"/>
                <small class="text-muted">{{ moment(item.lastupdate).fromNow() }}</small>
              </td>
              <td>
                <a href="#more" class="btn btn-sm btn-icon"
                   @click.prevent="loadFullRate(item.autoID)" v-tooltip="{title: 'Подробнее'}"><i
                    class="bx bx-fullscreen"></i></a>
                <a href="#check" class="btn btn-sm btn-icon" @click.prevent="checkProxy(item.autoID)"
                   v-tooltip="{title: 'Проверить прокси'}"><i
                    class="bx bx-check"></i></a>
                <a href="#edit" @click.prevent="editItem(item.autoID)" class="btn btn-sm btn-icon" v-tooltip="{title: 'Редактировать'}"><i
                    class="bx bx-edit"></i></a>
                <a href="#edit2" @click.prevent="editSum(item.autoID)" class="btn btn-sm btn-icon" v-tooltip="{title: 'Изменить сумму'}"><i
                    class="bx bx-edit"></i></a>
                <a href="#remove" @click.prevent="removeItem(item.autoID)" class="btn btn-sm btn-icon" v-tooltip="{title: 'Удалить'}"><i
                    class="bx bx-trash"></i></a>
              </td>
            </template>
          </datatable>
          <full-rate-info-modal v-model="fullRateInfoModal" :data="fullRateInfo"/>
        </div>
      </div>
    </settings-layout>
  </main-layout>
  <add-rate-modal v-model="addRateModal" :id="id" @update:page="updatePage"/>
</template>

<script>
import {DEFAULT_TIME_FORMAT} from "@/constants";
import Datatable from "@/views/components/Datatable";
import FullRateInfoModal from "@/views/components/Modals/FullRateInfoModal";
import MainLayout from "@/views/layouts/MainLayout";
import SettingsLayout from "@/views/layouts/SettingsLayout";
import toastr from "toastr";
import http from "@/http";
import moment from "moment-timezone";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import AddRateModal from "@/views/components/Modals/AddRateModal";
import Swal from "sweetalert2";

export default {
  name: "RateSettings",
  components: {AddRateModal, MomentFormat, SettingsLayout, MainLayout, FullRateInfoModal, Datatable},
  data() {
    return {
      id: null,
      DEFAULT_TIME_FORMAT,
      addRateModal: false,
      fullRateInfoModal: false,
      fullRateInfo: null,
      items: [],
      moment,
      columns: [
        {field: 'autoID', title: '#', sorting: true, width: '70px'},
        {field: 'name', title: 'Название'},
        {field: 'currency', title: 'Валюта', width: '61px'},
        {field: 'crypto', title: 'Криптовалюта', width: '115px'},
        {field: 'seconds', title: 'Частота обновлений (сек.)'},
        {field: 'value', title: 'Сумма', width: '90px'},
        {field: 'lastupdate', title: 'Последнее обновление', sorting: true, width: '200px'},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    loadFullRate(autoID) {
      this.fullRateInfo = null
      this.fullRateInfoModal = true

      http.post('/api/rate/get', {
        id: autoID
      }).then((response) => {

        let {
          success,
          item
        } = response.data

        if (success)
          this.fullRateInfo = item
        else
          this.fullRateInfoModal = false

      }).catch(error => {
        this.fullRateInfoModal = false
      })
    },
    checkProxy(autoID) {
      toastr.info('Идет проверка прокси...')

      http.post('/api/rate/check_proxy', {
        id: autoID
      })
    },
    removeItem(id) {
        Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите удалить курс #${id}`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (!result.isConfirmed) return;
            http.post('/api/rate/remove', {
                id
            }).then(response => {
                this.updatePage()
            })
        })

    },
    editItem(id) {
      this.id = id
      this.addRateModal = true
    },
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    editSum(id) {
        Swal.fire({
            title: "Введите новую сумму",
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/rate/saveValue', {
                    id,
                    value: Number(result.value) || 0
                }).then(({data}) => {
                    let {
                    success
                    } = data

                    if (success) {
                        this.updatePage()
                    }
                })
            }
        });
    },
  }
}
</script>

<style scoped>

</style>
