export default {
    data() {
        return {
            test: 'Хуй'
        }
    },
    methods: {
        moneyFormat(value: any) {
            if (!value) return value;
            value = Number (value);
            // Форматирование денег 1.000.000.000
            value = value.toString().replace(/\D/,'');
            return value.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
        }    
        
    }
}
