<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form action="/api/parser/save" method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 class="modal-title">Регулярное выражение</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="regexText" class="form-label">Регулярное выражение</label>
                <input type="text" id="regexText" v-model="item.regexText" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="currency" class="form-label">Валюта</label>
                <select2 id="currency" v-model.number="item.currency" :items="currencies"/>
              </div>
              <div class="col-12 mb-3">
                <label for="typeSMS" class="form-label">Тип СМС</label>
                <bootstrap-select id="typeSMS" :items="typeSMS" v-model="item.typeSMS"
                                  placeholder="Выберите тип сообщения"></bootstrap-select>
              </div>
              <div class="col-12 mb-3">
                <label for="sender" class="form-label">Отправитель</label>
                <input type="text" id="sender" v-model="item.sender" class="form-control"
                       placeholder="Сбербанк" data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="1" v-model="item.usePort" id="usePort">
                  <label class="form-check-label" for="usePort">Использует порт</label>
                </div>
              </div>
              <div class="col-12 mb-3">
                <label for="card" class="form-label">ID Карты</label>
                <input type="text" id="card" v-model="item.jsonData.card" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="balance" class="form-label">ID Баланса</label>
                <input type="text" id="balance" v-model="item.jsonData.balance" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="amount" class="form-label">ID Суммы</label>
                <input type="text" id="amount" v-model="item.jsonData.amount" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";

export default {
  name: "AddParseModal",
  props: ['id', 'modelValue'],
  components: {Select2, BootstrapSelect, LoadingButton, Modal},
  data() {
    return {
        urls: {
            get: '/api/parser/get',
            add: '/api/parser/add',
            save: '/api/parser/save'
        },
        loading: false,
        typeSMS: [
            {title: 'Оповещение о пополнении', value: 0},
            {title: 'Оповещение о списании', value: 1}
        ],
        item: {},
        defaultItem: {
            typeSMS: null,
            regexText: null,
            currency: null,
            sender: null,
            usePort: false,
            jsonData: {
                card: null,
                balance: null,
                amount: null,
            }
        },
        currencies: [
            {title: 'RUB', value: 'RUB'},
            {title: 'UAH', value: 'UAH'},
            {title: 'KZT', value: 'KZT'}
        ],
    }
  },
  created() {
    this.item = {...this.defaultItem}
  },
  computed: {
    saveUrl() {
      return this.id ? this.urls.save : this.urls.add
    }
  },
  watch: {
    id(newVal) {
      if (newVal) {
        this.loading = true
        http.post(this.urls.get, {id: this.id}).then(({data}) => {
          let {
            success,
            item
          } = data

          if (success) {
            for (let field of Object.keys(this.item)) {
              this.item[field] = item[field]
            }
          }

        }).finally(() => {
          this.loading = false
        })
      } else {
        this.item = {...this.defaultItem}
      }
    }
  },
  methods: {
    saveItem() {
      this.loading = true
      http.post(this.saveUrl, {
        id: this.id,
        item: this.item
      }).then(response => {

        let {
          success,
          item
        } = response.data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
