import UserList from "@/views/pages/users/UserList.vue";
import UserRoles from "@/views/pages/users/UserRoles.vue";
import UserLogs from "@/views/pages/logs/UserLogs.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/user/list',
        name: 'user-list',
        component: UserList,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/user/roles',
        name: 'user-roles',
        component: UserRoles,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/logs/user',
        name: 'user-logs',
        component: UserLogs,
        meta: {
            middleware: [isUser]
        }
    },
]
