<template>
  <main-layout :code="6">
    <change-currency-layout route="highlighted-cards">
      <div class="card">
        <div class="card-header header-elements">
          <h5 class="m-0 me-2">Выделенные карты ({{ currency }})</h5>
          <div class="card-header-elements ms-auto">
            <!--            <a href="#add-card" @click.prevent="addCardModal = true" class="btn btn-sm btn-secondary"><span class="tf-icon bx bx-plus"></span> Добавить-->
            <!--              карту</a>-->
            <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                class="tf-icon bx bx-refresh"></span> Обновить данные</a>
          </div>
        </div>
        <div class="card-datatable text-nowrap">
          <div class="btn-group p-3" role="group">
            <button type="button" class="btn btn-danger" @click.prevent="removeCard(checkedItems)"
                    :disabled="!checkedItems.length">Удалить
            </button>
            <button type="button" class="btn btn-primary" @click.prevent="editCard(checkedItems)"
                    :disabled="!checkedItems.length">Изменить
            </button>
            <button type="button" class="btn btn-success" @click.prevent="disconnectCard(checkedItems)"
                    :disabled="!checkedItems.length">Отвязть
            </button>
          </div>
          <datatable ajax="/api/cards/list" ref="datatable" :default-query="defaultQuery" :default-order="defaultOrder"
                     :items="items"
                     :columns="columns" :checkbox="true" checkbox-key="autoID" v-model:checked-items="checkedItems">
            <template #tr="item">
              <td><span class="fw-semibold">{{ item.autoID }}</span></td>
              <td>
                **** {{ item.lastdigits }}
                <template v-if="item.phone && item.phone.length">
                    <small class="text-muted">{{ item.phone }}</small>
                </template>
              </td>
              <td v-if="item.customerID"><a href="#">Перейти / {{ item.customerID }}</a></td>
              <td v-else><i>нет</i></td>
              <td v-if="item.comment">{{ item.comment }}</td>
              <td v-else><i>нет</i></td>
              <td>
                <span>
                  <span>
                  <span v-if="item.enabledUprate"><badge
                      :color="item.enabledUprate >= 50 ? COLORS.Success : COLORS.Danger"/> {{
                      item.enabledUprate
                    }}</span>
                  <i v-else>нет</i>
                </span> /
                <span>
                  <span v-if="item.uprate"><badge
                      :color="item.uprate >= 50 ? COLORS.Success : COLORS.Danger"/> {{ item.uprate }}</span>
                  <i v-else>нет</i>
                </span>
                </span>
              </td>
              <td>
                <span>{{ item.todayWithdrawCount || '-' }} из {{ item.todayWithdrawLimit || '-' }}</span><br>
                <small>({{ item.monthlyWithdrawCount || '-' }} из {{ item.monthlyWithdrawLimit || '-' }})</small>
              </td>
              <td v-if="item?.zs_partners">{{ item?.zs_partners?.partnername }}</td>
              <td v-else><i>нет</i></td>
              <td v-if="item.port">{{ item.port }}</td>
              <td v-else><i>нет</i></td>
              <td>{{ item.balance }} {{ item.currency }}</td>
              <td>
                <a href="#disconnectCard" @click.prevent="disconnectCard([item.autoID])"
                   class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Отвязать карту'}"><i
                    class="bx bx-power-off"></i></a>
                <a href="#fullInfo" @click.prevent="fullInfo(item)" class="btn btn-sm btn-icon item-edit"
                   v-tooltip="{title: 'Информация о карте'}"><i
                    class="bx bx-fullscreen"></i></a>
                <a href="#editCard" @click.prevent="editCard(item.autoID)" class="btn btn-sm btn-icon item-edit"
                   v-tooltip="{title: 'Изменить карту'}"><i
                    class="bx bx-edit"></i></a>
                <a href="#cardHistory" @click.prevent="cardHistory(item)" class="btn btn-sm btn-icon item-edit"
                   v-tooltip="{title: 'История изменений'}"><i
                    class="bx bx-history"></i></a>
                <a href="#removeCard" @click.prevent="removeCard([item.autoID])" class="btn btn-sm btn-icon item-edit"
                   v-tooltip="{title: 'Удалить карту'}"><i
                    class="bx bx-trash"></i></a>
              </td>
            </template>
          </datatable>
          <add-card-modal v-model="addCardModal" :id="id" :currency="currency" @update:page="updatePage"/>
          <card-full-info-modal v-model="cardFullInfoModal" :data="cardFullInfo"/>
          <card-history-modal v-model="cardHistoryModal" :data="cardHistoryInfo"/>
        </div>
      </div>
    </change-currency-layout>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import ChangeCurrencyLayout from "@/views/layouts/ChangeCurrencyLayout";
import {CARD_LANG, COLORS, COMMON_LANG, DEFAULT_TIME_FORMAT} from "@/constants";
import Datatable from "@/views/components/Datatable";
import AddCardModal from "@/views/components/Modals/AddCardModal";
import http from "@/http";
import CardFullInfoModal from "@/views/components/Modals/CardFullInfoModal";
import CardHistoryModal from "@/views/components/Modals/CardHistoryModal";
import Swal from "sweetalert2";

export default {
  name: "DisabledCards",
  computed: {
    COLORS() {
      return COLORS
    }
  },
  components: {CardHistoryModal, CardFullInfoModal, AddCardModal, Datatable, ChangeCurrencyLayout, MainLayout},
  props: ['currency'],
  data() {
    return {
      id: null,
      checkedItems: [],
      cardHistoryInfo: null,
      cardHistoryModal: false,
      cardFullInfo: null,
      cardFullInfoModal: false,
      addCardModal: false,
      defaultOrder: ['autoID', 'desc'],
      defaultQuery: {status: 15, currency: this.currency, dedicatedPanelID: {operator: 'ne', value: null}},
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'autoID', title: '#', sorting: true, filter: {type: 'input'}},
        {field: 'lastdigits', title: 'Номер карты', filter: {type: 'input', operator: 'like', end: '%'}},
        {field: 'customerID', title: 'Физ.лицо', filter: {type: 'input', operator: 'like', end: '%'}},
        {field: 'comment', title: 'Комментарий', filter: {type: 'input', operator: 'between'}},
        {field: 'uprate', title: 'Конверсия'},
        {field: 'limit', title: 'Лимиты'},
        {field: '$zs_partners.partnername$', title: 'Партнер'},
        {field: 'port', title: 'Порт', filter: {type: 'input', operator: 'like', end: '%'}},
        {field: 'balance', title: 'Баланс', sorting: true},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  watch: {
    currency(newVal) {
      this.defaultQuery = {...this.defaultQuery, currency: newVal}
      //this.updateColumns()
    }
  },
  created() {
    //this.$nextTick().then(() => {
    //  this.updateColumns()
    //})
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
      this.checkedItems = []
    },
    disconnectCard(ids) {
        Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите отвязать карты '${ids.join(', ')}'?`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (!result.isConfirmed) return;
            http.post('/api/cards/disconnect', {
                id: ids,
                hasPartner: true
            }).then(() => {
                this.updatePage()
            })
        })
    },
    fullInfo(item) {
      this.cardFullInfo = item
      this.cardFullInfoModal = true
    },
    editCard(id) {
      this.id = id
      this.addCardModal = true
    },
    cardHistory(item) {
      this.cardHistoryModal = true
      this.cardHistoryInfo = null

      http.post('/api/cards/history', {
        id: item.autoID
      }).then(({data}) => {
        this.cardHistoryInfo = {item: data.item, history: data.history}
      })
    },
    removeCard(ids) {
      Swal.fire({
        text: CARD_LANG.REMOVE,
        showCancelButton: true,
        confirmButtonText: COMMON_LANG.YES_DELETE,
        cancelButtonText: COMMON_LANG.CANCEL
      }).then((result) => {
        if (result.isConfirmed) {
          http.post('/api/cards/remove', {
            id: ids
          }).then(() => {
            this.$refs.datatable.loadItems()
          })
        }
      })
    },
    getAllCards(id, cards) {
      return function () {
        let cardList = []

        for (let card of cards) {
          if (id.includes(card.autoID))
            cardList.push("<b>" + card.lastdigits + "</b>")
        }

        Swal.getHtmlContainer().innerHTML = cardList.join(', ')
        Swal.getHtmlContainer().style.display = 'block'
      }
    }
  }
}
</script>

<style scoped>

</style>
