<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form method="post" @submit.prevent="addPayment">
          <div class="modal-header">
            <h5 class="modal-title">Добавить платеж</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="partnerID" class="form-label">Партнера</label>
                <select2 id="partnerID" v-model.number="item.partnerID" :items="zsPartners" :options="{titleField: 'partnername', valueField: 'partnerid'}" />
              </div>
              <div class="col-12 mb-3">
                <label for="cardID" class="form-label">Карта</label>
                <select2 id="cardID" v-model.number="item.cardID" :items="cards.filter(c => c.dedicatedPanelID === item.partnerID)" :options="{titleField: 'numbercard', valueField: 'autoID'}" />
              </div>
              <div class="col-12 mb-3">
                <label for="amount" class="form-label">Сумма</label>
                <input type="text" id="amount" v-model.number="item.amount" class="form-control"
                       placeholder="123" data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="smstext" class="form-label">Текст СМС</label>
                <input type="text" id="smstext" v-model.number="item.smstext" class="form-control"
                       placeholder="123" data-np-checked="1">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";

export default {
  name: "AddPartnerPaymentModal",
  props: ['id', 'modelValue'],
  components: {Select2, BootstrapSelect, LoadingButton, Modal},
  data() {
    return {
      loading: false,
      item: {},
      defaultItem: {
        amount: null,
        card_id: null,
      },
      cards: [],
      zsPartners: [],
    }
  },
  created() {
    this.item = {...this.defaultItem}
  },
  watch: {
    modelValue(newVal) {
      if (newVal) {
        this.item = {...this.defaultItem}
        this.loadCards()
      }
    }
  },
  methods: {
    loadCards() {
      http.post('/api/panel/getZsPartnerAll').then(({data}) => {
        let {
          success,
          zsPartners,
          cards
        } = data

        if (success){
            this.zsPartners = zsPartners;
            this.cards = cards;
        }

      })
    },
    addPayment() {
      this.loading = true
      http.post('/api/panel/add_partnerPayment', {
        id: this.id,
        item: this.item
      }).then(({data}) => {

        let {
          success,
          item
        } = data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>
