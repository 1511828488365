import Cleave from 'cleave.js';

export default {
    mounted: (el: any, binding: any) => {
        el.cleave = new Cleave(el, binding.value || {})
    },
    unmounted: (el: any) => {
        el.cleave.destroy()
    }
}
