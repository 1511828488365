<template>
  <main-layout :code="8">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Хранилище Tron (TRX / USDT)</h5>
              <div class="card-header-elements ms-auto">
                <a href="#get-address" @click.prevent="getNewAddressModal = true" class="btn btn-sm btn-secondary"><span
                    class="tf-icon bx bx-plus"></span> Получить новый адрес</a>
                <a href="#rescan" @click.prevent="rescanBlockchain" class="btn btn-sm btn-warning"><span
                    class="tf-icon bx bx-refresh"></span> Рескан блокчейна</a>
                <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                    class="tf-icon bx bx-refresh"></span> Обновить данные</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/store/list/usdt" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.autoID }}</span></td>
                  <td>{{ item.addressWallet }}</td>
                  <td>{{ item.balance ?? 0 }} TRX</td>
                  <td>{{ item.balanceTokens ?? 0 }} USDT</td>
                  <td v-if="item?.label?.labelName">{{ item?.label?.labelName }}</td>
                  <td v-else><i>нет</i></td>
                  <td>
                    <a href="#send_usdt" @click.prevent="sendCrypto(item.autoID, 'usdt')" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Отправить USDT'}"><i
                        class="bx bx-send"></i></a>
                    <a href="#send_trx" @click.prevent="sendCrypto(item.autoID, 'trx')" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Отправить TRX'}"><i
                        class="bx bx-send"></i></a>
                    <a href="#edit" @click.prevent="editAddress(item.autoID)" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Редактировать'}"><i
                        class="bx bx-edit"></i></a>
                    <a href="#remove" @click.prevent="removeAddress(item.autoID)" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Удалить'}"
                    ><i
                        class="bx bx-trash"></i></a>
                  </td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->
    </div>

    <edit-address-modal v-model="editAddressModal" :wallet-id="walletId" crypto="USDT" @updage:page="updatePage"/>
    <get-new-address-modal v-model="getNewAddressModal" crypto="USDT" @updage:page="updatePage"/>
    <send-crypto-modal v-model="sendCryptoModal" :id="id" :crypto="crypto"/>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {COMMON_LANG, DEFAULT_TIME_FORMAT, STORE_LANG} from "@/constants";
import {rescanBlockchain} from "@/functions"
import SendCryptoModal from "@/views/components/Modals/SendCryptoModal";
import GetNewAddressModal from "@/views/components/Modals/GetNewAddressModal";
import EditAddressModal from "@/views/components/Modals/EditAddressModal";
import Swal from "sweetalert2";
import http from "@/http";

export default {
  name: "PartnerList",
  components: {EditAddressModal, GetNewAddressModal, SendCryptoModal, Datatable, AddRoleModal, MainLayout},
  data() {
    return {
      defaultOrder: ['autoID', 'desc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      crypto: null,
      id: null,
      sendCryptoModal: false,
      getNewAddressModal: false,
      editAddressModal: false,
      walletId: null,
      columns: [
        {field: 'id', title: '#', sorting: true},
        {field: 'address', title: 'Адрес'},
        {field: 'balance', title: 'Баланс (TRX)'},
        {field: 'balanceTokens', title: 'Баланс (USDT)'},
        {field: 'label', title: 'Лейбл'},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    sendCrypto(id, crypto) {
      this.crypto = crypto
      this.id = id
      this.sendCryptoModal = true
    },
    rescanBlockchain() {
      rescanBlockchain('USDT').then(({data}) => {
        let {
          success
        } = data

        if (success)
          this.updatePage()
      })
    },
    editAddress(id) {
      this.walletId = id
      this.editAddressModal = true
    },
    removeAddress(id) {
      Swal.fire({
        title: STORE_LANG.REMOVE_ADDRESS,
        text: '',
        showCancelButton: true,
        confirmButtonText: COMMON_LANG.YES_DELETE,
        cancelButtonText: COMMON_LANG.CANCEL,
      }).then((result) => {
        if (result.isConfirmed) {
          http.post('/api/store/remove_address', {
            wallet_id: id
          }).then(({data}) => {

            let {
              success
            } = data

            if (success) {
              this.$refs.datatable.loadItems()
            }

          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
