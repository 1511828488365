<template>
  <div class="container-xxl container-p-y">
    <div class="misc-wrapper" style="text-align: center">
      <h1 class="mb-2 mx-2">Не авторизирован!</h1>
      <p class="mb-4 mx-2">У Вас недостаточно прав для этой странички :(</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotEnoughAccess"
}
</script>

<style scoped>

</style>
