<template>
  <div class="col-xl-8 col-12 mb-4" style="height: fit-content;">
    <div class="card h-100">
      <div class="row row-bordered m-0">
        <!-- Order Summary -->
        <div class="col-md-8 col-12 px-0">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Проблемные ({{ currency.code }})</h5>
            <a href="#change-date" @click.prevent v-flatpickr="{mode: 'range', onClose: selectDates, defaultDate: dateRange}"
               class="text-muted small">{{ dateText }}</a>
          </div>
          <div class="card-body p-0">
            <apexchart height="214" type="area" :options="options" :series="series" :key="series"></apexchart>
          </div>
        </div>
        <!-- Sales History -->
        <div class="col-md-4 col-12 px-0">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Обзор</h5>
          </div>
          <div class="card-body">
            <ul class="list-unstyled m-0 pt-0">
              <li class="mb-3">
                <div class="d-flex align-items-center">
                  <div class="avatar avatar-sm flex-shrink-0 me-2">
                    <span class="avatar-initial rounded bg-label-secondary"><i class="bx bx-trending-up"></i></span>
                  </div>
                  <div>
                    <p class="mb-0 lh-1 text-muted text-nowrap">Проблем сегодня</p>
                    <small class="fw-semibold text-nowrap">{{ currency.symbol }} {{ moneyFormat(todayProfit) }}</small>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="d-flex align-items-center">
                  <div class="avatar avatar-sm flex-shrink-0 me-2">
                    <span class="avatar-initial rounded bg-label-dark"><i class="bx bx-trending-up"></i></span>
                  </div>
                  <div>
                    <p class="mb-0 lh-1 text-muted text-nowrap">Проблем вчера</p>
                    <small class="fw-semibold text-nowrap">{{ currency.symbol }} {{ moneyFormat(yesterdayProfit) }}</small>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="d-flex align-items-center">
                  <div class="avatar avatar-sm flex-shrink-0 me-2">
                    <span class="avatar-initial rounded bg-label-dark"><i class="bx bx-trending-up"></i></span>
                  </div>
                  <div>
                    <p class="mb-0 lh-1 text-muted text-nowrap">Проблем на этой недели</p>
                    <small class="fw-semibold text-nowrap">{{ currency.symbol }} {{ moneyFormat(weekProfit) }}</small>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="d-flex align-items-center">
                  <div class="avatar avatar-sm flex-shrink-0 me-2">
                    <span class="avatar-initial rounded bg-label-secondary"><i class="bx bx-trending-up"></i></span>
                  </div>
                  <div>
                    <p class="mb-0 lh-1 text-muted text-nowrap">Проблем в этом месяце</p>
                    <small class="fw-semibold text-nowrap">{{ currency.symbol }} {{ moneyFormat(monthProfit) }}</small>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import moment from "moment-timezone";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";

export default {
  name: "ProblemSummary",
  props: ['currency'],
  data() {
    return {
      todayProfit: 0,
      yesterdayProfit: 0,
      weekProfit: 0,
      monthProfit: 0,
      series: [],
      options: {
        chart: {
          toolbar: false,
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 3,
            color: config.colors.primary,
            opacity: .15
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (o) => {
            return this.currency.symbol + o
          },
        },
        stroke: {
          curve: "smooth",
          lineCap: "round"
        },
        colors: [config.colors.primary, config.colors.warning],
        fill: {
          type: "gradient",
          gradient: {
            shade: 'light',
            shadeIntensity: .8,
            opacityFrom: .7,
            opacityTo: .25,
            stops: [0, 95, 100]
          }
        },
        grid: {
          show: true,
          borderColor: config.colors.borderColor,
          padding: {
            top: -15,
            bottom: -10,
            left: 15,
            right: 10
          }
        },
        xaxis: {
          categories: [],
          labels: {
            offsetX: 0,
            style: {
              colors: config.colors.axisColor,
              fontSize: "13px"
            },
            formatter: (o) => {
              return moment(o).format('Do')
            },
          },

          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          lines: {
            show: false
          }
        },
        yaxis: {
          labels: {
            offsetX: -3,
            formatter: (o) => {
              return Math.floor(o)
            },
            style: {
              fontSize: "13px",
              colors: config.colors.axisColor
            }
          },
          min: 0,
          tickAmount: 4
        }
      },
      dateRange: null,
      dateText: null,
      DEFAULT_TIME_FORMAT
    }
  },
  created() {
    let utcOffset = moment().utcOffset()

    this.setDates(
        moment().subtract(7, 'days').startOf('day').add(utcOffset, 'minutes'),
        moment().endOf('day').add(1, 'second').add(utcOffset, 'minutes').subtract(1, 'day')
    )
  },
  methods: {
    selectDates(selectedDates) {

      if (selectedDates.length !== 2)
        return

      let timeZoneOffset = (new Date).getTimezoneOffset() * 60


      let dateTimeStart = moment.unix(Math.floor(+selectedDates[0] / 1000) - timeZoneOffset)
      let dateTimeEnd = moment.unix(Math.floor(+selectedDates[1]/ 1000) - timeZoneOffset)

      this.setDates(
          dateTimeStart,
          dateTimeEnd
      )
    },
    setDates(startDate, endDate) {

      this.dateRange = [
        startDate.format(this.DEFAULT_TIME_FORMAT),
        endDate.format(this.DEFAULT_TIME_FORMAT)
      ]

      this.dateText = startDate.tz('UTC').format('MMM Do YY') + ' - ' + endDate.tz('UTC').format('MMM Do YY')

      this.loadStatistic()
    },
    loadStatistic() {
      http.post('/api/home/pgraphic', {
        currency_id: this.currency.id,
        date: [
          moment(this.dateRange[0]).toString(),
          moment(this.dateRange[1]).toString()
        ]
      }).then(({data}) => {
        let {
          success,
          categories,
          series,
          today_profit,
          yesterday_profit,
          week_profit,
          month_profit
        } = data

        if (success){
          this.options.xaxis.categories = categories
          this.series = series
          this.todayProfit = today_profit
          this.yesterdayProfit = yesterday_profit
          this.weekProfit = week_profit
          this.monthProfit = month_profit
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
