<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="data">
        <div class="modal-header">
          <h5 class="modal-title">Транзакция | #{{ data.item.transactionid }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul class="timeline">
            <li class="timeline-item timeline-item-transparent ps-4" v-for="(row, index) in data.transactioninfo"
                :key="index">
              <span class="timeline-point timeline-point-primary"></span>
              <div class="timeline-event pb-2">
                <div class="timeline-header mb-1">
                  <h6 class="mb-0">#{{ row.withdrawid }} | {{ row.comment }}</h6>
                </div>
                <p class="mb-2" v-for="withdrawdetail in row.withdrawdetails" :key="withdrawdetail.detailid">
                  <a href="#" v-tooltip="{title: withdrawdetail.address}">{{ minifyAddress(withdrawdetail.address) }}</a> | {{ withdrawdetail.amount }} {{ row.cryptoShortName }}</p>
              </div>
            </li>
            <li class="timeline-end-indicator">
              <i class="bx bx-check-circle"></i>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import moment from "moment-timezone";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import {minifyAddress, rescanBlockchain} from "@/functions"

export default {
  name: "TransactionInfoModal",
  props: ['modelValue', 'data'],
  components: {MomentFormat, LoadingBlock, Modal},
  data() {
    return {
      DEFAULT_TIME_FORMAT
    }
  },
  methods: {
    getStatus(status) {
      let result = null

      switch (status) {
        case 'sending':
          result = 'В очереди'
          break;
        case 'chunksend':
          result = 'Частично отправлена'
          break;
        case 'send':
          result = 'Отправлена'
          break;
        case 'canceled':
          result = 'Отменена'
          break;
      }

      return result
    },
    minifyAddress
  }
}
</script>

<style scoped>

</style>
