<template>
  <div class="col-xl-6 col-12 mb-4">
    <div class="card h-100">
      <div class="row row-bordered m-0">
        <div class="col-md-12 col-12 px-0">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="card-title mb-0">Статистика конверсии ({{ currency.code }})</h5>
              <a href="#change-date" @click.prevent v-flatpickr="{mode: 'range', defaultDate: dateRange}"
                 class="text-muted small">{{ dateText }}</a>
            </div>
            <div class="card-body p-0">
              <apexchart height="214" type="area" :options="options" :series="conversions" :key="conversions"></apexchart>
            </div>
        </div>

      </div>
    </div>
  </div>

  <div class="col-xl-6 col-12 mb-4" style="height: fit-content;">
    <div class="card h-100">
      <div class="row row-bordered m-0">
        <!-- Order Summary -->
        <div class="col-md-8 col-12 px-0">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Статистика ручных карт ({{ currency.code }})</h5>
            <a href="#change-date" @click.prevent v-flatpickr="{mode: 'range', defaultDate: dateRange}"
               class="text-muted small">{{ dateText }}</a>
          </div>
          <div class="card-body p-0" v-if="stats && stats.series">
            <apexchart height="214" type="area" :options="{
                ...options,
                dataLabels: {
                    enabled: true,
                    formatter: (o) => {
                      return this.currency.symbol + o
                    },
                },
            }" :series="stats.series" :key="stats.series"></apexchart>
          </div>
        </div>
        <!-- Sales History -->
        <div class="col-md-4 col-12 px-0">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Обзор</h5>
          </div>
          <div class="card-body">
            <ul class="list-unstyled m-0 pt-0">
              <li class="mb-3">
                <div class="d-flex align-items-center">
                  <div class="avatar avatar-sm flex-shrink-0 me-2">
                    <span class="avatar-initial rounded bg-label-secondary"><i class="bx bx-trending-up"></i></span>
                  </div>
                  <div>
                    <p class="mb-0 lh-1 text-muted text-nowrap">Доход сегодня</p>
                    <small class="fw-semibold text-nowrap">{{ currency.symbol }} {{ moneyFormat(stats.today_profit) }}</small>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="d-flex align-items-center">
                  <div class="avatar avatar-sm flex-shrink-0 me-2">
                    <span class="avatar-initial rounded bg-label-dark"><i class="bx bx-trending-up"></i></span>
                  </div>
                  <div>
                    <p class="mb-0 lh-1 text-muted text-nowrap">Доход вчера</p>
                    <small class="fw-semibold text-nowrap">{{ currency.symbol }} {{ moneyFormat(stats.yesterday_profit) }}</small>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="d-flex align-items-center">
                  <div class="avatar avatar-sm flex-shrink-0 me-2">
                    <span class="avatar-initial rounded bg-label-dark"><i class="bx bx-trending-up"></i></span>
                  </div>
                  <div>
                    <p class="mb-0 lh-1 text-muted text-nowrap">Доход на этой недели</p>
                    <small class="fw-semibold text-nowrap">{{ currency.symbol }} {{ moneyFormat(stats.week_profit) }}</small>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="d-flex align-items-center">
                  <div class="avatar avatar-sm flex-shrink-0 me-2">
                    <span class="avatar-initial rounded bg-label-secondary"><i class="bx bx-trending-up"></i></span>
                  </div>
                  <div>
                    <p class="mb-0 lh-1 text-muted text-nowrap">Доход в этом месяце</p>
                    <small class="fw-semibold text-nowrap">{{ currency.symbol }} {{ moneyFormat(stats.month_profit) }}</small>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--<div class="col-lg-4 col-12">
    <div class="row">
      <div class="col-6 col-md-3 col-lg-6 mb-4" style="height: fit-content;">
        <div class="card">
          <div class="card-body text-center">
            <div class="menu-icon tf-icons bx bx-id-card" style="font-size: 5rem;"/>
            <span class="d-block text-nowrap error_text_text">Карт в работе ({{ currency.code ?? '-' }})</span>
            <h2 class="mb-0 error_text_text">{{ cardCount }} шт.</h2>
          </div>
        </div>
      </div>
    </div>
  </div>-->
</template>

<script>
import config from "@/config";
import moment from "moment-timezone";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat.vue";
import AddRateWidgetModal from "@/views/components/Modals/AddRateWidgetModal.vue";
import { options } from "toastr";

export default {
  name: "TicketsStats",
  components: {AddRateWidgetModal, MomentFormat},
  props: ['currency'],
  data() {
    return {
      todayProfit: 0,
      yesterdayProfit: 0,
      weekProfit: 0,
      monthProfit: 0,
      series: [],
      conversions: [],
      stats: {},
      options: {
        chart: {
          toolbar: false,
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 3,
            color: config.colors.primary,
            opacity: .15
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (o) => {
            return o
          },
        },
        stroke: {
          curve: "smooth",
          lineCap: "round"
        },
        colors: [config.colors.primary, config.colors.success, config.colors.danger],
        fill: {
          type: "gradient",
          gradient: {
            shade: 'light',
            shadeIntensity: .8,
            opacityFrom: .7,
            opacityTo: .25,
            stops: [0, 95, 100]
          }
        },
        grid: {
          show: true,
          borderColor: config.colors.borderColor,
          padding: {
            top: -15,
            bottom: -10,
            left: 15,
            right: 10
          }
        },
        xaxis: {
          categories: [],
          labels: {
            offsetX: 0,
            style: {
              colors: config.colors.axisColor,
              fontSize: "13px"
            },
            formatter: (o) => {
              return moment(o).format('Do')
            },
          },

          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          lines: {
            show: false
          }
        },
        yaxis: {
          labels: {
            offsetX: -3,
            formatter: (o) => {
              return Math.floor(o)
            },
            style: {
              fontSize: "13px",
              colors: config.colors.axisColor
            }
          },
          min: 0,
          tickAmount: 4
        }
      },
      dateRange: null,
      dateRange2: null,
      dateText: null,
      DEFAULT_TIME_FORMAT,
      cardCount: 0
    }
  },
  created() {
    http.post('/api/home/graphictickets', {
        currency_id: this.currency.id,
      }).then(({data}) => {
        let {
          success,
          stats,
          categories,
          conversions,
          cardCount
        } = data

        if (success){
          //this.options.xaxis.categories = categories
          //this.series = series
          this.options.xaxis.categories = categories
          this.conversions = conversions
          this.stats = stats
          this.cardCount = cardCount
          console.log(this.currency.code, {
            success,
            stats,
            conversions,
            cardCount
          })
        }
      })
  },
}
</script>

<style scoped>

</style>
