import TokenList from "@/views/pages/api/TokenList.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/api/list',
        name: 'api-list',
        component: TokenList,
        meta: {
            middleware: [isUser]
        }
    },
]
