<template>
  <main-layout :code="12">
    <div class="container-xxl flex-grow-1 container-p-y">
      <div class="row">
        <div class="col-12 mb-md-0 mb-4">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">API Логи</h5>
              <div class="card-header-elements ms-auto">
                <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                    class="tf-icon bx bx-refresh"></span> Обновить данные</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/logs/api" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.id }}</span></td>
                  <td>{{ item.partner.name }}</td>
                  <td>{{ item.type }}</td>
                  <td>{{ item.method }}</td>
                  <td><moment-format :input="item.time" :output="DEFAULT_TIME_FORMAT"/></td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import PaymentLogsLayout from "@/views/layouts/PaymentLogsLayout";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";

export default {
  name: "ApiLogs",
  components: {MomentFormat, Datatable, PaymentLogsLayout, MainLayout},
  props: ['currency'],
  data() {
    return {
      defaultOrder: ['id', 'desc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'id', title: '#', width: '50px', sorting: true},
        {field: 'partner', title: 'Партнер'},
        // {field: 'token', title: 'Токен'},
        {field: 'type', title: 'Тип'},
        {field: 'method', title: 'Метод'},
        {field: 'time', title: 'Время', filter: {type: 'datetime-range'}},
        //{field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
  }
}
</script>

<style scoped>
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1700px;
  }
}
</style>
