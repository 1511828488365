<template>
  <main-layout :code="4">
    <div class="container-xxl flex-grow-1 container-p-y">
      <h4 class="py-3 breadcrumb-wrapper mb-2">Управление сотрудниками</h4>
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-xl-4 col-lg-6 col-md-6" v-for="role in roles" :key="role.id">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-2">
                <h6 class="fw-normal">Сотрудников: {{ role.users.length }}</h6>
                <ul class="list-unstyled d-flex align-items-center avatar-group mb-0">
                  <li class="avatar avatar-sm pull-up" v-for="user in role.users" :key="user.id">
                    <div class="avatar-wrapper">
                      <div class="avatar avatar-sm me-3"><span
                          class="avatar-initial rounded-circle bg-label-primary">{{ user.name.substring(0, 2) }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="d-flex justify-content-between align-items-end">
                <div class="role-heading">
                  <h4 class="mb-1">{{ role.title }}</h4>
                  <a href="#edit" @click.prevent="editRole(role.id)"
                     class="role-edit-modal"><small>Редактировать</small></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="card h-100">
            <div class="row h-100">
              <div class="col-sm-5">
                <div class="d-flex align-items-end h-100 justify-content-center mt-sm-0 mt-3">
                  <img src="@/assets/img/illustrations/lady-with-laptop-light.png" class="img-fluid" alt="Image"
                       width="100" data-app-light-img="illustrations/lady-with-laptop-light.png"
                       data-app-dark-img="illustrations/lady-with-laptop-dark.png">
                </div>
              </div>
              <div class="col-sm-7">
                <div class="card-body text-sm-end text-center ps-sm-0">
                  <button @click="editRole(null)"
                          class="btn btn-primary mb-3 text-nowrap add-new-role">Создать роль
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <!-- Role Table -->
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Сотрудники </h5>
              <div class="card-header-elements ms-auto">
                <a href="#add-user" @click.prevent="editItem(null)" class="btn btn-sm btn-secondary"><span
                    class="tf-icon bx bx-plus"></span> Добавить сотрудника</a>
              </div>
            </div>
            <div class="card-datatable table-responsive">
              <datatable ajax="/api/user/list" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.id }}</span></td>
                  <td class="sorting_1">
                    <div class="d-flex justify-content-left align-items-center">
                      <div class="avatar-wrapper">
                        <div class="avatar avatar-sm me-3"><span
                            class="avatar-initial rounded-circle bg-label-primary">{{
                            item.name.substring(0, 2)
                          }}</span></div>
                      </div>
                      <div class="d-flex flex-column me-3">
                        <a href="" class="text-body text-truncate"><span class="fw-semibold">{{ item.name }}</span></a>
                        <small class="text-muted">{{ item.login }}</small>
                      </div>
                      <div v-if="item.otp_enabled" class="permission-list">
                        <span class="badge bg-success m-1">2FA</span>
                      </div>
                    </div>
                  </td>
                  <td><span>{{ item.role.title }}</span></td>
                  <td>
                    <span v-if="item.online" class="badge bg-success m-1">Онлайн</span>
                    <span v-else class="badge bg-danger m-1">Оффлайн</span>
                  </td>
                  <td v-if="item.last_online">
                    <moment-format :input="item.last_online" :output="DEFAULT_TIME_FORMAT"/>
                  </td>
                  <td v-else><i>нет</i></td>
                  <td><span>{{ item.day_limit_ia_rub }} / {{ item.max_day_limit_ia_rub }}</span></td>
                  <td><span>{{ item.day_limit_ia_uah }} / {{ item.max_day_limit_ia_uah }}</span></td>
                  <td>
                    <span v-if="!item.hasBan" class="badge bg-success m-1">Нет</span>
                    <span v-else class="badge bg-danger m-1">Да</span>
                  </td>
                  <td>
                    <a href="#edit" @click.prevent="editItem(item.id)" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Редактировать'}"><i
                        class="bx bx-edit"></i></a>
                    <a href="#remove" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Удалить'}"
                       @click.prevent="removeItem(item)"><i
                        class="bx bx-trash"></i></a>
                  </td>
                </template>
              </datatable>
            </div>
          </div>
          <!--/ Role Table -->
        </div>
      </div>
      <!--/ Role cards -->


    </div>
    <add-user-modal v-model="addUserModal" :id="userId" :roles="roles" @update:page="updatePage"/>
    <add-role-modal v-model="addRoleModal" :id="roleId" @update:page="updatePage"/>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import Badge from "@/views/components/UI/Badge";
import {COLORS, DEFAULT_TIME_FORMAT} from "@/constants";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import http from "@/http";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import Datatable from "@/views/components/Datatable";
import AddUserModal from "@/views/components/Modals/AddUserModal";
import Swal from "sweetalert2";

export default {
  name: "UserList",
  components: {AddUserModal, Datatable, MomentFormat, AddRoleModal, Badge, MainLayout},
  data() {
    return {
      roleId: null,
      userId: null,
      COLORS,
      addRoleModal: false,
      addUserModal: false,
      roles: [],
      defaultOrder: ['id', 'asc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'id', title: '#', sorting: true},
        {field: 'name', title: 'Сотрудник'},
        {field: 'role_id', title: 'Роль'},
        {field: 'online', title: 'Статус'},
        {field: 'last_online', title: 'Последний онлайн'},
        {field: 'last_online', title: 'Лимит на выдачу заявок RUB (Суточный)'},
        {field: 'last_online', title: 'Лимит на выдачу заявок UAH (Суточный)'},
        {field: 'hasBan', title: 'Блокировка входа'},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  mounted() {
    this.loadAllRoles()
  },
  methods: {
    updatePage() {
      this.loadAllRoles()
      this.$refs.datatable.loadItems()
    },
    loadAllRoles() {
      http.post('/api/role/get_all').then(({data}) => {
        this.roles = data.items
      })
    },
    editRole(id) {
      this.roleId = id
      this.addRoleModal = true
    },
    editItem(id) {
      this.userId = id
      this.addUserModal = true
    },
    removeItem(item) {
        Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите удалить сотрудника ${item.name}(#${item.id})?`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/user/remove', {
                    id: item.id
                }).then(() => {
                    this.$refs.datatable.loadItems()
                })
            }
        })
    }
  }
}
</script>

<style scoped>

</style>
