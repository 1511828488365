<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-add-new-role">
      <div class="modal-content p-3 p-md-5" v-if="data && partners">
        <button type="button" class="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-body">
          <div class="text-center mb-4">
            <h3 class="role-title">{{ id ? 'Редактирование токена' : 'Новый токен' }}</h3>
            <p>{{ id ? '' : 'Генерация нового токена' }}</p>
          </div>
          <!-- Add role form -->
          <form class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework" method="post" @submit.prevent="saveItem">
            <div class="col-12 mb-4 fv-plugins-icon-container" v-if="!id">
              <label class="form-label" for="partner_id">Партнер</label>
              <select2 id="partner_id" v-model:items="partners" v-model="item.partner_id" :options="{titleField: 'name', valueField: 'id'}"/>
            </div>
            <div class="col-12 mb-4 fv-plugins-icon-container" v-if="id">
              <label class="form-label" for="token">Токен</label>
              <input type="text" id="token" name="token" v-model="item.token" class="form-control"
                     tabindex="-1" data-np-invisible="1" data-np-checked="1">
            </div>
            <div class="col-12">
              <h5>Права</h5>
              <!-- Permission table -->
              <div class="form-check me-3 me-lg-5" v-for="perm in data" :key="perm.id">
                <input class="form-check-input" type="checkbox" :id="`role_${perm.id}`" data-np-invisible="1"
                       data-np-checked="1" v-model="permissions" :true-value="[]" :value="perm.id">
                <label class="form-check-label" :for="`role_${perm.id}`">
                  {{ perm.title }}
                </label>
              </div>
              <!-- Permission table -->
            </div>
            <div class="col-12 text-center">
              <loading-button :loading="loading" type="submit" class="btn btn-primary me-sm-3 me-1">Сохранить</loading-button>
              <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Отмена
              </button>
            </div>
            <div></div>
            <input type="hidden" data-np-invisible="1" data-np-checked="1"></form>
          <!--/ Add role form -->
        </div>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import http from "@/http";
import Select2 from "@/views/components/UI/Select2";
import LoadingButton from "@/views/components/UI/LoadingButton";

export default {
  name: "AddApiModal",
  props: ['id', 'modelValue'],
  components: {LoadingButton, Select2, LoadingBlock, Modal},
  data() {
    return {
      urls: {
        get: '/api/token/get',
        add: '/api/token/add',
        save: '/api/token/save'
      },
      loading: false,
      data: null,
      partners: null,
      permissions: [],
      item: {},
      defaultItem: {
        token: null,
        partner_id: null,
      }
    }
  },
  created() {
    this.item = {...this.defaultItem}
  },
  watch: {
    id(newVal) {
      if (newVal) {
        http.post(this.urls.get, {id: this.id}).then(({data}) => {
          let {
            success,
            item
          } = data

          if (success) {
            for (let field of Object.keys(this.item)) {
              this.item[field] = item[field]
            }

            // Extra
            let permissions = []
            for (let permission of item.permissions) {
              permissions.push(permission.permission_id)
            }

            this.permissions = permissions
          }

        })
      } else {
        for (let field of Object.keys(this.item)) {
          this.item = {...this.defaultItem}
        }

        // Extra
        this.permissions = []
      }
    }
  },
  mounted() {
    http.post('/api/token/get_permissions').then(({data}) => {
      this.data = data.items
    })
    http.post('/api/partner/list').then(({data}) => {
      this.partners = data.items
    })
  },
  computed: {
    saveUrl() {
      return this.id ? this.urls.save : this.urls.add
    }
  },
  methods: {
    saveItem() {
      this.loading = true
      http.post(this.saveUrl, {
        id: this.id,
        item: this.item,
        permissions: this.permissions
      }).then(({data}) => {
        if (data.success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
