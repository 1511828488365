<template>
  <main-layout :code="14">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Заявки на вывод</h5>
              <div class="card-header-elements ms-auto">
                <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                    class="tf-icon bx bx-refresh"></span> Обновить данные</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/panel/withdraws" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.panelwithdrawid }}</span></td>
                  <td><a href="#" v-tooltip="{title: item.addressWallet}">{{ minifyAddress(item.addressWallet) }}</a></td>
                  <td>{{ item.cryptoAmount }}</td>
                  <td v-if="item?.partner">{{ item.partner.currency }}</td>
                  <td v-else><i>нет</i></td>
                  <td v-if="item?.partner">{{ item.partner.cryptocurrency }}</td>
                  <td v-else><i>нет</i></td>
                  <td v-if="item?.partner">{{ item.partner.panelname }}</td>
                  <td v-else><i>нет</i></td>
                  <td>
                    <moment-format :input="item.dateWithdraw" :output="DEFAULT_TIME_FORMAT"/>
                  </td>
                  <td v-if="status[item.statusWithdraw]" :style="{color: status[item.statusWithdraw].color}">{{ status[item.statusWithdraw].name }}</td>
                  <td v-else>Нет</td>
                  <td>
                    <a href="#approve" class="btn btn-sm btn-icon"
                       v-tooltip="{title: 'Подтвердить вывод'}"
                       @click.prevent="approveWithdraw(item.panelwithdrawid)" v-if="item.statusWithdraw === 'waiting'"><i
                        class="bx bx-check"></i></a>
                    <a href="#cancel" class="btn btn-sm btn-icon"
                       v-tooltip="{title: 'Отменить вывод'}"
                       @click.prevent="cancelWithdraw(item.panelwithdrawid)" v-if="item.statusWithdraw === 'waiting'"><i
                        class="bx bx-x"></i></a>
                    <a href="#info" class="btn btn-sm btn-icon"
                        v-tooltip="{title: 'Информация'}"
                        @click.prevent="infoWithdraw(item)"><i
                        class="bx bx-error-circle"></i></a>
                  </td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import AddApiModal from "@/views/components/Modals/AddApiModal";
import AddPartnerModal from "@/views/components/Modals/AddPartnerModal";
import AddPaymentModal from "@/views/components/Modals/AddPaymentModal";
import AddPanelModal from "@/views/components/Modals/AddPanelModal";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import {minifyAddress} from "@/functions";
import Swal from "sweetalert2";

export default {
  name: "PanelWithdraws",
  components: {
    MomentFormat,
    AddPanelModal, AddPaymentModal, AddPartnerModal, AddApiModal, Datatable, AddRoleModal, MainLayout
  },
  data() {
    return {
      defaultOrder: ['panelwithdrawid', 'desc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'panelwithdrawid', title: '#', sorting: true},
        {field: 'addressWallet', title: 'Адрес'},
        {field: 'cryptoAmount', title: 'Сумма'},
        {field: 'partner.cryptocurrency', title: 'Криптовалюта'},
        {field: 'partner.currency', title: 'Валюта'},
        {field: 'partner.panelname', title: 'Название панели'},
        {field: 'dateWithdraw', title: 'Время вывода'},
        {field: 'statusWithdraw', title: 'Статус'},
        {field: 'actions', title: 'Действия', width: '110px'},
      ],
      status: {
        send: {
            color: 'green',
            name: 'Отправлен'
        },
        canceled: {
            color: 'red',
            name: 'Отменен'
        },
        waiting: {
            color: 'orange',
            name: 'Ожидает'
        },
      }
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    approveWithdraw(id) {
        Swal.fire({
            title: "Введите TXID для данного вывода",
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/panel/approve', {
                    id,
                    txid: result.value
                }).then(({data}) => {
                    let {
                    success
                    } = data

                    if (success) {
                    this.updatePage()
                    }
                })
            }
        });

    },
    cancelWithdraw(id) {
      http.post('/api/panel/cancel', {
        id,
      }).then(({data}) => {
        let {
          success
        } = data

        if (success) {
          this.updatePage()
        }
      })
    },
    infoWithdraw(item) {
        let html = ''

        html = `Баланс до вывода: ${item.balanceBefore}<br/>`;
        html += `Баланс после вывода: ${item.balanceAfter}`;

        Swal.fire({
            title: 'Информация о выводе',
            html
        })
    },
    minifyAddress
  }
}
</script>

<style scoped>

</style>
