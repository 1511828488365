<template>
  <main-layout :code="12">
    <payment-logs-layout>
      <div class="card">
        <div class="card-header header-elements">
          <h5 class="m-0 me-2">Платежные логи ({{ currency }})</h5>
          <div class="card-header-elements ms-auto">
            <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                class="tf-icon bx bx-refresh"></span> Обновить данные</a>
          </div>
        </div>
        <div class="card-datatable text-nowrap">
          <datatable ajax="/api/logs/list" ref="datatable" :default-query="defaultQuery" :default-order="defaultOrder" :items="items"
                     :columns="columns">
            <template #tr="item">
              <td><span class="fw-semibold">{{ item.autoID }}</span></td>

              <td v-if="item?.changeID">
                <span v-if="item.changeID === -1">
                  <b>Карта не добавлена</b>
                </span>
                <span v-else>
                    {{ item?.change?.uuID }}
                </span>
              </td><td v-else><i>нет</i></td>

              <td v-if="item?.panelID">
                Панель {{ item?.panel?.panelname }}
              </td><td v-else><i>нет</i></td>
              <td>{{ item.port }}</td>
              <td v-if="item.customerName">{{ item.customerName }}</td><td v-else><i>нет</i></td>
              <td>{{ item.sender }}</td>
              <td>{{ item.text }}</td>
              <td><moment-format :input="item.addTime" :output="DEFAULT_TIME_FORMAT"/></td>
<!--              <td>-->
<!--                <a href="#giveOrder" v-if="!item.changeID" @click.prevent="giveOrder(item.missingPayID)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Выдать за заявку'}"><i-->
<!--                    class="bx bx-check"></i></a>-->
<!--              </td>-->
            </template>
          </datatable>
          <give-order-modal v-model="giveOrderModal" :missing-pay-id="giveOrderInfo"/>
        </div>
      </div>
    </payment-logs-layout>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import PaymentLogsLayout from "@/views/layouts/PaymentLogsLayout";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import GiveOrderModal from "@/views/components/Modals/GiveOrderModal";
export default {
  name: "PaymentLogs",
  components: {GiveOrderModal, MomentFormat, Datatable, PaymentLogsLayout, MainLayout},
  props: ['currency'],
  data() {
    return {
      giveOrderModal: false,
      giveOrderInfo: null,
      defaultOrder: ['autoID', 'desc'],
      defaultQuery: {inctype: 'payments', currency: this.currency},
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'autoID', title: '#', width: '50px', sorting: true, filter: {type: 'input'}},
        {field: 'changeID', title: 'Код заявки', filter: {type: 'input', operator: 'like'}},
        {field: 'panelID', title: 'Панель', filter: {type: 'input', operator: 'like'}},
        {field: 'port', title: 'Порт', filter: {type: 'input', operator: 'like'}},
        {field: 'customerName', title: 'Физ.лицо', filter: {type: 'input', operator: 'like'}},
        {field: 'sender', title: 'Отправитель', filter: {type: 'input', operator: 'like'}},
        {field: 'text', title: 'Текст', filter: {type: 'input', operator: 'fulltext'}},
        {field: 'addTime', title: 'Время', filter: {type: 'datetime-range'}},
      ]
    }
  },
  watch: {
    currency(newVal) {
      this.defaultQuery = {...this.defaultQuery, currency: newVal}
    }
  },
  methods: {
    giveOrder(missingPayId) {
      this.giveOrderInfo = missingPayId
      this.giveOrderModal = true
    },
    updatePage() {
      this.$refs.datatable.loadItems()
    },
  }
}
</script>

<style scoped>
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1700px;
  }
}
</style>
