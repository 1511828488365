<template>
  <nav class="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">
    <div class="container-xxl">
      <div class="navbar-brand app-brand demo d-none d-xl-flex py-0 me-4">
        <router-link :to="{name: 'home'}" class="app-brand-link gap-2">
          <span class="app-brand-text demo menu-text fw-bold">Панель управления</span>
        </router-link>

        <a href="javascript:void(0);" class="layout-menu-toggle menu-link d-xl-none text-large ms-auto">
          <i class="bx bx-x bx-sm align-middle"></i>
        </a>
      </div>

      <div class="layout-menu-toggle navbar-nav d-xl-none align-items-xl-center me-3 me-xl-0">
        <a class="nav-item nav-link px-0 me-xl-4" href="#openMenu" @click.stop="openMenu">
          <i class="bx bx-menu bx-sm"></i>
        </a>
      </div>

      <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <ul class="navbar-nav flex-row align-items-center ms-auto">
          <!-- Search -->
<!--          <search/>-->
          <!-- /Search -->

          <!-- Alerts ->
          <alerts/>
          <-- / Alerts -->

          <!-- Quick links ->
          <quick-links/>
          <-- Quick links -->

          <!-- Notification ->
          <notifications/>
          <!-/ Notification -->

          <!-- User -->
          <mini-user/>
          <!--/ User -->
        </ul>
      </div>

      <!-- Search Small Screens -->
      <div class="navbar-search-wrapper search-input-wrapper container-xxl d-none">
        <input
            type="text"
            class="form-control search-input border-0"
            placeholder="Search..."
            aria-label="Search..."
        />
        <i class="bx bx-x bx-sm search-toggler cursor-pointer"></i>
      </div>
    </div>
  </nav>
</template>

<script>
import Search from "@/views/components/Navbar/Search";
//import QuickLinks from "@/views/components/Navbar/QuickLinks";
// Notifications from "@/views/components/Navbar/Notifications";
import MiniUser from "@/views/components/Navbar/MiniUser";
import {mapMutations} from "vuex";
//import Alerts from "@/views/components/Navbar/Alerts";

export default {
  name: "Navbar",
  components: {MiniUser, Search},
  methods: {
    ...mapMutations('common', ['setMenuState']),
    openMenu() {
      this.setMenuState(true)
    }
  }
}
</script>

<style scoped>
.app-brand-text {
  font-family: "Rubik", "Times New Roman", serif;
  line-height: 1.2;
}
</style>
