import { createStore } from 'vuex'
import common from "@/store/common";
import user from "@/store/user";

export default createStore({
  modules: {
    common,
    user
  }
})
