<template>
  <aside id="layout-menu" class="bg-menu-theme"
         :class="clientWidth >= 1200 ? 'layout-menu-horizontal menu menu-horizontal container-fluid flex-grow-0' : 'layout-menu menu menu-vertical'"
         v-click-outside="closeMenu"
  >
    <div class="container-xxl d-flex h-100" :class="{'flex-column p-0': clientWidth < 1200}">
      <a href="#" class="menu-horizontal-prev disabled d-none"></a>
      <div class="navbar-brand app-brand demo d-xl-flex py-0 me-4" v-if="clientWidth < 1200">
        <router-link :to="{name: 'home'}" class="app-brand-link gap-2">
          <span class="app-brand-text demo menu-text fw-bold">Панель управления</span>
        </router-link>
        <a href="#closeMenu" @click.prevent="closeMenu"
           class="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
          <i class="bx bx-x bx-sm align-middle"></i>
        </a>
      </div>
      <a href="#" class="menu-horizontal-prev" :class="{disabled: menuBreakPoints.length <= 1}" @click.prevent="prevMenuStep"></a>
      <div class="menu-horizontal-wrapper" v-show="clientWidth >= 1200" ref="menuHorizontalWrapper">
        <ul class="menu-inner" ref="scrollableMenu" :style="{marginLeft: -menuOffset + 'px'}">
          <menu-list :items="menuItems"/>
        </ul>
      </div>
      <a href="#" class="menu-horizontal-next" :class="{disabled: menuBreakPoints.length <= 1}" @click.prevent="nextMenuStep"></a>
      <ul class="menu-inner overflow-auto" v-if="clientWidth < 1200">
        <menu-list :items="menuItems"/>
      </ul>
      <a href="#" class="menu-horizontal-next d-none"></a>
    </div>
  </aside>
</template>

<script>
import MenuList from "@/views/components/Menu/MenuList";
import {mapState, mapMutations} from 'vuex';
import {route} from "@/router";

export default {
  name: "AsideMenu",
  components: {MenuList},
  data() {
    return {
      clientWidth: document.documentElement.clientWidth,
      wrapperSize: 0,
      menuBreakPoints: [],
      menuOffset: 0
    }
  },
  computed: {
    ...mapState('common', ['menuOpened']),
    menuItems() {
      return [
        {
          text: 'Рабочий стол',
          class: 'menu-icon tf-icons bx bx-home-circle',
          href: route('home'),
          access: 1,
        },
        {
          text: 'Обмены',
          class: 'menu-icon tf-icons bx bx-git-compare',
          href: '#',
          access: 2,
          sub: [
            {
              text: 'Не завершенные обмены',
              href: route('changes', {status: 'processing'}),
            },
            {
              text: 'Завершенные обмены',
              href: route('changes', {status: 'completed'}),
            },
            {
              text: 'Удаленные обмены',
              href: route('changes', {status: 'deleted'}),
            },
            {
              text: 'Логи обменов',
              href: route('change-logs'),
            },
          ]
        },
        {
          text: 'Сотрудники',
          class: 'menu-icon tf-icons bx bx-user',
          href: '#',
          access: 4,
          sub: [
            {
              text: 'Управление сотрудниками',
              href: route('user-list'),
            },
            {
              text: 'Роли и права',
              href: route('user-roles'),
            },
            {
              text: 'Логи сотрудников',
              href: route('user-logs'),
            },
            // {
            //   text: 'Действия сотрудников',
            //   href: route('logs'),
            // },
          ]
        },
        {
          text: 'Банк.карты',
          class: 'menu-icon tf-icons bx bx-credit-card',
          href: '#',
          access: 6,
          sub: [
            {
              text: 'Активные карты',
              href: route('active-cards', {currency: 'RUB'}),
            },
            {
              text: 'Выключенные карты',
              href: route('disabled-cards', {currency: 'RUB'}),
            },
            {
              text: 'Удаленные карты',
              href: route('deleted-cards', {currency: 'RUB'}),
            },
            {
              text: 'Панельные карты',
              href: route('panel-cards', {currency: 'RUB'}),
            },
            {
              text: 'Выделенные карты',
              href: route('highlighted-cards', {currency: 'RUB'}),
            },
          ]
        },
        {
          text: 'Хранилище',
          class: 'menu-icon tf-icons bx bxs-bank',
          href: '#',
          access: 8,
          sub: [
            {
              text: 'Bitcoin',
              href: route('store-btc'),
            },
            {
              text: 'USDT (TRC20)',
              href: route('store-tron'),
            },
            {
              text: 'История платежей',
              href: route('store-history'),
            },
            {
              text: 'Транзакции',
              href: route('store-transactions'),
            },
            {
              text: 'P2P Кошельки',
              href: route('store-p2pwallet'),
            }
          ]
        },
        {
          text: 'API',
          class: 'menu-icon tf-icons bx bx-code-alt',
          href: '#',
          access: 10,
          sub: [
            {
              text: 'Партнеры',
              href: route('partner-list'),
            },
            {
              text: 'API Ключи',
              href: route('api-list'),
            },

            /*{
              text: 'Логи',
              href: route('api-logs'),
            },*/
          ]
        },
        {
          text: 'Логи',
          class: 'menu-icon tf-icons bx bx-history',
          href: '#',
          access: 12,
          sub: [
            {
              text: 'Платежные',
              href: route('payment-logs', {currency: 'RUB'}),
            },
            {
              text: 'Списния',
              href: route('withdraw-logs', {currency: 'RUB'}),
            },
            {
              text: 'Другие',
              href: route('other-logs', {currency: 'RUB'}),
            }
          ]
        },
        {
          text: 'Ручные карты',
          class: 'menu-icon tf-icons bx bx-square',
          href: '#',
          access: 14,
          sub: [
            {
              text: 'Список партнеров',
              href: route('panel-list'),
            },
            {
              text: 'Список платежей',
              href: route('panel-payments'),
            },
            {
              text: 'Заявки на вывод',
              href: route('panel-withdraws'),
            },
            {
              text: 'Выделенные платежи',
              href: route('panel-partnerpayments'),
            }
          ]
        },
        {
          text: 'Бухгалтерия',
          class: 'menu-icon tf-icons bx bx-book-alt',
          href: '#',
          access: 16,
          sub: [
            {
              text: 'Подсчеты задолжностей',
              href: route('accounting-debts'),
            },
            {
              text: 'Автовывод',
              href: route('accounting-autooutput'),
            },
            {
              text: 'Отчеты',
              href: route('accounting-report'),
            },
          ]
        },
        {
          text: 'Утилиты',
          class: 'menu-icon tf-icons bx bx-grid-alt',
          href: '#',
          access: 18,
          sub: [
            {
              text: 'Потерянные платежи',
              href: route('utility-missing_pays', {currency: 'RUB'}),
            },
          ]
        },
        {
          text: 'Настройки',
          class: 'menu-icon tf-icons bx bx-cog',
          href: '#',
          access: 20,
          sub: [
            {
              text: 'Парсер',
              href: route('settings-parser'),
            },
            {
              text: 'Курсы',
              href: route('settings-rate'),
            },
            {
              text: 'Выделенные платежи',
              href: route('settings-bot'),
            },
            {
              text: 'Автовывод (Бот)',
              href: route('settings-botao'),
            },
            {
              text: 'Поддержка',
              href: route('settings-botsupport'),
            },
            {
              text: 'Панель (Бот)',
              href: route('settings-botpanel'),
            },
            {
              text: 'WebHook',
              href: route('settings-webhook'),
            },
          ]
        },
        /*{
          text: 'P2P Карты',
          class: 'menu-icon tf-icons bx bx-id-card',
          href: '#',
          access: 22,
          sub: [
            {
              text: 'Пользователи',
              href: route('privateapi-list'),
            },
          ]
        },*/
        /*{
            text: 'Бог обмена',
            class: 'menu-icon tf-icons bx bx-cog',
            href: '#',
            access: 20,
            sub: [
                {
                    text: 'Выплаты клиентов',
                    href: route('settings-parser'),
                },
                {
                    text: 'Выводы клиентов',
                    href: route('settings-rate'),
                },
            ]
        },*/
      ]
    }
  },
  mounted() {
    window.addEventListener('resize', this.recalculateAside);
    window.addEventListener('keyup', this.keyUp)
    this.getRenderedSizes(true)
  },
  unmounted() {
    window.removeEventListener('resize', this.recalculateAside);
    window.removeEventListener('keyup', this.keyUp)
  },
  methods: {
    ...mapMutations('common', ['setMenuState']),
    getRenderedSizes(mounted = false) {
      this.wrapperSize = this.$refs.menuHorizontalWrapper.getBoundingClientRect().width
      this.menuBreakPoints = [0]
      let menuItems = this.$refs.scrollableMenu.children
      let startPosition = 0

      for (let menuItem of menuItems) {
        let itemWidth = menuItem.getBoundingClientRect().width

        if (mounted)
          itemWidth += 2.8984375
        else
          itemWidth -= 2.8984375

        let endPosition = startPosition + itemWidth + 4

        let lostSize = endPosition - this.wrapperSize

        if (lostSize > 0) {
          this.menuBreakPoints.push(endPosition - this.wrapperSize)
        }

        startPosition = endPosition
      }

      this.menuOffset = 0
    },
    prevMenuStep() {
      let prevSize = this.menuBreakPoints[this.menuBreakPoints.length - 1]

      for (let i = 0; i < this.menuBreakPoints.length; i++) {
        if (this.menuOffset > this.menuBreakPoints[i] && this.menuOffset <= this.menuBreakPoints[i + 1]) {
          prevSize = this.menuBreakPoints[i]
          break
        }
      }

      this.menuOffset = prevSize
    },
    nextMenuStep() {
      let nextSize = 0

      for (let i = 0; i < this.menuBreakPoints.length; i++) {
        if (this.menuOffset >= this.menuBreakPoints[i] && this.menuOffset < this.menuBreakPoints[i + 1]) {
          nextSize = this.menuBreakPoints[i + 1]
        }
      }

      this.menuOffset = nextSize
    },
    keyUp(event) {
      if (event.code === 'ArrowRight')
        this.nextMenuStep()
      else if (event.code === 'ArrowLeft')
        this.prevMenuStep()
    },
    closeMenu() {
      if (this.menuOpened)
        this.setMenuState(false)
    },
    recalculateAside() {
      this.clientWidth = document.documentElement.clientWidth;
      this.getRenderedSizes()
    }
  },
}
</script>

<style scoped>
#layout-menu {
  transition: .3s all ease;
}

.app-brand-text {
  font-family: "Rubik", "Times New Roman", serif;
  line-height: 1.2;
}
</style>
