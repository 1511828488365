<template>
  <main-layout :code="8">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">История платежей</h5>
              <div class="card-header-elements ms-auto">
                <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                    class="tf-icon bx bx-refresh"></span> Обновить данные</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/store/history" :default-query="defaultQuery" ref="datatable" :default-order="defaultOrder" v-model:items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.withdrawid }}</span></td>
                  <td>{{ getType(item.type) }}</td>
                  <td>{{ item.cryptoShortName }}</td>
                  <td>{{ item?.label?.labelName }}</td>
                  <td>{{ item.sender }}</td>
                  <td>{{ item.amount }}</td>
                  <td>{{ item.comment }}</td>
                  <td>{{ getStatus(item.status) }}</td>
                  <td>
                    <moment-format :input="item.addTime" :output="DEFAULT_TIME_FORMAT"/>
                    <small class="text-muted">{{ moment(item.addTime).fromNow() }}</small>
                  </td>
                  <td>
                    <a href="#cancel" @click.prevent="cancel(item)" v-if="item.status === 'sending' || item.status === 'chunksend'"
                       class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Отменить вывод'}"><i
                        class="bx bx-stop"></i></a>
                    <a href="#resume" @click.prevent="resume(item)" v-if="item.status === 'canceled'"
                       class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Возобновить вывод'}"><i
                        class="bx bx-play"></i></a>
                    <a href="#full" @click.prevent="full(item)" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Список адресов'}"><i
                        class="bx bx-fullscreen"></i></a>
                    <a href="#history" @click.prevent="history(item)" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'История'}"><i
                        class="bx bx-history"></i></a>
                  </td>
                </template>
              </datatable>

              <store-history-modal v-model="storeHistoryInfoModal" :data="storeHistoryInfo"/>
              <store-full-info-modal v-model="storeFullInfoModal" :data="storeFullInfo"/>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {CARD_LANG, COMMON_LANG, DEFAULT_TIME_FORMAT, STORE_LANG} from "@/constants";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import StoreFullInfoModal from "@/views/components/Modals/StoreFullInfoModal";
import http from "@/http";
import moment from "moment-timezone";
import StoreHistoryModal from "@/views/components/Modals/StoreHistoryModal";
import Swal from "sweetalert2";

export default {
  name: "History",
  components: {StoreHistoryModal, StoreFullInfoModal, MomentFormat, Datatable, AddRoleModal, MainLayout},
  data() {
    return {
      storeHistoryInfoModal: false,
      storeHistoryInfo: null,
      storeFullInfoModal: false,
      storeFullInfo: null,
      defaultOrder: ['withdrawid', 'desc'],
      defaultQuery: {type: null, cryptoShortName: null, currency: null},
      DEFAULT_TIME_FORMAT,
      items: [],
      crypto: null,
      moment,
      columns: [
        {field: 'withdrawid', title: '#', sorting: true, filter: {type: 'input'}},
        {field: 'type', title: 'Тип', filter: {
            type: 'select', items: [
                    { title: 'Выплата', value: 'payout' },
                    { title: 'Перевод с админки', value: 'transferadmin' },
                ]
            }
        },
        {field: 'cryptoShortName', title: 'Криптовалюта', filter: {
            type: 'select', items: [
                    { title: 'BTC', value: 'BTC' },
                    { title: 'USDT', value: 'USDT' },
                ]
            }
        },
        {field: 'label', title: 'Лейбл'},
        {field: 'sender', title: 'Отправитель', filter: {type: 'input'}},
        {field: 'amount', title: 'Сумма', filter: {type: 'input'}},
        {field: 'comment', title: 'Комментарий', filter: {type: 'input'}},
        {field: 'status', title: 'Статус', filter: {type: 'input'}},
        {field: 'addTime', title: 'Время', filter: {type: 'datetime-range'}},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  mounted() {
    http.post('/api/store/get_labels', {
        crypto: this.crypto,
        page: 1,
        limit: 100,
    }).then(({data}) => {
        let {
            success,
            items
        } = data

        if (success) {
            const item = this.columns.find(c => c.field === label)
            if (item) {
                item.filter = { 
                    type: 'select', 
                    items: items.map(i => ({
                        title: `${i.labelName} [${i.cryptoShortName}]`, 
                        value: i.labelName
                    }))
                }
            }
            this.labels = items
        }
    });
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    cancel(item) {
      Swal.fire({
        title: STORE_LANG.CANCEL,
        text: '',
        showCancelButton: true,
        confirmButtonText: STORE_LANG.YES_CANCEL,
        cancelButtonText: COMMON_LANG.CANCEL,
      }).then((result) => {
        if (result.isConfirmed) {
          http.post('/api/store/cancel_transaction', {
            id: item.withdrawid
          }).then(({data}) => {

            let {
              success
            } = data

            if (success) {
              this.$refs.datatable.loadItems()
            }

          })
        }
      })
    },
    resume(item) {
      http.post('/api/store/resume_transaction', {
        id: item.withdrawid
      }).then(({data}) => {

        let {
          success
        } = data

        if (success) {
          this.$refs.datatable.loadItems()
        }

      })
    },
    full(item) {
      this.storeFullInfo = item
      this.storeFullInfoModal = true
    },
    history(item) {
      this.storeHistoryInfoModal = true
      this.storeHistoryInfo = null
      http.post('/api/store/transaction_history', {
        id: item.withdrawid
      }).then(({data}) => {
        this.storeHistoryInfo = {item, history: data.history}
      })
    },
    getType(type) {

      let result = null

      switch (type.toUpperCase()) {
        case 'TRANSFERADMIN':
          result = 'Перевод с админки'
          break;
        case 'PAYOUT':
          result = 'Выплата'
          break;
      }

      return result
    },
    getStatus(status) {
      let result = null

      switch (status) {
        case 'sending':
          result = 'В очереди'
          break;
        case 'chunksend':
          result = 'Частично отправлена'
          break;
        case 'send':
          result = 'Отправлена'
          break;
        case 'canceled':
          result = 'Отменена'
          break;
      }

      return result
    }
  },
}
</script>

<style scoped>

</style>
