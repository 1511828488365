<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 class="modal-title">Отправка крипты ({{ (crypto || '').toUpperCase() }})</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="address" class="form-label">Получатель</label>
                <input type="text" id="address" v-model.trim="address" class="form-control" placeholder="Адрес кошелька"
                       data-np-checked="1">
              </div>

              <div class="col-12 mb-3">
                <label for="amount" class="form-label">Сумма</label>
                <input type="text" id="amount" v-model.number="amount" class="form-control" placeholder="123"
                       data-np-checked="1">
              </div>

              <div class="col-12 mb-3">
                <label for="comment" class="form-label">Комментарий</label>
                <input type="text" id="comment" v-model.trim="comment" class="form-control"
                       placeholder="Произвольный комментарий"
                       data-np-checked="1">
              </div>

              <div class="col-12 mb-3">
                <label for="otp" class="form-label">Код из приложения</label>
                <input type="text" id="otp" v-model.trim="otp" class="form-control"
                       placeholder="123456" data-np-checked="1">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import http from "@/http";
import Select2 from "@/views/components/UI/Select2";
import LoadingButton from "@/views/components/UI/LoadingButton";

export default {
  name: "SendCryptoModal",
  props: ['id', 'modelValue', 'crypto'],
  components: {LoadingButton, Select2, LoadingBlock, Modal},
  data() {
    return {
      loading: false,

      address: null,
      amount: 0,
      comment: null,
      otp: null,
    }
  },
  methods: {
    saveItem() {
      this.loading = true
      http.post('/api/store/send_crypto', {
        address: this.address,
        crypto: this.crypto,
        amount: this.amount,
        from_wallet_id: this.id,
        comment: this.comment,
        otp: this.otp
      }).then(({data}) => {
        if (data.success) {
          this.$emit('update:modelValue', false)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
