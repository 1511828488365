<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" v-if="data">
        <div class="modal-header">
          <h5 class="modal-title">История транзакции | {{ data.item.withdrawid }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding: 0">
          <div class="table-responsive text-nowrap">
            <table class="table mt-1">
              <thead>
              <tr>
                <th>Текст</th>
                <th>Дата</th>
              </tr>
              </thead>
              <tbody class="table-border-bottom-0">
              <tr v-for="(row, index) in data.history" :key="index">
                <td>{{ row.text }}</td>
                <td><moment-format :input="row.historyTime" :output="DEFAULT_TIME_FORMAT"/></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import moment from "moment-timezone";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import {DEFAULT_TIME_FORMAT} from "@/constants";

export default {
  name: "StoreHistoryModal",
  props: ['modelValue', 'data'],
  components: {MomentFormat, LoadingBlock, Modal},
  data() {
   return {
     DEFAULT_TIME_FORMAT
   }
  }
}
</script>

<style scoped>

</style>
