import axios from "axios";
import {BACKEND_URL} from "@/constants";
import store from './store'
import toastr from "toastr";

const http = axios.create({
    baseURL: BACKEND_URL
})

http.interceptors.request.use((config) => {

    // @ts-ignore
    config.headers['Authorization'] = store.state["user"].token || null

    return config
}, (error) => {
    return Promise.reject(error);
})

http.interceptors.response.use((response) => {

    let {
        data
    } = response

    if (data.token)
        store.commit('user/setToken', data.token)

    if (data.user)
        store.commit('user/setUser', data.user)

    if (data.permissions)
        store.commit('user/setPermissions', data.permissions)

    if (data.notifications)
        store.commit('common/setNotifications', data.notifications)

    if (data.message) {
        if (data.success)
            toastr.success(data.message)
        else
            toastr.error(data.message)
    }

    return response;
}, (error) => {
    return Promise.reject(error);
});

export default http
