<template>
  <main-layout :code="20">
    <settings-layout>
      <div class="card">
        <div class="card-header header-elements">
          <h5 class="m-0 me-2">Настройка парсера</h5>
          <div class="card-header-elements ms-auto">
            <a href="#add-parse" @click.prevent="editItem(null)" class="btn btn-sm btn-secondary"><span
                class="tf-icon bx bx-plus"></span> Добавить парсер</a>
          </div>
        </div>
        <div class="card-datatable text-nowrap">
          <datatable ajax="/api/parser/list" ref="datatable" :default-order="defaultOrder" :items="items"
                     :columns="columns" :default-query="defaultQuery">
            <template #tr="item">
              <td><span class="fw-semibold">{{ item.autoID }}</span></td>
              <td>{{ item.sender }}</td>
              <td>{{ item.typeSMS }}</td>
              <td v-tooltip="{title: item.regexText}">{{ getString(item.regexText) }}</td>
              <td>{{ item.currency }}</td>
              <td>{{ item.jsonData.card }}</td>
              <td>{{ item.jsonData.balance }}</td>
              <td>{{ item.jsonData.amount }}</td>
              <td>
                <span v-if="item.usePort"><badge :color="COLORS.Success"/> Привязан</span>
                <span v-if="!item.usePort"><badge :color="COLORS.Danger"/> Не привязан</span>
              </td>
              <td>
                <a href="#edit" @click.prevent="editItem(item.autoID)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Редактировать'}"><i
                    class="bx bx-edit"></i></a>
                <a href="#remove" @click.prevent="removeItem(item.autoID)" class="btn btn-sm btn-icon item-edit"
                   v-tooltip="{title: 'Удалить'}"><i
                    class="bx bx-trash"></i></a>
              </td>
            </template>
          </datatable>
          <add-parse-modal v-model="addParseModal" :id="id" @update:page="updatePage"/>
        </div>
      </div>
    </settings-layout>
  </main-layout>
</template>

<script>
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import SettingsLayout from "@/views/layouts/SettingsLayout";
import MainLayout from "@/views/layouts/MainLayout";
import Datatable from "@/views/components/Datatable";
import AddParseModal from "@/views/components/Modals/AddParseModal";
import Badge from "@/views/components/UI/Badge";
import {COLORS} from "@/constants";
import http from "@/http";

export default {
  name: "ParserSettings",
  components: {Badge, AddParseModal, MomentFormat, SettingsLayout, MainLayout, Datatable},
  data() {
    return {
        id: null,
        defaultOrder: ['autoID', 'desc'],
        defaultQuery: { },
        COLORS,
        addParseModal: false,
        items: [],
        columns: [
            {field: 'autoID', title: '#', sorting: true},
            {field: 'sender', title: 'Отправитель'},
            {field: 'typeSMS', title: 'Тип'},
            {field: 'regexText', title: 'Регулярное выражение'},
            {field: 'currency', title: 'Валюта', filter: {
                type: 'select', items: [
                        { title: 'RUB', value: 'RUB' },
                        { title: 'UAH', value: 'UAH' },
                        { title: 'KZT', value: 'KZT' }
                    ]
                }
            },
            {field: 'card_id', title: 'ID Карты'},
            {field: 'balance_id', title: 'ID Баланса'},
            {field: 'amount_id', title: 'ID Суммы'},
            {field: 'usePort', title: 'Привязка к порту'},
            {field: 'actions', title: 'Действия', width: '110px'},
        ]
    }
  },
  methods: {
    removeItem(id) {
      http.post('/api/parser/remove', {
        id
      }).then(response => {
        this.$refs.datatable.loadItems()
      })
    },
    editItem(id) {
      this.id = id
      this.addParseModal = true
    },
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    getString(text) {
        if (text.length < 9) return text;
        return `${text.substring(0, 6)}..${text.substring(text.length - 2, text.length)}`;
    }
  }
}
</script>

<style scoped>

</style>
