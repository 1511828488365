import MissingPays from "@/views/pages/MissingPays.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/utility/missing_pays/:currency',
        name: 'utility-missing_pays',
        component: MissingPays,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
]
