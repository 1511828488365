<template>
  <main-layout :code="10">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Токены</h5>
              <div class="card-header-elements ms-auto">
                <a href="#add-partner" @click.prevent="editItem(null)" class="btn btn-sm btn-secondary"><span
                    class="tf-icon bx bx-plus"></span> Добавить токен</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/privateapi/list" ref="datatable" :items="items" :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.uid }}</span></td>
                  <td>{{ item.comment }}</td>
                  <td>{{ item.balance }}</td>
                  <td>
                    <a href="#edit" @click.prevent="editItem(item.uid)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Редактировать'}"><i
                        class="bx bx-edit"></i></a>
                    <a href="#edit" @click.prevent="depositItem(item.uid)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Пополнить баланс'}"><i
                        class="bx bx-plus-circle"></i></a>
                    <a href="#edit" @click.prevent="withdrawItem(item.uid)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Списать баланс'}"><i
                        class="bx bx-minus-circle"></i></a>
                        
                    <a href="#remove" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Удалить'}"
                       @click.prevent="removeItem(item)"><i
                        class="bx bx-trash"></i></a>
                  </td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->

      <add-private-api-modal v-model="AddPrivateApiModal" :id="id" @update:page="updatePage"/>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import AddPrivateApiModal from "@/views/components/Modals/AddPrivateApiModal";
import Swal from "sweetalert2";

export default {
  name: "ApiUserList",
  components: {AddPrivateApiModal, Datatable, MainLayout},
  data() {
    return {
      id: null,
      AddPrivateApiModal: false,
      defaultOrder: ['id', 'desc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'uid', title: '#'},
        {field: 'comment', title: 'Коментарий'},
        {field: 'balance', title: 'Баланс'},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    editItem(id) {
      this.id = id
      this.AddPrivateApiModal = true
    },
    removeItem(item) {        
        Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите удалить токен ${item.comment}(#${item.uid})?`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/privateapi/remove', {
                    id: item.uid
                }).then(() => {
                    this.$refs.datatable.loadItems()
                })
            }
        })
    },
    depositItem(id) {
        Swal.fire({
            title: "Введите сумму которую хотите добавить",
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/privateapi/deposit', {
                    id,
                    amount: Number(result.value)
                }).then(({data}) => {
                    let {
                        success
                    } = data

                    if (success) {
                        this.$refs.datatable.loadItems()
                    }
                })
            }
        });

    },
    withdrawItem(id) {
        Swal.fire({
            title: "Введите сумму которую хотите списать",
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/privateapi/withdraw', {
                    id,
                    amount: Number(result.value)
                }).then(({data}) => {
                    let {
                        success
                    } = data

                    if (success) {
                        this.$refs.datatable.loadItems()
                    }
                })
            }
        });

    },
  }
}
</script>

<style scoped>

</style>
