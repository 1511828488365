<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="data">
        <div class="modal-header">
          <h5 class="modal-title">Потерянные платежи | {{ data.item.uuID }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <bootstrap-select :items="data.missing_pays" placeholder="Выберите потерянный платеж" v-model="missingPayId"/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
          <loading-button :loading="loading" type="button" class="btn btn-label-success" @click.prevent="selectId">Выбрать</loading-button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>

</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import LoadingButton from "@/views/components/UI/LoadingButton";
export default {
  name: "MissingPayModal",
  props: ['modelValue', 'data'],
  components: {LoadingButton, BootstrapSelect, LoadingBlock, Modal},
  data() {
    return {
      missingPayId: null,
      loading: false,
    }
  },
  methods: {
    selectId() {
      this.$emit('missingPayId', {missingPayID: this.missingPayId, autoID: this.data.item.autoID})
    }
  }
}
</script>

<style scoped>

</style>
