import Me from "@/views/pages/Me.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/me',
        name: 'me',
        component: Me,
        props: true,
        meta: {
            middleware: [isUser]
        }
    },
]
