<template>
  <footer class="content-footer footer bg-footer-theme">
    <div class="container-xxl d-flex flex-column flex-md-row flex-wrap justify-content-between py-2">
      <div class="mb-2 mb-md-0">
        <a href="#" target="_blank" class="logo-font footer-link fw-semibold">Панель управления</a>
      </div>
      <div>
        <a href="#" target="_blank" class="footer-link d-none d-sm-inline-block">Поддержка</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter"
}
</script>

<style scoped>
.logo-font {
  font-family: "Rubik", "Times New Roman", serif;
  line-height: 1.2;
}
</style>
