<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-add-new-role">
      <div class="modal-content p-3 p-md-5" v-if="data">
        <button type="button" class="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-body">
          <div class="text-center mb-4">
            <h3 class="role-title">{{ id ? 'Редактирование роли' : 'Новая роль' }}</h3>
            <p>{{ id ? '' : 'Создание новой роли' }}</p>
          </div>
          <!-- Add role form -->
          <form class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework" method="post" @submit.prevent="saveItem">
            <div class="col-12 mb-4 fv-plugins-icon-container">
              <label class="form-label" for="itemTitle">Название роли</label>
              <input type="text" id="itemTitle" name="itemTitle" v-model="item.title" class="form-control"
                     placeholder="Администратор" tabindex="-1" data-np-invisible="1" data-np-checked="1">
              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div class="col-12">
              <h5>Права</h5>
              <!-- Permission table -->
              <div class="table-responsive">
                <table class="table table-flush-spacing">
                  <tbody>
                  <tr v-for="item in data" :key="item.id">
                    <td class="text-nowrap">{{ item.title }}</td>
                    <td>
                      <div class="d-flex">
                        <div class="form-check me-3 me-lg-5" v-for="perm in item.permissions" :key="perm.id">
                          <input class="form-check-input" type="checkbox" :id="`role_${perm.id}`" data-np-invisible="1"
                                 data-np-checked="1" v-model="permissions" :true-value="[]" :value="perm.id">
                          <label class="form-check-label" :for="`role_${perm.id}`">
                            {{ perm.title }}
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- Permission table -->
            </div>
            <div class="col-12 text-center">
              <button type="submit" class="btn btn-primary me-sm-3 me-1">Сохранить</button>
              <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Отмена
              </button>
            </div>
            <div></div>
            <input type="hidden" data-np-invisible="1" data-np-checked="1"></form>
          <!--/ Add role form -->
        </div>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import http from "@/http";

export default {
  name: "AddRoleModal",
  props: ['id', 'modelValue'],
  components: {LoadingBlock, Modal},
  data() {
    return {
      urls: {
        get: '/api/role/get',
        add: '/api/role/add',
        save: '/api/role/save'
      },
      data: null,
      permissions: [],
      item: {},
      defaultItem: {
        title: null
      }
    }
  },
  created() {
    this.item = {...this.defaultItem}
  },
  watch: {
    id(newVal) {
      if (newVal) {
        http.post(this.urls.get, {id: this.id}).then(({data}) => {
          let {
            success,
            item
          } = data

          if (success) {
            for (let field of Object.keys(this.item)) {
              this.item[field] = item[field]
            }

            // Extra
            let permissions = []
            for (let permission of item.permissions) {
              permissions.push(permission.permission_id)
            }

            this.permissions = permissions
          }

        })
      } else {
        for (let field of Object.keys(this.item)) {
          this.item = {...this.defaultItem}
        }

        // Extra
        this.permissions = []
      }
    }
  },
  mounted() {
    http.post('/api/role/get_permissions').then(({data}) => {
      this.data = data.items
    })
  },
  computed: {
    saveUrl() {
      return this.id ? this.urls.save : this.urls.add
    }
  },
  methods: {
    saveItem() {
      http.post(this.saveUrl, {
        id: this.id,
        item: this.item,
        permissions: this.permissions
      }).then(({data}) => {
        if (data.success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
