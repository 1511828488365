<template>
  <li class="nav-item navbar-dropdown dropdown-user dropdown">
    <a class="nav-link dropdown-toggle hide-arrow" href="#" data-bs-toggle="dropdown">
      <div class="avatar"><span class="avatar-initial rounded-circle bg-label-secondary">{{ user?.name[0] }}</span></div>
    </a>
    <ul class="dropdown-menu dropdown-menu-end">
      <li>
        <a class="dropdown-item" href="#">
          <div class="d-flex">
            <div class="flex-shrink-0 me-3">
              <div class="avatar"><span class="avatar-initial rounded-circle bg-label-secondary">{{ user?.name[0] }}</span></div>
            </div>
            <div class="flex-grow-1">
              <span class="lh-1 d-block fw-semibold">{{ user?.name }}</span>
              <small>Администратор</small>
            </div>
          </div>
        </a>
      </li>
      <li>
        <div class="dropdown-divider"></div>
      </li>
      <li>
        <router-link class="dropdown-item" :to="{name: 'me'}">
          <i class="bx bx-cog me-2"></i>
          <span class="align-middle">Настройки</span>
        </router-link>
      </li>
      <li>
        <div class="dropdown-divider"></div>
      </li>
      <li>
        <a class="dropdown-item" href="#logout" @click.prevent="logout">
          <i class="bx bx-power-off me-2"></i>
          <span class="align-middle">Выйти</span>
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "MiniUser",
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    ...mapMutations('user', ['setUser', 'setToken']),
    logout() {
      this.setUser(null)
      this.setToken(null)
      this.$router.push({name: 'login'})
    }
  }
}
</script>

<style scoped>

</style>
