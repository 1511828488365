export default {
    colors: {
        axisColor: "#a8b1bb",
        black: "#000",
        body: "#f2f2f6",
        borderColor: "#e9ecee",
        danger: "#ff5b5c",
        dark: "#495563",
        headingColor: "#516377",
        info: "#00cfdd",
        primary: "#5a8dee",
        secondary: "#69809a",
        success: "#39da8a",
        warning: "#fdac41",
        white: "#fff",
    }
}
