import ApiUserList from "@/views/pages/privateapi/ApiUserList.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/privateapi/list',
        name: 'privateapi-list',
        component: ApiUserList,
        meta: {
            middleware: [isUser]
        }
    },
]
