<template>
  <div class="position-relative">
    <select
        class="select2 form-select form-select-lg select2-hidden-accessible"
        data-allow-clear="true" aria-hidden="true">
      <option :value="item[localOptions.valueField]" :key="item[localOptions.valueField]" v-for="item in items">{{ localOptions.title && localOptions.title(item) || item[localOptions.titleField] }}</option>
    </select>
    <span ref="select" @click.stop="toggle" @focusin="isFocused = true" @focusout="isFocused = false"
          class="select2 select2-container select2-container--default select2-container--below"
          :class="{'select2-container--focus': isFocused, 'select2-container--open': isOpened}">
      <span class="selection">
        <span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true"
              aria-expanded="true"
              tabindex="0" aria-disabled="false">
          <span class="select2-selection__rendered" role="textbox" aria-readonly="true"
                :title="fullItem ? localOptions.title && localOptions.title(fullItem) || fullItem[localOptions.titleField] : locale.SELECT_ITEMS">
            <span class="select2-selection__clear" :title="locale.REMOVE_ALL_ITEM" v-if="fullItem"
                  @click.stop="selectItem(null)">×</span>{{ fullItem ? localOptions.title && localOptions.title(fullItem) || fullItem[localOptions.titleField] : locale.SELECT_ITEMS }}</span>
          <span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
        </span>
      </span>
      <span
          class="dropdown-wrapper" aria-hidden="true"></span></span>
        <span ref="dropdown" v-if="isOpened" v-click-outside="hide"
            class="select2-container select2-container--default select2-container--open" style="z-index: 1">
        <span class="select2-dropdown select2-dropdown--below">
            <span class="select2-search select2-search--dropdown" v-if="search">
            <input class="select2-search__field" type="search" tabindex="0"
                 autocomplete="off" autocorrect="off"
                 autocapitalize="none"
                 spellcheck="false" role="searchbox"
                 aria-autocomplete="list" data-np-checked="1">
        </span>
      <span class="select2-results">
        <ul class="select2-results__options" role="listbox"
            aria-expanded="true" aria-hidden="false">
          <li class="select2-results__option"
              :class="{'select2-results__option--highlighted': isHovered === item[localOptions.valueField]}" role="option"
              @click.prevent="selectItem(item)" @mouseenter="isHovered = item[localOptions.valueField]" @mouseleave="isHovered = null"
              :aria-selected="fullItem && item[localOptions.valueField] === fullItem[localOptions.valueField]" :key="item[localOptions.valueField]" v-for="item in items">
            <slot name="li">
              {{ localOptions.title && localOptions.title(item) || item[localOptions.titleField] }}
            </slot>
          </li>
        </ul>
      </span>
    </span>
    </span>
  </div>
</template>

<script>
import {createPopper} from '@popperjs/core'

export default {
  name: "Select2",
  props: ['items', 'modelValue', 'options', 'search'],
  data() {
    return {
      localOptions: {},
      popper: null,
      isFocused: false,
      isOpened: false,
      isHovered: null,
      fullItem: null,
      locale: {
        REMOVE_ALL_ITEM: 'Remove all items',
        SELECT_ITEMS: 'Select items'
      }
    }
  },
  watch: {
    modelValue(newVal) {
      this.updateItem(newVal)
    }
  },
  mounted() {
    this.updateItem(this.modelValue)
  },
  unmounted() {
    this.destroy()
  },
  created() {
    this.localOptions = {
      titleField: 'title',
      valueField: 'value',
      ...this.options
    }
  },
  methods: {
    selectItem(item) {
      this.$emit('update:modelValue', item ? item[this.localOptions.valueField] : null)

      this.hide()
    },
    updateItem(value) {
      this.fullItem = null

      for (let item of this.items) {
        if (item[this.localOptions.valueField] === value)
          this.fullItem = item
      }

      if (!this.fullItem)
        this.$emit('update:modelValue', null)
    },
    toggle() {
      if (this.isOpened)
        this.hide()
      else
        this.open()
    },
    async open() {
      if (this.isOpened)
        return

      this.isOpened = true
      await this.$nextTick()
      this.create()
    },
    hide() {
      if (!this.isOpened)
        return

      this.isOpened = false
      this.destroy()
    },
    create() {
      this.popper = createPopper(this.$refs.select, this.$refs.dropdown, this.options?.popper || {placement: 'bottom'})
    },
    destroy() {
      if (this.popper) {
        this.popper.destroy()
        this.popper = null
      }
    }
  }
}
</script>

<style scoped>

</style>
