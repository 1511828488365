<template>
  <main-layout :code="4">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Управление ролями</h5>
              <div class="card-header-elements ms-auto">
                <a href="#add-role" @click.prevent="editRole(null)" class="btn btn-sm btn-secondary"><span
                    class="tf-icon bx bx-plus"></span> Добавить роль</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/role/list" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.id }}</span></td>
                  <td>{{ item.title }}</td>
                  <td>
                    <div class="permission-list" v-for="permission in item.permissions"
                         :key="permission.permission.id"><span class="badge bg-secondary m-1">{{
                        permission.permission.group.title
                      }}: {{ permission.permission.title }}</span></div>
                  </td>
                  <td>
                    <a href="#edit" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Редактировать'}"
                       @click.prevent="editRole(item.id)"><i
                        class="bx bx-edit"></i></a>
                    <a href="#remove" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Удалить'}"
                       @click.prevent="removeItem(item)"><i
                        class="bx bx-trash"></i></a>
                  </td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->


    </div>
    <add-role-modal v-model="addRoleModal" :id="id" :data="permissions" @update:page="updatePage"/>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import Swal from "sweetalert2";

export default {
  name: "UserRoles",
  components: {Datatable, AddRoleModal, MainLayout},
  data() {
    return {
      id: null,
      permissions: null,
      addRoleModal: false,
      defaultOrder: ['id', 'desc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'id', title: '#', sorting: true},
        {field: 'title', title: 'Роль'},
        {field: 'permissions', title: 'Права'},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    removeItem(item) {
        Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите удалить роль ${item.title}(#${item.id})?`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (result.isConfirmed) {
                http.post('/api/role/remove', {
                    id: item.id
                }).then(() => {
                    this.$refs.datatable.loadItems()
                })
            }
        })
    },
    editRole(id) {
      this.id = id
      this.addRoleModal = true
    },
    updatePage() {
      this.$refs.datatable.loadItems()
    }
  }
}
</script>

<style scoped>
.permission-list {
  float: left;
}
</style>
