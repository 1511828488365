<template>
  <main-layout :code="10">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">API ключи</h5>
              <div class="card-header-elements ms-auto">
                <a href="#add-token" @click.prevent="editItem(null)" class="btn btn-sm btn-secondary"><span
                    class="tf-icon bx bx-plus"></span> Добавить токен</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/token/list" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.id }}</span></td>
                  <td>{{ item.partner.name }}</td>
                  <td>{{ item.token }}</td>
                  <td>
                    <div>
                        <span class="badge bg-secondary m-1" v-for="permission in item.permissions"
                              :key="permission.permission.id">{{ permission.permission.title }}</span>
                    </div>
                  </td>
                  <td>
                    <a href="#edit" @click.prevent="editItem(item.id)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Редактировать'}"><i
                        class="bx bx-edit"></i></a>
                    <a href="#remove" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: 'Удалить'}"
                       @click.prevent="removeItem(item.id)"><i
                        class="bx bx-trash"></i></a>
                  </td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->

      <add-api-modal :id="id" v-model="addTokenModal" @update:page="updatePage"/>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import AddApiModal from "@/views/components/Modals/AddApiModal";

export default {
  name: "TokenList",
  components: {AddApiModal, Datatable, AddRoleModal, MainLayout},
  data() {
    return {
      addTokenModal: false,
      defaultOrder: ['id', 'desc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      id: null,
      columns: [
        {field: 'id', title: '#', sorting: true},
        {field: 'partner_id', title: 'Партнер'},
        {field: 'token', title: 'Токен'},
        {field: 'permissions', title: 'Права'},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    editItem(id) {
      this.id = id
      this.addTokenModal = true
    },
    removeItem(id) {
      http.post('/api/token/remove', {
        id
      }).then(response => {
        this.$refs.datatable.loadItems()
      })
    }
  }
}
</script>

<style scoped>

</style>
