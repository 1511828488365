import {callWithAsyncErrorHandling} from "vue";

export const CARD_LANG = {
    REMOVE: "Вы действительно хотите удалить карту(-ы)",
    DISABLE: "Вы действительно хотите выключить карту(-ы)",
    ENABLE: "Вы действительно хотите включить карту(-ы)",
    RESTORE: "Вы действительно хотите восстановить карту(-ы)",
    YES_DISABLE: 'Да, выключить',
    YES_ENABLE: 'Да, включить',
    YES_RESTORE: 'Да, восстановить'
}

export const STORE_LANG = {
    CANCEL: "Вы уверены, что хотите отменить вывод",
    ADDRESS_CREATED: "Вы создали новый адрес",
    REMOVE_ADDRESS: 'Вы уверены, что хотите удалить данный адрес? Его невозможно будет восстановить! НИКАК!',
    YES_CANCEL: "Да, отменить"
}

export const ACCOUNTING_LANG = {
    COMPLETE_PAYMENT: "Вы уверены, что хотите завершить вывод средств",
    YES_COMPLETE: "Да, выплатить"
}

export const COMMON_LANG = {
    YES_DELETE: 'Да, удалить',
    CANCEL: 'Отменить'
}

export enum COLORS {
    Primary,
    Secondary,
    Success,
    Danger,
    Warning,
    Info
}

export const DEFAULT_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const BACKEND_URL = process.env.NODE_ENV === 'production' ? '' : 'http://127.0.0.1:3000'
export const LAYOUT_BREAKPOINT = 1200

export const changeStatus = {
    "0": 'Ожидает оплаты',
    "-1": 'Удалена',
    "2": 'Оплачена',
    "3": 'Оплачена после обращения',
    "4": 'Оплачена',
    "5": "Успешно | Автоподдержка"
}

export const cardDisableReason = {
    "1": "Блокировка",
    "2": "Обработка операция",
    "3": "Кража денег",
    "4": "Перелимит",
    "5": "Замена карт",
    "6": "Другое",
}

export const changeSources = {
    "6": "Botoshop",
    "99": "Odium",
    "100": "Zeus",
    "10": "Topklad",
    "15": "Alternative | UAH",
    "16": "Alternative | KZT",
}
