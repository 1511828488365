import Debts from "@/views/pages/accounting/Debts.vue";
import AutoOutput from "@/views/pages/accounting/AutoOutput.vue";
import Report from "@/views/pages/accounting/Report.vue";
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/accounting/debts',
        name: 'accounting-debts',
        component: Debts,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/accounting/autooutput',
        name: 'accounting-autooutput',
        component: AutoOutput,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/accounting/report',
        name: 'accounting-report',
        component: Report,
        meta: {
            middleware: [isUser]
        }
    },
]
