import {Modal} from "bootstrap";

let modalList: any = {}

export function show(name: string) {
    try {
        modalList[name].show()
    } catch (e) {
    }
}

export default {
    mounted(el: any, binding: any) {
        let {name, ...options} = binding.value

        el.modalName = name
        el.modal = new Modal(el, options)

        modalList[el.modalName] = el.modal
    },
    unmounted(el: any) {
        el.modal.dispose()
        delete modalList[el.modalName]
    }
}
