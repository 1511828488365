<template>
  <div class="container-xxl flex-grow-1 container-p-y">
    <div class="row">
      <div class="col-12 mb-md-0 mb-4">
        <ul class="nav nav-pills mb-3" role="tablist">
          <li class="nav-item" v-for="(link, index) in links" :key="index">
            <router-link :to="{name: link.route}" active-class="active" class="nav-link">{{ link.title }}</router-link>
          </li>
        </ul>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsLayout",
  data() {
    return {
      links: [
        {title: 'Парсер', route: 'settings-parser'},
        {title: 'Курсы', route: 'settings-rate'},
        {title: 'Выделенные платежи', route: 'settings-bot'},
        {title: 'Автовывод (Бот)', route: 'settings-botao'},
        {title: 'Поддержка', route: 'settings-botsupport'},
        {title: 'Панель (Бот)', route: 'settings-botpanel'},
        {title: 'WebHook', route: 'settings-webhook'},
      ]
    }
  } 
}
</script>

<style scoped>

</style>