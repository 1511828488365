<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" v-if="!loadingBlock">
        <form action="/api/cards/save" method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 v-if="!id" class="modal-title">Добвление</h5>
            <h5 v-else class="modal-title">Изменение #{{ id }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3" v-if="partners">
                <label for="partnerid" class="form-label">Партнер</label>                
                <select2 id="partnerid" v-model:items="partners" v-model="item.partnerid" :options="{titleField: 'name', valueField: 'id'}"/>
              </div>

              <div class="col-12 mb-3">
                <label for="time" class="form-label">Время вывода МСК (10:20, 10:30)</label>
                <input type="text" id="time" v-model="item.time" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>

              <div class="col-12 mb-3">
                <label for="extracharge" class="form-label">Наценка к выводу</label>
                <input type="text" id="extracharge" v-model="item.extracharge" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>

              <div class="col-12 mb-3">
                <label for="tgchatid" class="form-label">ID ЧАТА TELEGRAM</label>
                <input type="text" id="tgchatid" v-model="item.tgchatid" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>
              
              <div class="col-12 mb-3">
                <label for="autoPayment" class="form-label">Автовыплата</label>
                <select2 id="autoPayment" v-model="item.autoPayment" :items="autoPayments" :options="{ titleField: 'title', valueField: 'value' }" />
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
      <div class="modal-content" v-else>
        <loading-block class="p-3"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/views/components/UI/Modal";
import LoadingButton from "@/views/components/UI/LoadingButton";
import http from "@/http";
import LoadingBlock from "@/views/components/UI/LoadingBlock";
import Select2 from "@/views/components/UI/Select2";

export default {
  name: "AddAutoOutputOrEditModal",
  props: ['id', 'itemData'],
  components: {LoadingBlock, LoadingButton, Modal, Select2},
  data() {
    return {
      item: {},
      partners: null,
      loading: false,
      defaultItem: {
        numbercard: null,
        comment: null,
        port: null,
        source: null,
        todayWithdrawCount: null,
        todayWithdrawLimit: null,
        monthlyWithdrawCount: null,
        monthlyWithdrawLimit: null,
      },
      
      autoPayments: [
            {
                title: 'Включить', value: true
            }, 
            {
                title: 'Выключить', value: false
            }
        ]
    }
  },
  mounted() {
    http.post('/api/partner/list').then(({data}) => {
      this.partners = data.items
    })
  },
  
  watch: {
    id(newVal) {
        if (newVal && this.itemData) {
            this.item = {
                ...this.item,
                ...this.itemData
            }
        }
    }
  },
  methods: {
    saveItem() {
        if (this.loading) return;
        this.loading = true

        http.post('/api/accounting/autooutput/addorupdate', {
            id: this.id,
            item: this.item
        }).then(response => {

            let {
                success
            } = response.data

            if (success) {
                this.$emit('update:modelValue', false)
                this.$emit('update:page')
            }

        }).finally(() => {
            this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
