import PanelList from "@/views/pages/panel/PanelList.vue"
import PanelPayments from "@/views/pages/panel/PanelPayments.vue"
import PanelWithdraws from "@/views/pages/panel/PanelWithdraws.vue"
import PanelPartnerPayments from "@/views/pages/panel/PanelPartnerPayments.vue"
import isUser from "@/router/middlewares/isUser";

export default [
    {
        path: '/panel/list',
        name: 'panel-list',
        component: PanelList,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/panel/payments',
        name: 'panel-payments',
        component: PanelPayments,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/panel/withdraws',
        name: 'panel-withdraws',
        component: PanelWithdraws,
        meta: {
            middleware: [isUser]
        }
    },
    {
        path: '/panel/partnerpayments',
        name: 'panel-partnerpayments',
        component: PanelPartnerPayments,
        meta: {
            middleware: [isUser]
        }
    },
]
