<template>
  <li class="menu-item" :class="{open: opened}" v-click-outside="closeMenu">
    <a :href="item.href" class="menu-link" :class="{'menu-toggle': typeof item.sub == 'object'}"
       @click.prevent="handleClick">
      <i :class="item.class" v-if="item?.class"></i>
      <span class="badge rounded-pill badge-notifications bg-danger" v-if="item?.notify">{{ item.notify }}</span>
      <div>{{ item.text }}</div>
    </a>
    <ul class="menu-sub" v-if="typeof item.sub == 'object'">
      <menu-item v-for="(subItem, index) in item.sub" :close-menu-event="closeTree" :item="subItem" :key="index"/>
    </ul>
  </li>
</template>

<script>

import router from "@/router";
import {mapMutations} from "vuex";

export default {
  name: "MenuItem",
  props: ['item', 'closeMenuEvent'],
  data() {
    return {
      opened: false,
    }
  },
  methods: {
    ...mapMutations('common', ['setMenuState']),
    handleClick() {
      if (typeof this.item.sub == 'object') {
        this.opened = !this.opened
      } else {
        router.push(this.item.href)

        this.setMenuState(false)
        this.closeTree()
      }
    },
    closeTree() {
      this.closeMenu()
      if (this.closeMenuEvent)
        this.closeMenuEvent()
    },
    closeMenu() {
      this.opened = false
    }
  }
}
</script>

<style scoped>

</style>
