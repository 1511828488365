import PerfectScrollbar from "perfect-scrollbar";

export default {
    mounted(el: any, binding: any) {
        el.scrollbar = new PerfectScrollbar(el, binding.value)
    },
    unmounted(el: any) {
        el.scrollbar.destroy()
    }
}
