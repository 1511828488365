<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form method="post" @submit.prevent="confirm">
          <div class="modal-header">
            <h5 class="modal-title">Привязать к партнеру</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="partner_id" class="form-label">Партнер</label>
                <select2 id="partner_id" v-model.number="partner_id" :items="partners"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Привязать</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";
import Swal from "sweetalert2";

export default {
  name: "ConnectToPartnerModal",
  props: ['id', 'modelValue'],
  components: {Select2, BootstrapSelect, LoadingButton, Modal},
  data() {
    return {
      partners: [],
      partner_id: null,
      loading: false,
    }
  },
  watch: {
    id(newVal) {
      if (newVal) {
        this.loading = true
        http.post('/api/zs_partners/list', {order: ['partnerid', 'desc']}).then(({data}) => {
          let {
            success,
            items
          } = data

          if (success) {
            for (let item of items) {
              this.partners.push({title: `${item.partnername} | ${item.currency}`, value: item.partnerid})
            }
          }

        }).finally(() => {
          this.loading = false
        })
      } else {
        this.partners = []
      }
    }
  },
  methods: {
    confirm() {
        const partner = this.partners.find(p => p.value === this.partner_id)
        if (!partner) return;
        Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите карты ${Object.values(this.id).join(', ')} сделать выделенными картами для партнера ${partner.title}?`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (!result.isConfirmed) return;
            this.connectToPartner();
        })
    },

    connectToPartner() {
      this.loading = true
      http.post('/api/cards/connect', {
        id: this.id,
        partner_id: this.partner_id
      }).then(({data}) => {

        let {
          success,
        } = data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
