<template>
  <main-layout :code="20">
    <settings-layout>
      <div class="card">
        <div class="card-header header-elements">
          <h5 class="m-0 me-2">Панель (Бот)</h5>
        </div>
        <div class="card-datatable text-nowrap">
          <datatable ajax="/api/bot/list" ref="datatable" :default-order="['id', 'desc']" :default-query="defaultQuery" :items="items" :columns="columns">
            <template #tr="item">
              <td><span class="fw-semibold">{{ item.id }}</span></td>
              <td>{{ item.name }}</td>
              <td>{{ item.token }}</td>
              <td>{{ item.proxy }}</td>
              <td>
                <a href="#edit" @click.prevent="editItem(item)" class="btn btn-sm btn-icon" v-tooltip="{title: 'Редактировать'}"><i
                    class="bx bx-edit"></i></a>
                    
                <a href="#check" class="btn btn-sm btn-icon" @click.prevent="checkProxy(item.id)"
                    v-tooltip="{title: 'Проверить прокси'}"><i
                    class="bx bx-check"></i></a>
              </td>
            </template>
          </datatable>
        </div>
      </div>
    </settings-layout>
  </main-layout>
  <edit-bot-modal v-model="editBotModal" :item="item" @update:page="updatePage"/>
</template>

<script>
import {DEFAULT_TIME_FORMAT} from "@/constants";
import Datatable from "@/views/components/Datatable";
import MainLayout from "@/views/layouts/MainLayout";
import SettingsLayout from "@/views/layouts/SettingsLayout";
import toastr from "toastr";
import http from "@/http";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import EditBotModal from "@/views/components/Modals/EditBotPanelModal";//EditBotAOModal

export default {
  name: "BotPanelSettings",
  components: {EditBotModal, MomentFormat, SettingsLayout, MainLayout, Datatable},
  data() {
    return {
      item: null,
      DEFAULT_TIME_FORMAT,
      editBotModal: false,
      defaultQuery: { type: 'botPanel' },
      items: [],
      columns: [
        {field: 'id', title: '#', sorting: true},
        {field: 'name', title: 'Название'},
        {field: 'token', title: 'Токен'},
        {field: 'proxy', title: 'Прокси'},   
        {field: 'actions', title: 'Действия'},
      ]
    }
  },
  methods: {
    editItem(item) {
      this.item = item
      this.editBotModal = true
    },
    updatePage() {
      this.$refs.datatable.loadItems()
    },    
    checkProxy(autoID) {
      toastr.info('Идет проверка прокси...')

      http.post('/api/bot/check_proxy', {
        id: autoID
      })
    },
  }
}
</script>

<style scoped>

</style>
