import store from '../../store'

export default function isUser ({ next }: any){

    // @ts-ignore
    if(store.state['user'].user) {
        return next()
    }

    return next({
        name: 'login'
    })
}
