<template>
  <main-layout :code="1">
    <div class="container-xxl flex-grow-1 container-p-y">      
        <div class="row">
            <conversion :currency="['RUB', 'UAH', 'KZT']" />
        </div>
        <div class="row">
            <income-summary :currency="{id: 1, symbol: '₽', code: 'RUB'}"/>
            <rates-widget :items="rateWidgets" :line="1" @update:page="updatePage" />

            <income-summary :currency="{id: 2, symbol: '₴', code: 'UAH'}"/>
            <rates-widget :items="rateWidgets" :line="2" @update:page="updatePage" />

            <income-summary :currency="{id: 3, symbol: '₸', code: 'KZT'}"/>
            <rates-widget :items="rateWidgets" :line="3" @update:page="updatePage" />

            <problem-summary :currency="{id: 1, symbol: '₽', code: 'RUB'}"/>
            <rates-widget :items="rateWidgets" :line="3" @update:page="updatePage" />
            
            <problem-summary :currency="{id: 2, symbol: '₴', code: 'UAH'}"/>
            <rates-widget :items="rateWidgets" :line="4" @update:page="updatePage" />

            <problem-summary :currency="{id: 3, symbol: '₸', code: 'KZT'}"/>

            <tickets-stats :currency="{id: 1, symbol: '₽', code: 'RUB'}"/>
            <tickets-stats :currency="{id: 2, symbol: '₴', code: 'UAH'}"/>
            <tickets-stats :currency="{id: 3, symbol: '₸', code: 'KZT'}"/>
        </div>
    </div>
  </main-layout>
</template>

<script>
import '@/assets/vendor/libs/select2/select2.css'
import MainLayout from "@/views/layouts/MainLayout";
import Select2 from "@/views/components/UI/Select2";
import IncomeSummary from "@/views/widgets/IncomeSummary";
import ProblemSummary from "@/views/widgets/ProblemSummary";
import TicketsStats from "@/views/widgets/TicketsStats";
import RatesWidget from "@/views/widgets/RatesWidget";
import Conversion from "@/views/widgets/Conversion";
import http from "@/http";

export default {
  name: "Home",
  components: {RatesWidget, IncomeSummary, TicketsStats, ProblemSummary, Select2, MainLayout, Conversion},
  data() {
    return {
      rates: {},
      incomes: {},
      rateWidgets: null
    }
  },
  mounted() {
    this.updatePage()
  },
  methods: {
    updatePage() {
      http.post('/api/home/get').then(({data}) => {
        let {
          success,
          rates,
          incomes,
          rate_widgets
        } = data
        
        if (success) {
          this.rates = rates
          this.incomes = incomes
          this.rateWidgets = rate_widgets
        }

      })
    }

  }
}
</script>

<style scoped>

</style>
