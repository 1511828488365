<template>
  <main-layout :code="8">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Транзакции</h5>
              <div class="card-header-elements ms-auto">
                <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                    class="tf-icon bx bx-refresh"></span> Обновить данные</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/store/transactions" :default-query="defaultQuery" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.transactionid }}</span></td>
                  <td>{{ getType(item.type) }}</td>
                  <td>{{ item.cryptoShortName }}</td>
                  <td v-if="item.inputAddress"><a href="#"  @click.prevent="copyTextToClipboard(item.inputAddress)" v-tooltip="{title: item.inputAddress}">{{ minifyAddress(item.inputAddress) }}</a></td><td v-else><i>нет</i></td>
                  <td v-if="item.outputAddress"><a href="#"  @click.prevent="copyTextToClipboard(item.outputAddress)" v-tooltip="{title: item.outputAddress}">{{ minifyAddress(item.outputAddress) }}</a></td><td v-else><i>нет</i></td>
                  <td>{{ item.amount }} {{ item.cryptoShortName }}</td>
                  <td>
                    <moment-format :input="item.addTime" :output="DEFAULT_TIME_FORMAT"/>
                    <small class="text-muted">{{ moment(item.addTime).fromNow() }}</small>
                  </td>
                  <td v-if="item.txidlink">
                    <a :href="item.txidlink" target="_blank" v-tooltip="{title: item.txidlink}">{{ minifyAddress(item.txid) }}</a>
                    <a href="#full" @click.prevent="copyTextToClipboard(item.txid)" class="btn btn-sm btn-icon"
                       v-tooltip="{title: 'Скопировать'}"><i
                        class="bx bx-copy"></i></a>
                  </td>
                  <td v-else><i>нет</i></td>
                  <td>
                    <a href="#full" @click.prevent="full(item)" class="btn btn-sm btn-icon"
                       v-tooltip="{title: 'Подробнее'}"><i
                        class="bx bx-fullscreen"></i></a>
                  </td>
                </template>
              </datatable>
              <transaction-info-modal v-model="transactionInfoModal" :data="transactionInfo"/>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddRoleModal from "@/views/components/Modals/AddRoleModal";
import Datatable from "@/views/components/Datatable";
import {DEFAULT_TIME_FORMAT} from "@/constants";
import {copyTextToClipboard, getTxLink, minifyAddress} from "@/functions"
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import StoreFullInfoModal from "@/views/components/Modals/StoreFullInfoModal";
import http from "@/http";
import moment from "moment-timezone";
import TransactionInfoModal from "@/views/components/Modals/TransactionInfoModal";

export default {
  name: "Transactions",
  components: {TransactionInfoModal, StoreFullInfoModal, MomentFormat, Datatable, AddRoleModal, MainLayout},
  data() {
    return {
      transactionInfoModal: false,
      transactionInfo: null,
      defaultOrder: ['withdrawid', 'desc'],
      defaultQuery: {type: null, cryptoShortName: null},
      DEFAULT_TIME_FORMAT,
      items: [],
      crypto: null,
      moment,
      columns: [
        {field: 'transactionid', title: '#', sorting: true},
        {field: 'type', title: 'Тип', filter: {
            type: 'select', items: [
                    { title: 'Пополнение', value: 'topup' },
                    { title: 'Перевод', value: 'transfer' },
                    { title: 'Объединение адресов', value: 'extendwallets' }
                ]
            }
        },
        {field: 'cryptoShortName', title: 'Криптовалюта', filter: {
            type: 'select', items: [
                    { title: 'BTC', value: 'BTC' },
                    { title: 'USDT', value: 'USDT' },
                    { title: 'TRX', value: 'TRX' }
                ]
            }
        },
        {field: 'inputAddress', title: 'Исходящий адрес'},
        {field: 'outputAddress', title: 'Входящий Адрес', filter: {type: 'input'}},
        {field: 'amount', title: 'Сумма'},
        {field: 'addTime', title: 'Время'},
        {field: 'txidlink', title: 'TXID', filter: {type: 'input'}},
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    updatePage() {
      this.$refs.datatable.loadItems()
    },
    full(item) {
      http.post('/api/store/transaction_info', {
        id: item.transactionid
      }).then(({data}) => {
        let {
          transactioninfo
        } = data

        this.transactionInfo = {item, transactioninfo}
        this.transactionInfoModal = true
      })
    },
    getType(type) {

      let result = null

      switch (type) {
        case 'topup':
          result = 'Пополнение'
          break;
        case 'transfer':
          result = 'Перевод'
          break;
        case 'extendwallets':
          result = 'Объединение адресов'
          break;
      }

      return result
    },
    minifyAddress,
    getTxLink,
    copyTextToClipboard
  },
}
</script>

<style scoped>

</style>
