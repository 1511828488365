<template>
  <main-layout :codes="16">
    <div class="container-xxl flex-grow-1 container-p-y">
      <!-- Role cards -->
      <div class="row g-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header header-elements">
              <h5 class="m-0 me-2">Автовывод</h5>

              <div class="card-header-elements ms-auto">
                <a href="#add" @click.prevent="addOrEdit()" class="btn btn-sm btn-primary"><span
                    class="tf-icon bx bx-refresh"></span> Добавить</a>
              </div>
            </div>
            <div class="card-datatable text-nowrap">
              <datatable ajax="/api/accounting/autooutput" ref="datatable" :default-order="defaultOrder" :items="items"
                         :columns="columns">
                <template #tr="item">
                  <td><span class="fw-semibold">{{ item.id }}</span></td>
                  <td>
                    <span v-if="item?.partner">{{ item?.partner?.name }}</span>
                    <i v-else>нет</i>
                  </td>
                  <td>{{ item.time }}</td>
                  <td>{{ item.extracharge }}</td>
                  <td>{{ item.tgchatid }}</td>
                  <td>
                    <span v-if="item.status" class="badge bg-success m-1">Включено</span>
                    <span v-else class="badge bg-danger m-1">Выключено</span>

                  </td>
                  <td v-if="item.autoPayment">Включен</td>
                  <td v-else>Выключен</td>
                  <td>
                    <a href="#disable" @click.prevent="updateStatus(item.id)" class="btn btn-sm btn-icon item-edit"
                       v-tooltip="{title: item.status ? 'Выключить' : 'Включить'}"><i
                        class="bx bx-power-off"></i></a>
                    <a href="#edit" @click.prevent="addOrEdit(item)" class="btn btn-sm btn-icon item-edit"
                        v-tooltip="{title: 'Изменить'}"><i
                        class="bx bx-edit"></i></a>
                    <a href="#remove" @click.prevent="remove(item.id)" class="btn btn-sm btn-icon item-edit"
                        v-tooltip="{title: 'Удалить'}"><i
                        class="bx bx-trash"></i></a>
                  </td>
                </template>
              </datatable>
            </div>
          </div>
        </div>
      </div>
      <!--/ Role cards -->

      <add-auto-output-or-edit-modal v-model="addAutoOutputOrEditModal" :id="id" :itemData="item" @update:page="updatePage"/>
    </div>

  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import AddAutoOutputOrEditModal from "@/views/components/Modals/AddAutoOutputOrEditModal";
import Datatable from "@/views/components/Datatable";
import {ACCOUNTING_LANG, COMMON_LANG, DEFAULT_TIME_FORMAT, STORE_LANG} from "@/constants";
import http from "@/http";
import Swal from "sweetalert2";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";

export default {
  name: "AutoOutput",
  components: {MomentFormat, AddAutoOutputOrEditModal, Datatable, MainLayout},
  data() {
    return {
      id: null,
      item: null,
      addAutoOutputOrEditModal: false,
      load: false,
      defaultOrder: ['id', 'desc'],
      DEFAULT_TIME_FORMAT,
      items: [],
      columns: [
        {field: 'id', title: '#', sorting: true},
        {field: '$partner.name$', title: 'Партнер'},
        {field: 'time', title: 'Время (МСК)'},
        {field: 'extracharge', title: 'Наценка к выводу'},
        {field: 'tgchatid', title: 'ID Чата Telegram'},
        {field: 'status', title: 'Статус'},
        {field: 'autoPayment', title: 'Автовывод'},     
        {field: 'actions', title: 'Действия', width: '110px'},
      ]
    }
  },
  methods: {
    updatePage() {
        this.$refs.datatable.loadItems()
    },
    addOrEdit(item = null) {
        this.id = item?.id;
        this.item = item;
        this.addAutoOutputOrEditModal = true;
    },
    updateStatus(id) {
        if (this.load) return;
        this.load = true;
        http.post('/api/accounting/autooutput/updateStatus', {
            id
        }).then(() => {
            this.$refs.datatable.loadItems()
            this.load = false;
        })
    },
    remove(id) {
        if (this.load) return;
        this.load = true;

        Swal.fire({
            title: 'Подтверждение',
            text: `Точно ли вы уверенны, что вы хотите удалить ${id}?`,
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
        }).then((result) => {
            if (!result.isConfirmed) {
                this.load = false;
                return;
            }
            http.post('/api/accounting/autooutput/remove', {
                id,
            }).then(({data}) => {
                let {
                    success
                } = data

                if (success) {
                    this.$refs.datatable.loadItems()
                }

                this.load = false;
            })
        })
    },
  }
}
</script>

<style scoped>

</style>
