<template>
  <main-layout :code="18">
    <div class="container-xxl flex-grow-1 container-p-y">
      <div class="card">
        <div class="card-header header-elements">
          <h5 class="m-0 me-2">Потерянные платежи</h5>
          <div class="card-header-elements ms-auto">            
            <a href="#add" @click.prevent="addPayment()" class="btn btn-sm btn-secondary"><span
                class="tf-icon bx bx-plus"></span> Добавить платежи</a>
            <a href="#refresh" @click.prevent="updatePage" class="btn btn-sm btn-primary"><span
                class="tf-icon bx bx-refresh"></span> Обновить данные</a>
          </div>
        </div>
        <div class="card-datatable text-nowrap">
          <datatable ajax="/api/utility/missing_pays" ref="datatable" :default-order="defaultOrder" :items="items"
                     :columns="columns" :default-query="defaultQuery">
            <template #tr="item">
              <td><span class="fw-semibold">{{ item.autoID }}</span></td>
              <td>{{ item.card }}</td>
              <td>{{ item.count }} {{ item.currency }}</td>
              <td>
                <moment-format :input="item.time" :output="DEFAULT_TIME_FORMAT"/>
              </td>
              <td>
                  <span v-if="item.isTicket"><badge :color="COLORS.Danger"/> Тикет</span>
                  <span v-else-if="item.uuID && item.uuID.length">{{ item.uuID }}</span>
                  <span v-else><i>нет</i></span>
              </td>
              <td>
                <span v-if="item.isSystem"><badge :color="COLORS.Danger"/> Системный</span>
                <span v-else><i>нет</i></span>
              </td>
              <td>{{ item.currency }}</td>
              <td v-if="item.author">{{ item.author }}</td>
              <td v-else>нет</td>
              <td>
                <a href="#setSystem" v-if="!item.isSystem" @click.prevent="setSystem(item.autoID)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Системный платеж'}"><i
                    class="bx bx-check"></i></a>
                <a href="#giveOrder" v-if="!item.isTicket && (!item.uuID || !item.uuID.length)" @click.prevent="giveOrder(item.autoID)" class="btn btn-sm btn-icon item-edit" v-tooltip="{title: 'Выдать за заявку'}"><i
                    class="bx bx-check"></i></a>
              </td>
            </template>
          </datatable>
          <give-order-modal v-model="giveOrderModal" :missing-pay-id="giveOrderInfo"/>
        </div>
      </div>
    </div>

    <add-payment-modal v-model="addPaymentModal" />
  </main-layout>
</template>

<script>
import MainLayout from "@/views/layouts/MainLayout";
import Datatable from "@/views/components/Datatable";
import {COLORS, DEFAULT_TIME_FORMAT} from "@/constants";
import http from "@/http";
import MomentFormat from "@/views/components/UI/Moment/MomentFormat";
import ChangeCurrencyLayout from "@/views/layouts/ChangeCurrencyLayout";
import GiveOrderModal from "@/views/components/Modals/GiveOrderModal";
import Badge from "@/views/components/UI/Badge.vue";
import AddPaymentModal from "@/views/components/Modals/AddPaymentUtilsModal";


export default {
  name: "MissingPays",
  components: {Badge, GiveOrderModal, ChangeCurrencyLayout, MomentFormat, Datatable, MainLayout, AddPaymentModal},
  props: ['currency', 'missingPayId'],
  data() {
    return {
      giveOrderModal: false,
      giveOrderInfo: null,
      defaultOrder: ['autoID', 'desc'],
      defaultQuery: { },
      DEFAULT_TIME_FORMAT,
      COLORS,
      items: [],
      columns: [
        {field: 'autoID', title: '#', sorting: true},
        {field: 'card', title: 'Карта', filter: {type: 'input', operator: 'fulltext'}},
        {field: 'count', title: 'Сумма', filter: {type: 'input'}},
        {field: 'time', title: 'Время'},
        {field: 'uuID', title: 'Заявка'},
        {field: 'isSystem', title: 'Системный'},
        {field: 'currency', title: 'Валюта', filter: {
            type: 'select', items: [
                    { title: 'RUB', value: 'RUB' },
                    { title: 'UAH', value: 'UAH' },
                    { title: 'KZT', value: 'KZT' }
                ]
            }
        },
        {field: 'author', title: 'Сотрудник'},
        {field: 'actions', title: 'Действия', width: '110px'},
      ],
      currencies: [
        {title: 'RUB', value: 'RUB'},
        {title: 'UAH', value: 'UAH'},
        {title: 'KZT', value: 'KZT'}
      ],
      addPaymentModal: false
    }
  },
  
  created() {
    if (this.missingPayId) {
        this.defaultQuery = {...this.defaultQuery, autoID: this.missingPayId}
    }
  },

  watch: {
    missingPayId(newVal) {
        this.defaultQuery = {...this.defaultQuery, autoID: newVal}
    }
  },
  methods: {
    addPayment(id) {
      this.addPaymentModal = true
    },
    setSystem(id) {
      http.post('/api/utility/set_system', {
        id
      }).then(({data}) => {
        const {success} = data

        if (success)
          this.updatePage()
      })
    },
    giveOrder(missingPayId) {
      this.giveOrderInfo = missingPayId
      this.giveOrderModal = true
    },
    updatePage() {
      this.$refs.datatable.loadItems()
    }
    // removeItem(id) {
    //   http.post('/api/partner/remove', {
    //     id
    //   }).then(response => {
    //     this.$refs.datatable.loadItems()
    //   })
    // }
  }
}
</script>

<style scoped>

</style>
