<template>
  <modal v-model="modelValue" @update:modelValue="newValue => $emit('update:modelValue', newValue)">
    <div class="modal-dialog modal-dialog-centered" role="document" v-if="item && item.togglechange">
      <div class="modal-content">
        <form method="post" @submit.prevent="saveItem">
          <div class="modal-header">
            <h5 class="modal-title" v-if="!id">Добавление партнера</h5>
            <h5 class="modal-title" v-else>Изменение партнера</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="name" class="form-label">Название</label>
                <input type="text" id="name" v-model="item.name" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="sourceCode" class="form-label">Код источника</label>
                <input type="text" id="sourceCode" v-model="item.sourceCode" class="form-control"
                       placeholder="123" data-np-checked="1">
              </div>
              <div class="col-12 mb-3">
                <label for="currency" class="form-label">Валюта</label>
                <select2 id="currency" v-model="item.currency" :items="currencies"/>
              </div>
              <div class="col-12 mb-3">
                <label for="type" class="form-label">Тип</label>
                <select2 id="type" v-model="item.type" :items="types"/>
              </div>
              <div class="col-12 mb-3">
                <label for="financeGods" class="form-label">Финансовый бог</label>
                <select2 id="financeGods" v-model="item.useFinanceGod" :items="financeGods"/>
              </div>

              <div class="col-12 mb-3" v-if="id">
                <label for="fake_procent" class="form-label">Обманная надбавка</label>
                <input type="text" id="fake_procent" v-model="item.fake_procent" class="form-control" placeholder=""
                       data-np-checked="1">
              </div>

              <div class="col-12 mb-3" v-if="id">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="1"
                         v-model="item.is_p2p_toggle" id="is_p2p_toggle-toggle">
                  <label class="form-check-label" for="is_p2p_toggle-toggle">P2P Карты</label>
                </div>
              </div>

              <div v-if="id">
                <h3>BTC</h3>
                <div class="col-12 mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="1"
                           v-model="item.togglechange.bitcoin.card_bitcoin.toggle" id="card_bitcoin-toggle">
                    <label class="form-check-label" for="card_bitcoin-toggle">Включить направление</label>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label for="procent" class="form-label">Процент</label>
                  <input type="text" id="procent" v-model.number="item.togglechange.bitcoin.card_bitcoin.procent"
                         class="form-control"
                         placeholder="123" data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                  <label for="minlimit" class="form-label">Минимальный лимит</label>
                  <input type="text" id="minlimit" v-model.number="item.togglechange.bitcoin.card_bitcoin.minlimit"
                         class="form-control"
                         placeholder="123" data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                  <label for="maxlimit" class="form-label">Максимальный лимит</label>
                  <input type="text" id="maxlimit" v-model.number="item.togglechange.bitcoin.card_bitcoin.maxlimit"
                         class="form-control"
                         placeholder="123" data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                  <label for="rateID" class="form-label">ID Курса</label>
                  <input type="text" id="rateID" v-model.number="item.togglechange.bitcoin.card_bitcoin.rateID"
                         class="form-control"
                         placeholder="123" data-np-checked="1">
                </div>
              </div>

              <div v-if="id">
                <h3>USDT</h3>
                <div class="col-12 mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="1"
                           v-model="item.togglechange.usdt.card_usdt.toggle" id="toggle">
                    <label class="form-check-label" for="toggle">Включить направление</label>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label for="procent2" class="form-label">Процент</label>
                  <input type="text" id="procent2" v-model.number="item.togglechange.usdt.card_usdt.procent"
                         class="form-control"
                         placeholder="123" data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                  <label for="minlimit2" class="form-label">Минимальный лимит</label>
                  <input type="text" id="minlimit2" v-model.number="item.togglechange.usdt.card_usdt.minlimit"
                         class="form-control"
                         placeholder="123" data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                  <label for="maxlimit2" class="form-label">Максимальный лимит</label>
                  <input type="text" id="maxlimit2" v-model.number="item.togglechange.usdt.card_usdt.maxlimit"
                         class="form-control"
                         placeholder="123" data-np-checked="1">
                </div>
                <div class="col-12 mb-3">
                  <label for="rateID2" class="form-label">ID Курса</label>
                  <input type="text" id="rateID2" v-model.number="item.togglechange.usdt.card_usdt.rateID"
                         class="form-control"
                         placeholder="123" data-np-checked="1">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Отмена</button>
            <loading-button class="btn btn-label-success" :loading="loading" type="submit">Сохранить</loading-button>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import LoadingButton from "@/views/components/UI/LoadingButton";
import Modal from "@/views/components/UI/Modal";
import http from "@/http";
import BootstrapSelect from "@/views/components/UI/BootstrapSelect";
import Select2 from "@/views/components/UI/Select2";

export default {
  name: "AddPartnerModal",
  props: ['id', 'modelValue'],
  components: {Select2, BootstrapSelect, LoadingButton, Modal},
  data() {
    return {
      urls: {
        get: '/api/partner/get',
        add: '/api/partner/add',
        save: '/api/partner/save'
      },
      loading: false,
      item: {},
      defaultItem: {
        name: null,
        sourceCode: null,
        currency: null,
        type: null,
        useFinanceGod: null,
        fake_procent: null,
        is_p2p_toggle: null,
        togglechange: {
          "bitcoin": {
            "card_bitcoin": {
              "toggle": null,
              "procent": null,
              "minlimit": null,
              "rateID": null,
              "maxlimit": null,
              "currency": null
            }
          }, "usdt": {
            "card_usdt": {
              "toggle": null,
              "procent": null,
              "minlimit": null,
              "rateID": null,
              "maxlimit": null,
              "currency": null
            }
          }
        }
      },
      currencies: [
        {title: 'RUB', value: 'RUB'},
        {title: 'UAH', value: 'UAH'},
        {title: 'KZT', value: 'KZT'}
      ],
      types: [
        {value: 'fiatbalance', title: 'Фиатный баланс'},
        {value: 'orders', title: 'Выплата за заявки'},
        {value: 'cryptobalance', title: 'Криптовалютный баланс'},
        {value: 'fastsend', title: 'Моментальная отправка'},
      ],
      financeGods: [
        {value: true, title: 'Да'},
        {value: false, title: 'Нет'},
      ],
    }
  },
  created() {
    this.item = {...this.defaultItem}
  },
  watch: {
    id(newVal) {
      if (newVal) {
        this.loading = true
        http.post(this.urls.get, {id: this.id}).then(({data}) => {
          let {
            success,
            item
          } = data

          if (success) {
            item.togglechange = typeof item.togglechange === 'string' ? JSON.parse(item.togglechange) : item.togglechange
            for (let field of Object.keys(this.item)) {
              this.item[field] = item[field]
            }
          }

        }).finally(() => {
          this.loading = false
        })
      } else {
        for (let field of Object.keys(this.item)) {
          this.item = {...this.defaultItem}
        }
      }
    }
  },
  computed: {
    saveUrl() {
      return this.id ? this.urls.save : this.urls.add
    }
  },
  methods: {
    saveItem() {
      this.loading = true
      http.post(this.saveUrl, {
        id: this.id,
        item: this.item
      }).then(({data}) => {

        let {
          success,
          item
        } = data

        if (success) {
          this.$emit('update:modelValue', false)
          this.$emit('update:page')
        }

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
