<template>
  <div class="container-xxl flex-grow-1 container-p-y">
    <div class="row">
      <div class="col-12 mb-md-0 mb-4">
        <ul class="nav nav-pills mb-3" role="tablist">
          <li class="nav-item" v-for="(link, index) in links" :key="index">
            <router-link :to="{name: link.route, params: link.params}" active-class="active" class="nav-link">{{ link.title }}</router-link>
          </li>
        </ul>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangesLayout",
  data() {
    return {
      links: [
        {title: 'Не завершенные', route: 'changes', params: {status: 'processing'}},
        {title: 'Завершенные', route: 'changes', params: {status: 'completed'}},
        {title: 'Удаленные', route: 'changes', params: {status: 'deleted'}},
        {title: 'Логи', route: 'change-logs'},
      ]
    }
  }
}
</script>

<style scoped>

</style>
