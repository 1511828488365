<template>
  <div class="dropdown bootstrap-select w-100">
    <select :id="id" class="selectpicker w-100" v-model="selected">
      <option v-for="item in localItems" :key="item.value" :value="item.value">{{ item.title }}</option>
    </select>
    <button type="button" tabindex="-1" class="btn dropdown-toggle btn-default"
            data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-4" aria-haspopup="listbox"
            aria-expanded="false" title="Burger, Shake and a Smile" data-id="selectpickerLiveSearch">
      <div class="filter-option">
        <div class="filter-option-inner">
          <div class="filter-option-inner-inner">{{ selectedText || placeholder }}</div>
        </div>
      </div>
    </button>
    <div class="dropdown-menu">
      <div class="bs-searchbox" v-if="search"><input type="search" class="form-control" autocomplete="off" role="combobox"></div>
      <div class="inner show" role="listbox" id="bs-select-4" tabindex="-1" v-perfect-scrollbar="{wheelPropagation: false}" style="max-height: 250px">
        <ul class="dropdown-menu inner show" role="presentation">
          <li :class="{'selected active': selected === item.value}" v-for="(item, index) in localItems" :key="item.value"
              @click="selectValue(item.value, item.title)">
            <slot name="item" v-bind="item">
              <a role="option" class="dropdown-item" :aria-selected="selected === item.value">
                <span class="bx bx-check text-primary check-mark"></span>
                <span class="text">{{ item.title }}</span>
              </a>
            </slot>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/vendor/libs/bootstrap-select/bootstrap-select.css'

export default {
  name: "BootstrapSelect",
  props: ['id', 'items', 'placeholder', 'modelValue', 'search'],
  data() {
    return {
      selected: this.modelValue,
      selectedText: null,
      localItems: this.items,
    }
  },
  watch: {
    modelValue(newVal) {
      this.findAndSelect(newVal)
    }
  },
  mounted() {
    this.findAndSelect(this.selected)
  },
  methods: {
    findAndSelect(selected) {
      for (let item of this.localItems) {
        if (item.value === selected)
          this.selectValue(item.value, item.title)
      }
    },
    selectValue(value, title) {
      this.selected = value
      this.selectedText = title
      this.$emit('update:modelValue', this.selected)
      this.$emit('change', this.selected)
    }
  }
}
</script>

<style scoped>

</style>
